import ContainerNarrow from "../../../components/Containers/ContainerNarrow";
import { H1, H2 } from "../../../components/Typography/Typography";
//import ContainerNarrow from "../../components/Containers/ContainerNarrow";
import Link from "../../../components/Link/Link";

//import { H1, H2 } from "../../components/Typography/Typography";
// import Buttons from "./3-1_buttons";
// import Forms from "./3-2_forms";
// import Navigation from "./3-3_navigation";
// import DataDisplay from "./3-4_data_display";
// import Feedback from "./3-5_feedback";
import Buttons from "./3-1_buttons";
import Cards from "./3-7_cards";
import Media from "./3-6_media";
import Feedback from "./3-5_feedback";
import DataDisplay from "./3-4_data_display";
import Navigation from "./3-3_navigation";
import Forms from "./3-2_forms";

function Components() {
    return <ContainerNarrow>
        <H1>Components</H1>
        <>
            <ul>
                <li><Link to="#buttons">Buttons</Link></li>
                <li>Forms</li>
                <li>Navigation</li>
                <li>Data Display</li>
                <li>Feedback</li>
                <li>Media</li>
            </ul>
        </>
        <>
            <H2 id="buttons">Buttons</H2>
            <Buttons/>
        </>
        <>
            <H2>Forms</H2>
            <Forms />
        </>
        <>
            <H2>Navigation</H2>
            <Navigation />
        </>
        <>
            <H2>Data Display</H2>
            <DataDisplay />
        </>
        <>
            <H2>Feedback</H2>
            <Feedback />
        </>
        <>
            <H2>Media</H2>
            <Media />
        </>
        <>
            <H2>Cards</H2>
            <Cards/>
        </>
    </ContainerNarrow>
}

export default Components;