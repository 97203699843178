import { useForm } from "react-hook-form";
import Button from "../../../components/Buttons/Buttons";
import { CardSimple } from "../../../components/Cards/CardWithFooter";
import { H1, H3 } from "../../../components/Typography/Typography";
import useExperimentStore from '../../../store/experimentStore';
import { Input, SelectReference } from "../../../components/Form/Form";
import { useState } from "react";
import Beautifier from "../../../../legacy/components/common/beautifier";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { inviteReferenceToAssessment } from "../../../services/candidateService";
import useGlobalStore from "../../../store/globalStore";
import Link from "../../../components/Link/Link";
import TwoColumnContainer from "../../../components/Containers/TwoColumn";
import Tabs from "../../../components/Tabs/Tabs";
import AssessmentDashboard from "../1_AssessmentDashboard/1_AssessmentDashboard";

// const data =
// {
//     "id": 1,
//     "user_id": 123,
//     "resume_url": "https://example.com/resume/john.doe.pdf", // Example resume URL
//     "cover_letter": "I am a highly motivated...", // Example cover letter
//     "created_at": "2024-08-01T00:00:00Z", // Example creation date (UTC)
//     "updated_at": "2024-08-20T10:15:00Z", // Example update date (UTC)
//     "job_id": 789,
//     "job_name": "Software Engineer", // Duplicate job_name removed
//     "location": "San Francisco, CA",
//     "description": "Looking for a talented developer...",
//     "company_name": "Acme Corp",
//     "assessment_id": 12,
//     "assessment_name": "Programming Skills Assessment",
//     "assessment_description": "This assessment is evaluating a candidate's abilities to 1.) handle stress situations, 2.) to act socially, and of course 3. the coding skills.",
//     "email": "johndoe@example.com",
//     "first_name": "John",
//     "last_name": "Doe",
//     "expiration_date": "2024-09-30" // Example expiration date

// };

function AssessmentDetails() {
    const {
        findAssessmentById, sentInvitations, userReferences, addNewSentInvitation
    } = useExperimentStore(state => state);


    let { id } = useParams();

    const data = findAssessmentById(id);
    const { register: register10, handleSubmit: handleSubmit10, setValue: setValue10, reset: reset10, formState: { errors: errors10, isValid: isValid10 } } = useForm({
        mode: 'onChange', // Enables validation on change
    });

    if (!data) {
        return <LoadingScreen />
    }
    return <div>
        <AssessmentDashboard/>


    </div >
}

export default AssessmentDetails;