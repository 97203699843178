import {create} from 'zustand';

const useGlobalStore = create((set, get) => ({
  categories: [],
  appSkills: [],
  notification: null,
  notificationStatus: null,
  notificationText: null,
  loading: false,  // New loading state

  setLoading: (isLoading) => set({ loading: isLoading }),  // Method to set loading state

  addCategory: (newCategory) => set((state) => {
    if (!state.categories.some(category => category.id === newCategory.id)) {
      return { categories: [...state.categories, newCategory] };
    }
    return state;
  }),

  addSkills: (newSkills) => set((state) => ({
    appSkills: [
      ...state.appSkills,
      ...newSkills.filter(
        newSkill => !state.appSkills.some(
          skill => skill.id === newSkill.id
        )
      )
    ]
  })),

  setNotification: (newNotification) => set({ notification: newNotification }),

  setNotificationStatus: (newNotificationStatus) => set({ notificationStatus: newNotificationStatus }),

  clearNotification: () => set({ notification: null, notificationStatus: null, notificationText: null }),


  setAppNotificationWithTimeout: (newNotification, newNotificationStatus, newNotificationText, timeout) => {
    // Set the notification states
    set({
      notification: newNotification,
      notificationStatus: newNotificationStatus,
      notificationText: newNotificationText,
  });

  // Use setTimeout to clear the notification after the specified time or default
  setTimeout(() => {
      console.log('Original: Clearing notification after timeout:', timeout);
      get().clearNotification();
  }, timeout);
},

  findSkillById: ({ id }) => {
    const { appSkills } = get();
    const result = appSkills.filter(skill => skill.id === id);
    return result;
  },

}));

export default useGlobalStore;
