import React from 'react';

const mockData = [
  {
    id: "1e2d3c4b-5678-4a1b-9c1d-abcde1234567",
    user_id: "1a2b3c4d-5678-4e2a-8c4b-bcd123ef4567",
    activity_type: "CREATE",
    description: "Created a new question",
    entity_id: "2a3b4c5d-6789-4b2c-9d3f-abc4567efgh8",
    entity_type: "QUESTION",
    created_at: "2024-09-27T10:00:00Z",
    metadata: { question_first_person: "I am adaptable", traits: [1] }
  },
  {
    id: "2f4g5h6i-5678-4j3k-9m8n-lmn789012345",
    user_id: "1a2b3c4d-5678-4e2a-8c4b-bcd123ef4567",
    activity_type: "EDIT",
    description: "Edited question text and traits",
    entity_id: "2a3b4c5d-6789-4b2c-9d3f-abc4567efgh8",
    entity_type: "QUESTION",
    created_at: "2024-09-28T14:30:00Z",
    metadata: { before: { traits: [1, 2] }, after: { traits: [1, 3] } }
  },
];

function ActivityLog() {
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-4">Activity Log</h1>
      
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="hidden md:table min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 bg-gray-200 font-semibold text-gray-700 text-left">User</th>
              <th className="py-2 px-4 bg-gray-200 font-semibold text-gray-700 text-left">Activity Type</th>
              <th className="py-2 px-4 bg-gray-200 font-semibold text-gray-700 text-left">Description</th>
              <th className="py-2 px-4 bg-gray-200 font-semibold text-gray-700 text-left">Entity</th>
              <th className="py-2 px-4 bg-gray-200 font-semibold text-gray-700 text-left">Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {mockData.map((activity) => (
              <tr key={activity.id} className="border-b">
                <td className="py-3 px-4 text-gray-800">{activity.user_id}</td>
                <td className="py-3 px-4 text-gray-800">{activity.activity_type}</td>
                <td className="py-3 px-4 text-gray-600">{activity.description}</td>
                <td className="py-3 px-4 text-gray-800">
                  {activity.entity_type} (ID: {activity.entity_id})
                </td>
                <td className="py-3 px-4 text-gray-800">
                  {new Date(activity.created_at).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Mobile-Friendly Card Layout */}
        <div className="md:hidden space-y-4">
          {mockData.map((activity) => (
            <div key={activity.id} className="bg-white shadow-sm rounded-lg p-4 border border-gray-200">
              <p className="text-gray-800 font-semibold mb-1">User: <span className="font-normal">{activity.user_id}</span></p>
              <p className="text-gray-800 font-semibold mb-1">Activity Type: <span className="font-normal">{activity.activity_type}</span></p>
              <p className="text-gray-800 font-semibold mb-1">Description: <span className="font-normal">{activity.description}</span></p>
              <p className="text-gray-800 font-semibold mb-1">Entity: <span className="font-normal">{activity.entity_type} (ID: {activity.entity_id})</span></p>
              <p className="text-gray-800 font-semibold mb-1">Timestamp: <span className="font-normal">{new Date(activity.created_at).toLocaleString()}</span></p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ActivityLog;
