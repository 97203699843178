import ContainerNarrow from "../../../components/Containers/ContainerNarrow";
import { H1, H2 } from "../../../components/Typography/Typography";
import Colors from "./2-1_colors";
import TextSystem from "./2-2_typography";
import Spacing from "./2-3_spacing";
import Icons from "./2-4_icons";
import Imagery from "./2-5_imagery";

function Foundations() {
    return <ContainerNarrow>
        <H1>Foundations</H1>
        <ul>
            <li>Colors</li>
            <li>Typography</li>
            <li>Spacing</li>
            <li>Icons</li>
            <li>Imagery</li>
        </ul>
        <>
            <H2>Colors</H2>
            <Colors />
        </>
        <>
            <H2>Typography</H2>
            <TextSystem />
        </>
        <>
            <H2>Spacing</H2>
            <Spacing />
        </>
        <>
            <H2>Icons</H2>
            <Icons />
        </>
        <>
            <H2>Imagery</H2>
            <Imagery />
        </>
    </ContainerNarrow>
}

export default Foundations;