// pages/JobView/JobEditor.js
import React from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../../components/Buttons/Buttons';
import { Input } from '../../../components/Form/Form';

const JobEditor = ({ job, onSubmit, onCancel }) => {
  const { register, handleSubmit, formState: { errors, isValid } } = useForm({
    mode: 'onChange',
    defaultValues: job
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 bg-white p-8 mt-8">
      <Input
        label="Job Name"
        id="name"
        register={register}
        required={true}
        errors={errors}
        type="text"
      />

      <Input
        label="Location"
        id="location"
        register={register}
        required={true}
        errors={errors}
        type="text"
      />

      <Input
        label="Company Name"
        id="company_name"
        register={register}
        required={true}
        errors={errors}
        type="text"
      />

      <div className="flex justify-end space-x-4">
        <Button
          type="button"
          variant="secondary"
          size="small"
          onClick={onCancel}
          className="inline-flex justify-center"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          size="small"
          disabled={!isValid}
          className="inline-flex justify-center"
        >
          Save Changes
        </Button>
      </div>
    </form>
  );
};

export default JobEditor;