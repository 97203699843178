import {create} from 'zustand';

const useAdminStore = create((set, get) => ({
  registeredUsers: [],
  traits: [],
  psychQuestions: [],
  jobs: [],

  initializeRegisteredUsers: (initialRegisteredUsers) => set((state) => ({
    registeredUsers: [
      ...state.registeredUsers,
      ...initialRegisteredUsers.filter(
        initialRegisteredUser => !state.registeredUsers.some(registeredUser => registeredUser.id === initialRegisteredUser.id)
      ),
    ]
  })),

  addNewUser: (newRegisteredUser) => set((state) => ({
    registeredUsers: [...state.registeredUsers, newRegisteredUser]
  })),

  editStoredUser: (editedUser) => set((state) => ({
    registeredUsers: state.registeredUsers.map(registeredUser =>
        registeredUser.id === editedUser.id ? editedUser : registeredUser
    )
  })),


  removeStoredUser: (userId) => set((state) => ({
    registeredUsers: state.registeredUsers.filter(registeredUser => registeredUser.id !== userId)
  })),

  initializePsychQuestions: (initialPsychQuestions) => set((state) => ({
    psychQuestions: [
      ...state.psychQuestions,
      ...initialPsychQuestions.filter(
        initialPsychQuestion => !state.psychQuestions.some(psychQuestion => psychQuestion.id === initialPsychQuestion.id)
      ),
    ]
  })),

  addPsychQuestions: (newPsychQuestion) => set((state) => ({
    psychQuestions: [...state.psychQuestions, newPsychQuestion]
  })),

  editPsychQuestions: (editedPsychQuestion) => set((state) => ({
    psychQuestions: state.psychQuestions.map(psychQuestion =>
        psychQuestion.id === editedPsychQuestion.id ? editedPsychQuestion : psychQuestion
    )
  })),

  removeStoredPsychQuestion: (questionId) => set((state) => ({
    psychQuestions: state.psychQuestions.filter(psychQuestion => psychQuestion.id !== questionId)
  })),

  initializePsychTraits: (initialPsychTraits) => set((state) => ({
    traits: [
      ...state.traits,
      ...initialPsychTraits.filter(
        initialPsychTrait => !state.traits.some(trait => trait.id === initialPsychTrait.id)
      ),
    ]
  })),

  initializeJobs: (initialJobs) => set((state) => ({
    jobs: [
      ...state.jobs,
      ...initialJobs.filter(
        initialJob => !state.jobs.some(job => job.id === initialJob.id)
      ),
    ]
  })),

  removeStoredJob: (jobId) => set((state) => ({
    jobs: state.jobs.filter(job => job.id !== jobId)
  })),
}));

export default useAdminStore;