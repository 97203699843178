export default function StyledProgressBar({ currentStep, totalSteps }) {
    return (
      <nav aria-label="Progress" className="w-full mb-4">
        <ol role="list" className="flex justify-between w-full">
          {Array.from({ length: totalSteps }).map((_, stepIdx) => (
            <li key={stepIdx} className="flex-1 mx-2">
              <div className="flex flex-col items-center">
                {stepIdx + 1 < currentStep ? (
                  // Completed steps
                  <span className="block h-1 w-full bg-brand-600" aria-hidden="true" />
                ) : stepIdx + 1 === currentStep ? (
                  // Current step
                  <span className="block h-1 w-full bg-brand-600" aria-hidden="true" />
                ) : (
                  // Upcoming steps
                  <span className="block h-1 w-full border border-gray-300 bg-gray-300" aria-hidden="true" />
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    );
  }
  
  