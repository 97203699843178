import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useAdminStore from "../../../store/adminStore";
import axios from "axios";
import { checkEmail } from "../../../services/adminService";

function DashboardAdmin() {
  const [filters, setFilters] = useState({ first_name: '', last_name: '', role: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const { registeredUsers } = useAdminStore((state) => state);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isEmailChecked, setIsEmailChecked] = useState(false); // Track if email was checked
  
  const { register, handleSubmit, reset, watch, setError, clearErrors } = useForm();
  const email = watch("email");

  useEffect(() => {
    if (!email) return;
    const checkEnteredEmail = async () => {
      setIsCheckingEmail(true);
      try {
        const response = await checkEmail(email);
        setIsEmailValid(!response.data.exists);
        response.data.exists
          ? setError("email", { type: "manual", message: "Email already exists" })
          : clearErrors("email");
      } catch (error) {
        console.error("Error checking email:", error);
      }
      setIsCheckingEmail(false);
    };
    const delayDebounce = setTimeout(() => checkEnteredEmail(), 500);
    return () => clearTimeout(delayDebounce);
  }, [email, setError, clearErrors]);

  const handleFilterChange = (e) => setFilters({ ...filters, [e.target.name]: e.target.value });

  const filteredData = registeredUsers.filter(
    (user) =>
      user.first_name.toLowerCase().includes(filters.first_name.toLowerCase()) &&
      user.last_name.toLowerCase().includes(filters.last_name.toLowerCase()) &&
      user.role.toLowerCase().includes(filters.role.toLowerCase())
  );

  const handleEdit = (user) => {
    setEditingUser(user);
    setIsModalOpen(true);
    reset({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    });
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`/api/users/${userId}`);
      alert("User deleted successfully.");
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleResetPassword = async (userId) => {
    try {
      await axios.post(`/api/users/${userId}/reset-password`);
      alert("Password reset successfully.");
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  const onSubmit = (data) => {
    if (editingUser) {
      console.log("Updating user:", data);
      setEditingUser(null);
    } else {
      console.log("New user data:", data);
    }
    reset();
    setIsModalOpen(false);
  };

  const handleCheckEmail = async () => {
    setIsCheckingEmail(true);
    try {
      const response = await checkEmail(email);
      setIsEmailValid(!response.data.exists);
      setIsEmailChecked(true);
      response.data.exists
        ? setError("email", { type: "manual", message: "Email already exists" })
        : clearErrors("email");
    } catch (error) {
      console.error("Error checking email:", error);
    }
    setIsCheckingEmail(false);
  };

  return (
    <div className="p-6">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">Admin Dashboard</h1>

      <button
        onClick={() => { setIsModalOpen(true); setEditingUser(null); reset(); }}
        className="mb-6 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
      >
        Add New User
      </button>

      {/* Filters */}
      <div className="bg-white rounded-xl shadow-md p-4 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            name="first_name"
            placeholder="Filter by First Name"
            value={filters.first_name}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
          <input
            type="text"
            name="last_name"
            placeholder="Filter by Last Name"
            value={filters.last_name}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          />
          <select
            name="role"
            value={filters.role}
            onChange={handleFilterChange}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
          >
            <option value="">All Roles</option>
            <option value="candidate">Candidate</option>
            <option value="recruiter">Recruiter</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      </div>

      {/* User Table */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        <table className="min-w-full text-gray-800">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="py-2 px-4 text-left font-medium">First Name</th>
              <th className="py-2 px-4 text-left font-medium">Last Name</th>
              <th className="py-2 px-4 text-left font-medium">Role</th>
              <th className="py-2 px-4 text-left font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((user) => (
                <tr key={user.id} className="border-b hover:bg-gray-50">
                  <td className="py-2 px-4">{user.first_name}</td>
                  <td className="py-2 px-4">{user.last_name}</td>
                  <td className="py-2 px-4 capitalize">{user.role}</td>
                  <td className="py-2 px-4 flex flex-wrap gap-2">
                    <button
                      onClick={() => handleEdit(user)}
                      className="bg-blue-500 text-white px-2 py-1 rounded-md font-semibold hover:bg-blue-600 transition"
                    >
                      Edit
                    </button>
                    <button
  onClick={() => handleResetPassword(user.id)}
  className="text-gray-600 bg-gray-100 px-2 py-1 rounded-md font-semibold hover:bg-gray-200 transition"
>
  Reset Password
</button>
<button
  onClick={() => handleDelete(user.id)}
  className="text-red-600 bg-gray-100 px-2 py-1 rounded-md font-semibold hover:bg-gray-200 transition"
>
  Delete
</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center py-4 text-gray-500">No users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add/Edit User Modal */}
      {isModalOpen && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">
            {editingUser ? "Edit User" : "Add New User"}
          </h2>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <input
              {...register("first_name", { required: true })}
              type="text"
              placeholder="First Name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <input
              {...register("last_name", { required: true })}
              type="text"
              placeholder="Last Name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
            <div className="relative flex items-center">
              <input
                {...register("email", { required: true })}
                type="email"
                placeholder="Email"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              />
              <button
                type="button"
                onClick={handleCheckEmail}
                className="absolute right-2 px-3 py-1 bg-gray-300 text-gray-700 rounded-md font-semibold hover:bg-gray-400 transition"
              >
                {isCheckingEmail ? "Checking..." : "Check Email"}
              </button>
            </div>
            {!isEmailValid && (
              <p className="text-red-500 text-sm">Email already exists</p>
            )}
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md font-semibold hover:bg-gray-300 transition"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isEmailChecked || !isEmailValid || isCheckingEmail}
                className={`px-4 py-2 bg-gray-500 text-white rounded-md font-semibold hover:bg-gray-600 transition ${(!isEmailChecked || !isEmailValid || isCheckingEmail) ? "opacity-50 cursor-not-allowed" : ""}`}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    )}
    </div>
  );
}

export default DashboardAdmin;
