import {create} from 'zustand';

const useUserStore = create((set) => ({
  user: null,
  isAuthenticated: false,
  token: localStorage.getItem('authToken'),
  setToken: (token) => set({ token }),
  setUser: (user) => set({ user }),
  setIsAuthenticated: (status) => set({ isAuthenticated: status }),
  logout: () => {
    localStorage.removeItem('authToken');
    set({ user: null, token: null, isAuthenticated: false });
  },
}));

export default useUserStore;