import { HiChevronRight as ChevronRightIcon } from 'react-icons/hi2';

import { useState } from 'react';

import { useForm } from 'react-hook-form';
import Link from '../../../components/Link/Link';
import useGlobalStore from '../../../store/globalStore';
import useRecruiterStore from '../../../store/recruiterStore';
import { createJob } from '../../../services/recruiterService';
import useUserStore from '../../../store/userStore';
import { job_data } from '../../../data/recruiter/jobs';
import Button from '../../../components/Buttons/Buttons';
import { CardSimple } from '../../../components/Cards/CardWithFooter';
import { H1, P } from '../../../components/Typography/Typography';
import Beautifier from '../../../../legacy/components/common/beautifier';
import { Input } from '../../../components/Form/Form';
import useHandleInvalidToken from '../../../utils/handleInvalidToken';
import Badge from '../../../components/Badge/Badge';

const JobItem = ({ job }) => {
    const {
        countJobCandidates, jobCandidates
    } = useRecruiterStore((state) => state);
    return (
        <>
            {/* <Beautifier value={jobCandidates} /> */}
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:table-cell">
                {job.name} ({countJobCandidates(job.id)})
                <div className="mt-1 flex flex-col text-gray-500">
                    <span>{job.company_name}</span>
                    <span>{job.location}</span>
                    <span className='sm:hidden'>{job.assessment_name}</span>
                    <span className='sm:hidden'>
                        <Badge
                            text={job.status}
                            color={job.status === "complete" ? "green" : job.status === "draft" ? "yellow" : job.status === "open" ? "blue" : "red"}
                        />
                    </span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell font-semibold">
                {job.assessment_name ? job.assessment_name : <i className='italic font-thin'>None selected</i>}
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <Badge
                    text={job.status}
                    color={job.status === "complete" ? "green" : job.status === "draft" ? "yellow" : job.status === "open" ? "blue" : "red"}
                />
            </td>
            <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                    to={`/recruiter/jobs/${job.id}`}
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    View
                    <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Link>
            </td>
        </>
    );
};

export default function Jobs() {
    const [editorOn, setEditorOn] = useState(false);
    const [entriesPage, setEntriesPage] = useState(5);
    const handleInvalidToken = useHandleInvalidToken();
    const { register: register5, handleSubmit: handleSubmit5, setValue: setValue5, reset: reset5, formState: { errors: errors5, isValid: isValid5 } } = useForm({
        mode: 'onChange', // Enables validation on change
    });

    const {
        jobs, addNewJob
    } = useRecruiterStore((state) => state);

    const { user } = useUserStore((state) => state);
    const { setAppNotificationWithTimeout } = useGlobalStore((state) => state);

    const onAddJob = async (data) => {
        data.recruiter_id = user.id;
        const response = await createJob(data);
        console.log("response")
        console.log(response)
        console.log("response.data")
        console.log(response.data)
        if (response && response.success) {
            addNewJob(response.data);
            setAppNotificationWithTimeout('Job created.', 'success', 'Job created.', 3000);
            setEditorOn(false);
            reset5();
        } else {
            setAppNotificationWithTimeout("Error creating job.", 'error', 3000);
            if (response.tokenValid === false) {
                handleInvalidToken();  // Call the hook function to handle the invalid token
                return;
            }
        }
    }

    return (
        <CardSimple
            className="px-4 sm:px-6 lg:px-8"
            contentData={
                <><H1>Jobs</H1>
                    <div className="-mx-4 mt-10  ring-gray-300 sm:mx-0 sm:rounded-lg">
                        {
                            editorOn ?
                                <>
                                    <form onSubmit={handleSubmit5(onAddJob)}>

                                        <Input
                                            label="Job Name"
                                            id="name"
                                            register={register5}
                                            required={true}
                                            errors={errors5}
                                            type="text"
                                        />
                                        <Input
                                            label="Job Description"
                                            id="description"
                                            register={register5}
                                            required={true}
                                            errors={errors5}
                                            type="text"
                                        />
                                        <Input
                                            label="Company Name"
                                            id="company_name"
                                            register={register5}
                                            required={true}
                                            errors={errors5}
                                            type="text"
                                        />
                                        <Input
                                            label="Location"
                                            id="location"
                                            register={register5}
                                            required={true}
                                            errors={errors5}
                                            type="text"
                                        />

                                        <div className="mt-4 sm:flex sm:justify-end flex flex-shrink-0 md:ml-4 md:mt-4">

                                            <Button
                                                variant="primary"
                                                size="small"
                                                className={"w-full mr-2"}
                                                type="submit"
                                                disabled={!isValid5}
                                            >
                                                Create Job
                                            </Button>

                                            <Button
                                                variant="secondary"
                                                size="small"
                                                className={"blocked"}
                                                type="submit"
                                                onClick={() => setEditorOn(false)}
                                            //disabled={!isValid}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </form>
                                </>
                                : <>
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Job
                                                </th>
                                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                                    Assessment
                                                </th>
                                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                                    Status
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">View</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {jobs.slice(0, entriesPage).map((job, jobIdx) => (
                                                <tr className="divide-y divide-gray-200">
                                                    <JobItem key={job.id} job={job} />
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="mt-4 sm:flex sm:justify-end">
                                        {jobs.length > entriesPage &&
                                            <Button
                                                variant="primary"
                                                size="small"
                                                onClick={() => setEntriesPage(entriesPage + 5)}
                                                className="block mr-2"

                                            >
                                                View more
                                            </Button>

                                        }
                                        <Button
                                            variant="secondary"
                                            size="small"
                                            onClick={() => setEditorOn(true)}
                                        >
                                            Create
                                        </Button>
                                    </div>
                                </>
                        }

                    </div>
                </>
            }
        />
    );
}