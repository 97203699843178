import React from 'react';
import Link from '../Link/Link';
import logo from './../../assets/brand/brand_logo.png';

const ErrorPage = ({errorMessage}) => {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="flex flex-shrink-0 justify-center">
        <img src={logo} alt="Brand Logo" className="h-12 w-auto" />
      </div>
      <div className="text-center mt-8">
        <h2 className="text-3xl font-bold tracking-tight text-red-600 sm:text-4xl">Error!</h2>
        <p className="mt-2 text-base text-gray-500">{errorMessage}</p>
        <div className="mt-6">
          <Link to="/" className="text-base font-medium text-red-600 hover:text-red-500">
            Go back home<span aria-hidden="true"> &rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
