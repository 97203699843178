import Button from "../../../components/Buttons/Buttons";
//import Button from "../../components/Buttons/Buttons";
import { ListWithChildren, ListWithItems, HorizontalListWithChildren } from "../../../components/List/List";
import { H2, P } from "../../../components/Typography/Typography";
import Link from "../../../components/Link/Link";

function Buttons() {

    return <div className="p-8 space-y-8">
        <div className="space-y-4">
            <H2>Button Rules</H2>
            <P>For buttons, we use accent colors. So, we take <text className="text-highlight-500">Highlight</text> and <text className="text-accent-500">Accent.</text></P>
        </div>

        <div className="space-y-4">
            <H2>Primary</H2>
            <P>Primary buttons are used for primary actions like submitting forms, saving changes, or proceeding to the next step.</P>
            <div className="space-x-2">
                <HorizontalListWithChildren>
                    <Button variant="primary" size="small">Small</Button>
                    <Button variant="primary" size="medium">Medium</Button>
                    <Button variant="primary" size="large">Large</Button>
                    <Button variant="primary" size="medium" disabled>Disabled</Button>
                </HorizontalListWithChildren>
            </div>
        </div>

        <div className="space-y-4">
            <H2>Secondary</H2>
            <P>Secondary buttons are for secondary actions, such as secondary options in forms, or less critical actions.</P>
            <div className="space-x-2">
                <HorizontalListWithChildren>
                    <Button variant="secondary" size="small">Small</Button>
                    <Button variant="secondary" size="medium">Medium</Button>
                    <Button variant="secondary" size="large">Large</Button>
                    <Button variant="secondary" size="medium" disabled>Disabled</Button>
                </HorizontalListWithChildren>
            </div>
        </div>

        <div className="space-y-4">
            <H2>Tertiary</H2>
            <P>Highlight buttons draw attention to key actions, such as important notices or alerts.</P>
            <div className="space-x-2">
                <HorizontalListWithChildren>
                    <Button variant="tertiary" size="small">Small</Button>
                    <Button variant="tertiary" size="medium">Medium</Button>
                    <Button variant="tertiary" size="large">Large</Button>
                    <Button variant="tertiary" size="medium" disabled>Disabled</Button>
                </HorizontalListWithChildren>
            </div>
        </div>

        <div className="space-y-4">
            <H2>Caution</H2>
            <P>Caution buttons are for operations that require caution, like deleting data.</P>
            <div className="space-x-2">
                <HorizontalListWithChildren>
                    <Button variant="caution" size="small">Small</Button>
                    <Button variant="caution" size="medium">Medium</Button>
                    <Button variant="caution" size="large">Large</Button>
                    <Button variant="caution" size="medium" disabled>Disabled</Button>
                </HorizontalListWithChildren>
            </div>
        </div>

        <div className="space-y-4">
            <H2>Link</H2>
            <P>Highlight buttons draw attention to <Link to="/" >key actions</Link>, such as important notices or alerts.</P>
            <div className="space-x-2">
                <Link to="/" className="mr-2">Home</Link>
                <Link to="/about" className="mr-2">About</Link>
                <Link to="https://www.google.com" external className="mr-2">Google (External)</Link>
            </div>
        </div>
    </div>
}

export default Buttons;