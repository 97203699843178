import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import useStore from './state/userState';
import useAdminStore from './state/adminState';
import { getCategories, getSkills } from './services/adminService';

import Login from './pages/unprotected/Login';
import Register from './pages/unprotected/Register';
import Recruiter from './pages/recruiter/Recruiter';
import Candidate from './pages/candidate/Candidate';

import './App.css';
import TermsOfService from './pages/unprotected/Terms';

import CandidateLayout from './layouts/candidateLayout';
import RecruiterLayout from './layouts/recruiterLayout';
import ProtectedRoute from './utils/ProtectedRoute';
import StyleGuide from './pages/Admin/StyleGuide/StyleGuide';
import Assessments from './pages/recruiter/Assessments';
import Jobs from './pages/recruiter/Jobs';
import JobView from './pages/recruiter/JobView/JobView';
import AssessmentPage from './pages/recruiter/AssessmentPage/AssessmentPage';
import Home from './pages/unprotected/Home';
import InvitationsPage from './pages/candidate/InvitationsPage/InvitationsPage';
import ProfilePage from './pages/candidate/ProfilePage/ProfilePage';
import { checkAndValidateToken } from './services/authService';
import NotFound from './pages/unprotected/NotFound';
import CandidateAssessmentPage from './pages/candidate/Assessment/AssessmentPage';
import CandidateResults from './pages/recruiter/JobView/CandidateResults';
// import AssessmentPage from './pages/recruiter/AssessmentView/AssessmentPage';

function App() {
  const { setUser, user } = useStore((state) => state);
  const { appSkills, categories, addCategories, addSkills, notification, notificationStatus, setNotificationWithTimeout } = useAdminStore((state) => state);
 
  useEffect(() => {
    const validateToken = async () => {
      const response = await checkAndValidateToken();

      if (response.success) {
        setUser(response.user);
        setNotificationWithTimeout('User authenticated successfully.', 'success', 3000);
      } else {
        setNotificationWithTimeout(response.message, 'error', 3000);
      }
    };

    validateToken();
  }, []);

  return (
    <>
      <div className=''>
        {notification && (
          <div className={` admin notification ${notificationStatus}`}>
            <h4>{notification}</h4>
          </div>
        )}
      </div>
      <Routes className="App">
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recruiter">
          <Route path="dashboard" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><Recruiter /></RecruiterLayout></ProtectedRoute>} />
          {/* <Route path="ag/:id" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><AssessmentPage/></RecruiterLayout></ProtectedRoute>} /> */}
          <Route path="assessments/:id" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><AssessmentPage /></RecruiterLayout></ProtectedRoute>} />
          <Route path="assessments" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><Assessments /></RecruiterLayout></ProtectedRoute>} />
          <Route path="results/:job/:candidate" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><CandidateResults /></RecruiterLayout></ProtectedRoute>} />
          <Route path="jobs/:id" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><JobView /></RecruiterLayout></ProtectedRoute>} />
          <Route path="jobs" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><Jobs /></RecruiterLayout></ProtectedRoute>} />
        </Route>
        <Route path="/candidate">
          <Route path="dashboard" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><Candidate /></CandidateLayout></ProtectedRoute>} />
          <Route path="invitations" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><InvitationsPage /></CandidateLayout></ProtectedRoute>} />
          <Route path="profile" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><ProfilePage /></CandidateLayout></ProtectedRoute>} />
          <Route path="assessment/:id" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><CandidateAssessmentPage /></CandidateLayout></ProtectedRoute>} />
        </Route>
        <Route path="/style" element={<StyleGuide />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </>

  );
}

export default App;
