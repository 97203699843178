import { useEffect, useState } from "react";
import { CardSimple } from "../../../components/Cards/CardWithFooter";
import { getAssessmentPageData, submitAssessmentData } from "../../../services/candidateService";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";
import { H3 } from "../../../components/Typography/Typography";
import Button from "../../../components/Buttons/Buttons";
import StyledProgressBar from "../../../components/ProgressBar/styledProgressBar";
import SuccessSubmission from "../../../components/SuccessfulSubmission/SuccessfulSubmission";
import ErrorPage from "../../../components/ErrorPage/ErrorPage";
import useExperimentStore from "../../../store/experimentStore";

function AssessmentTest() {
    let { assessmentId, jobCandidateId, referenceId } = useParams();
    const [pages, setPages] = useState(0);
    const [pageRequirements, setPageRequirements] = useState([]);
    const [pageQuestions, setPageQuestions] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submissionFailed, setSubmissionFailed] = useState(false);
    const itemsPerPage = 1; // Define how many items to show per page

    const {
        editReceivedInvitation, findReceivedInvitation
    } = useExperimentStore(state => state);

    useEffect(() => {
        async function fetchData() {
            console.log("assessmentId");
            console.log(assessmentId);
            console.log("jobCandidateId");
            console.log(jobCandidateId);
            console.log("referenceId");
            console.log(referenceId);
            const data = await getAssessmentPageData({
                assessmentId,
                job_candidate_id: jobCandidateId,
                referenceId
            });
    
            if (data && data.data) {
                const requirements = data.data.assessmentRequirements || [];
                const questions = data.data.assessmentQuestions || [];
    
                // Initialize the questions and requirements with default slider values (50%)
                const updatedRequirements = requirements.map(req => ({
                    ...req,
                    sliderValue: 50, // Default value
                }));
    
                const updatedQuestions = questions.map(q => ({
                    ...q,
                    sliderValue: 50, // Default value
                }));
    
                setPageRequirements(updatedRequirements);
                setPageQuestions(updatedQuestions);
    
                // Total pages is the sum of requirements and questions divided by items per page
                setPages(updatedRequirements.length + updatedQuestions.length);
                setPageLoading(false);
            } else {
                setPageLoading(false);
                setPageError(true);
            }
        }
        fetchData();
    }, [assessmentId, referenceId]);
    

    const handleNextPage = () => {
        if (currentPage < pages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Function to handle the slider change and update the correct state (questions or requirements)
    const handleSliderChange = (newValue, index, isQuestion) => {
        if (isQuestion) {
            const updatedQuestions = [...pageQuestions];
            updatedQuestions[index].sliderValue = newValue;
            setPageQuestions(updatedQuestions);
        } else {
            const updatedRequirements = [...pageRequirements];
            updatedRequirements[index].sliderValue = newValue;
            setPageRequirements(updatedRequirements);
        }
    };

    const allItemsHaveSliderValue = () => {
        // Check if all questions have a sliderValue
        const questionsComplete = pageQuestions.every(question => question.sliderValue !== undefined);
    
        // Check if all requirements have a sliderValue
        const requirementsComplete = pageRequirements.every(requirement => requirement.sliderValue !== undefined);
    
        // Return true if both checks are true
        return questionsComplete && requirementsComplete;
    };

    const hasSliderValueForItem = (index, isQuestion) => {
        if (isQuestion) {
            // Check if the specific question has a sliderValue
            return pageQuestions[index]?.sliderValue !== undefined;
        } else {
            // Check if the specific requirement has a sliderValue
            return pageRequirements[index]?.sliderValue !== undefined;
        }
    };
    

    const onSubmit = async () => {
        console.log("Submission");
        console.log(pageQuestions);
        console.log(pageRequirements);
        const data = {
            "questions":pageQuestions,
            "requirements":pageRequirements,
            assessmentId,
            job_candidate_id: jobCandidateId,
            referenceId
        }
        console.log(data)
        
        if(allItemsHaveSliderValue()) {
            const submission = await submitAssessmentData(data);
            console.log(submission);
            //setIsSubmiting(true);
            setPageLoading(true);
            if(submission.success)
            {
                setIsSubmiting(false); // Replace with your desired success status
                setIsSubmitted(true);
                setPageLoading(false);
                const invitation = findReceivedInvitation(jobCandidateId);
                invitation.status = 'complete';
                editReceivedInvitation(invitation);
            } else {
                setSubmissionFailed(true)
                setIsSubmiting(false);
                setPageLoading(false);
            }
            // setTimeout(() => {
            //     setIsSubmiting(false); // Replace with your desired success status
            //     setIsSubmitted(true);
            // }, 2000);            
        } else {
            console.log("something's missing")
        }

    };

    const renderContent = () => {
        const allItems = [...pageRequirements, ...pageQuestions];
    
        // Ensure the current page is within the bounds of the combined items
        if (currentPage < 0 || currentPage >= allItems.length) {
            return <div>No content available</div>;
        }
    
        const currentItem = allItems[currentPage];
    
        if (!currentItem) {
            return <div>No content available</div>;
        }
    
        const isQuestion = !!currentItem.psych_question_id; // Check if the current item is a question or a requirement
        const currentSliderValue = currentItem.sliderValue || 50; // Default slider value to 50 if undefined
    
        // Render content based on whether it's a question or a requirement
        if (isQuestion) {
            return (
                <div>
                    <H3>Question: {currentItem.question_third_person}</H3>
                    {/* Slider input for questions */}
                    <div className="flex justify-between items-center my-6">
                        <span>Lowest</span>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={currentSliderValue}
                            onChange={(e) =>
                                handleSliderChange(e.target.value, currentPage - pageRequirements.length, true)
                            }
                            className="slider w-full mx-4"
                        />
                        <span>Highest</span>
                    </div>
                    <p className="text-sm text-center">
                        You selected: {currentSliderValue}% A, {100 - currentSliderValue}% B
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <H3>
                        Requirement: How good is the candidate's {currentItem.skill_name} ({currentItem.category_name}) skill?
                    </H3>
                    {/* Slider input for requirements */}
                    <div className="flex justify-between items-center my-6">
                        <span>Beginner</span>
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={currentSliderValue}
                            onChange={(e) =>
                                handleSliderChange(e.target.value, currentPage, false)
                            }
                            className="slider w-full mx-4"
                        />
                        <span>Expert</span>
                    </div>
                    <p className="text-sm text-center">
                        You selected: {currentSliderValue}% Expert, {100 - currentSliderValue}% Beginner
                    </p>
                </div>
            );
        }
    };
    

    if (pageLoading) {
        return <LoadingScreen />;
    }

    if (pageError) {
        return <ErrorPage errorMessage={"Assessment Invitation expired or already completed."} />;
    }

    if (isSubmiting) {
        return <LoadingScreen />
    }

    if (isSubmitted) {
        return <SuccessSubmission/>
    }

    if (submissionFailed) {
        return <ErrorPage errorMessage={"Assessment submission failed."} />;
    }

    return (
        <div>
            <CardSimple
                contentData={
                    <>
                    <StyledProgressBar currentStep={currentPage+1} totalSteps={pages}/>

                        {renderContent()}
                        <div>
                            {currentPage > 0 && (
                                <Button
                                    onClick={handlePreviousPage}
                                    disabled={currentPage === 0}
                                    size="medium"
                                    variant="primary"
                                    className={"mr-2"}
                                >
                                    Previous
                                </Button>
                            )}
                            {currentPage < pages - 1 && (
                                <Button
                                    onClick={handleNextPage}
                                    disabled={currentPage >= pages - 1}
                                    size="medium"
                                    variant="primary"
                                >
                                    Next
                                </Button>
                            )}
                            {currentPage === pages - 1 && (
                                <Button
                                    onClick={onSubmit}
                                    size="medium"
                                    type="button"
                                    variant="primary"
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </>
                }
            />
        </div>
    );
}

export default AssessmentTest;

