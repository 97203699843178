import { HiChevronRight as ChevronRightIcon } from "react-icons/hi2";
import Link from "../../../components/Link/Link";
import { ListWithItems, ListWithChildren, HorizontalListWithChildren, HorizontalListWithItems } from "../../../components/List/List";
import { H2, H3 } from "../../../components/Typography/Typography";
import { CardSimple } from "../../../components/Cards/CardWithFooter";
import LinkButton from "../../../components/Link/LinkButton";
import useRecruiterStore from "../../../store/recruiterStore";
import Beautifier from "../../../../legacy/components/common/beautifier";


const AssessmentItem = ({ job }) => {
    return (
        <tr key={job.id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {job.name}
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span>{job.company_name}</span>
                    <span>{job.location}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {job.description}
            </td>
            <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                    to={`/recruiter/assessments/${job.id}`}
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    View
                    <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Link>
            </td>
        </tr>
    );
};

const AssessmentList = ({ assessments }) => {
    return (
        <div className="-mx-4 mt-10  ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Job Title
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Description
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">View</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {assessments.slice(0, 5).map((assessment, jobIdx) => (
                        <AssessmentItem key={assessment.id} job={assessment} />
                    ))}
                </tbody>
            </table>
            <div className="mt-4 sm:flex sm:justify-end">
                <LinkButton
                    variant={"primary"}
                    to="/recruiter/assessments"
                >
                    View More
                </LinkButton>
            </div>
        </div>
    );
};

const JobItem = ({ job }) => {
    return (
        <tr key={job.id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {job.name}
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span>{job.company_name}</span>
                    <span>{job.location}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {job.company_name}
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {job.location}
            </td>
            <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <Link
                    to={`/recruiter/assessments/${job.id}`}
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    View
                    <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                </Link>
            </td>
        </tr>
    );
};

const JobList = ({ jobs }) => {
    return (
        <>
            <div className="-mx-4 mt-10  ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Job Title
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                Company
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                Location
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">View</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {jobs.slice(0, 5).map((job, jobIdx) => (
                            <JobItem key={job.id} job={job} />
                        ))}
                    </tbody>
                </table>
                <div className="mt-4 sm:flex sm:justify-end">
                    <LinkButton
                        variant={"primary"}
                        to="/recruiter/jobs"
                    >
                        View More
                    </LinkButton>
                </div>
            </div>
        </>

    );
};



function DashboardRecruiter() {

    const {
        assessments, addNewAssessment, jobs
      } = useRecruiterStore((state) => state);
    return <div>
        <H3>Recruiter Dashboard</H3>
        <div>
            {/* Assessments */}
            <CardSimple
                contentData={<>
                    <H3 className="text-secondary-600">Assessments</H3>
                    <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
                        <AssessmentList assessments={assessments} />
                    </div>
                </>
                }
            />
            {/* Jobs */}

            <CardSimple
                contentData={<>
                    <H3 className="text-secondary-600">Jobs</H3>
                    <JobList jobs={jobs} />
                </>}
            />

        </div>
    </div>
}

export default DashboardRecruiter;