import { useParams } from "react-router-dom";
import { H1, H2, H3, H4, P } from "../../../components/Typography/Typography";
import useExperimentStore from "../../../store/experimentStore";
import { HiClock, HiUserCircle, HiCheckCircle, HiBuildingOffice, HiBriefcase, HiDocument } from "react-icons/hi2";



function NarrowList({ data, id }) {
    const {
        findAssessmentById, sentInvitations, userReferences, addNewSentInvitation
    } = useExperimentStore(state => state);
    const expiration_date = new Date(data.expiration_date).toLocaleString('en-US', {
        dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
    });

    const invitedReferences = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === id; // Compare with the extracted work_history_id
    });

    const invitedReferencesComplete = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === id && sentInvitation.status === 'complete'; // Compare with the extracted work_history_id
    });


    return <div className="lg:col-start-2S lg:row-end-1">
        <h2 className="sr-only">Summary</h2>
        <div className="">
            <dl className="flex flex-wrap">
                <div className="flex-auto  pt-6">
                    <dt className="text-sm font-semibold leading-6 text-gray-900"></dt>
                    <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">{data.job_name}</dd>
                </div>
                <div className="flex-none self-end px-2 pt-4">
                    <dt className="sr-only">Status</dt>
                    <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        {data.status}
                    </dd>
                </div>
                <div className="mt-6 flex w-full flex-none  gap-x-4 border-t border-gray-900/5 px-1 pt-6">
                    <dt className="flex-none">
                        <span className="sr-only">Recruiter</span>
                        <HiUserCircle aria-hidden="true" className="h-6 w-5 text-gray-400" />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">{`${data.recruiter_first_name} ${data.recruiter_last_name}`}</dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-1">
                    <dt className="flex-none">
                        <span className="sr-only">Assessment</span>
                        <HiDocument aria-hidden="true" className="h-6 w-5 text-gray-400" />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-500">{`${data.assessment_name}`}</dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-1">
                    <dt className="flex-none">
                        <span className="sr-only">Due date</span>
                        <HiClock aria-hidden="true" className="h-6 w-5 text-gray-400" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                        <time dateTime="2023-01-31">{expiration_date}</time>
                    </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-1">
                    <dt className="flex-none">
                        <span className="sr-only">Status</span>
                        <HiCheckCircle aria-hidden="true" className="h-6 w-5 text-gray-400" />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">{data.status}</dd>
                </div>
            </dl>
            <div className="mt-2 border-t border-gray-900/5 px-1 py-2">
                <P>Minimum Invitations</P>
                <div className="flex justify-left items-center">
                    
                    <div className={`w-4 h-4 rounded-full border-2 border-gray-300 ${invitedReferences.length > 2 ? "bg-green-500" : "bg-transparent"}`}></div>
                    <span className="ml-2">{invitedReferences.length}/3 Min. Invited</span>
                </div>
                <div className="flex justify-left items-center">
                    <div className={`w-4 h-4 rounded-full border-2 border-gray-300 ${invitedReferencesComplete.length > 2 ? "bg-green-500" : "bg-transparent"}`}></div>
                    <span className="ml-2">{invitedReferencesComplete.length}/3 Completed</span>
                </div>
            </div>
            <div className="mt-2 border-t border-gray-900/5 px-1 py-2">
                <P>Recommended Invitations</P>
                <div className="flex justify-left items-center">
                    <div className={`w-4 h-4 rounded-full border-2 border-gray-300 ${invitedReferences.length > 4 ? "bg-green-500" : "bg-transparent"}`}></div>
                    <span className="ml-2">{invitedReferences.length}/5 Rec. Invited</span>
                </div>
                <div className="flex justify-left items-center">
                    <div className={`w-4 h-4 rounded-full border-2 border-gray-300 ${invitedReferencesComplete.length > 4 ? "bg-green-500" : "bg-transparent"}`}></div>
                    <span className="ml-2">{invitedReferencesComplete.length}/5 Completed</span>

                </div>
            </div>
        </div>
    </div>
}

export default function LeftContent({ data }) {

    let { id } = useParams();
    const expiration_date = new Date(data.expiration_date).toLocaleString('en-US', {
        dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
    });
    return <div>
        <NarrowList data={data} id={id} />
    </div>
}