// src/components/Dialog.js

import React from 'react';
import PropTypes from 'prop-types';

const Dialog = ({ isOpen, title, children, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded shadow-lg w-1/3">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button className="text-gray-500" onClick={onClose}>&times;</button>
        </div>
        <div>{children}</div>
        <div className="flex justify-end mt-4">
          <button className="bg-secondary-500 text-white px-4 py-2 rounded mr-2" onClick={onClose}>Cancel</button>
          <button className="bg-brand-500 text-white px-4 py-2 rounded">Confirm</button>
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Dialog;
