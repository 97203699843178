import React, { useState } from 'react';
import useAdminStore from '../../../store/adminStore';
import { createPsychQuestion, deletePsychQuestion, editPsychQuestion } from '../../../services/adminService';

function QuestionsPanel() {
    const [expandedQuestionId, setExpandedQuestionId] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedTraits, setSelectedTraits] = useState([]);
    const { psychQuestions, traits } = useAdminStore((state) => state);

    // Filtered questions based on search and traits
    const filteredQuestions = psychQuestions.filter((question) => {
        const matchesSearch = searchText
            ? question.question_third_person.toLowerCase().includes(searchText.toLowerCase()) ||
            question.question_first_person.toLowerCase().includes(searchText.toLowerCase())
            : true;
        const matchesTraits = selectedTraits.length
            ? selectedTraits.every((traitId) => [question.trait1, question.trait2, question.trait3].includes(traitId))
            : true;
        return matchesSearch && matchesTraits;
    });

    const handleNewQuestionClick = () => {
        setExpandedQuestionId(null); // Ensure existing question is closed
        setIsCreating(true);         // Open create mode
    };

    // Handle deletion from within QuestionsPanel and reset expandedQuestionId
    const handleDeleteQuestion = (id) => {
        setExpandedQuestionId(null); // Clear expanded ID on delete
        setIsCreating(false);        // Exit creation mode if active
    };

    const questionToEdit = psychQuestions.find(q => q.id === expandedQuestionId) || null;

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <h1 className="text-2xl font-semibold text-gray-800 mb-6">Questions Panel</h1>

            {/* Search Bar and Filter */}
            <div className="mb-6 flex flex-col md:flex-row items-start md:items-center justify-between">
                <input
                    type="text"
                    placeholder="Search by question text..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className="w-full md:w-1/2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition"
                />
                <select
                    multiple
                    value={selectedTraits}
                    onChange={(e) => setSelectedTraits([...e.target.selectedOptions].map(o => o.value))}
                    className="w-full md:w-1/3 p-2 mt-4 md:mt-0 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition"
                >
                    {traits.map((trait) => (
                        <option key={trait.id} value={trait.id}>{trait.trait_name}</option>
                    ))}
                </select>
                <button onClick={handleNewQuestionClick}>Add New Question</button>
            </div>

            {/* Conditional rendering */}
            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {(questionToEdit || isCreating)
                    ? <ExpandedQuestionCard
                        question={questionToEdit}
                        traits={traits}
                        onCollapse={() => { setExpandedQuestionId(null); setIsCreating(false); }}
                        onDelete={() => handleDeleteQuestion(expandedQuestionId)} // Pass handleDeleteQuestion
                        isCreating={isCreating}
                    />
                    : filteredQuestions.map((question) => (
                        <QuestionCard
                            key={question.id}
                            question={question}
                            onExpand={() => setExpandedQuestionId(question.id)}
                        />
                    ))
                }
            </div>
        </div>
    );
}

function QuestionCard({ question, onExpand }) {
    return (
        <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-6 flex flex-col justify-between transition-transform transform hover:scale-105 duration-200">
            <div>
                <h2 className="text-lg font-semibold text-gray-800 mb-2">{question.question_third_person}</h2>

                {/* Display traits */}
                <div className="flex flex-wrap space-x-2 mb-2">
                    {[question.trait1_name, question.trait2_name, question.trait3_name].filter(Boolean).map((traitName, index) => (
                        <span
                            key={index}
                            className="text-sm bg-gray-200 rounded-full px-2 py-1 text-gray-700 font-medium"
                        >
                            {traitName}
                        </span>
                    ))}
                </div>

                <div className="text-gray-500 text-xs">
                    Created: {new Date(question.created_at).toLocaleDateString()}
                </div>
            </div>

            <button
                onClick={onExpand}
                className="mt-4 text-blue-500 text-sm font-semibold hover:underline focus:outline-none"
            >
                View Details
            </button>
        </div>
    );
}

function ExpandedQuestionCard({ question, traits, onCollapse, onDelete, isCreating }) {
    const [isEditing, setIsEditing] = useState(isCreating);
    const [formState, setFormState] = useState({
        id: question ? question.id : '',
        question_first_person: question ? question.question_first_person : '',
        question_third_person: question ? question.question_third_person : '',
        trait1: question ? question.trait1 : null,
        trait2: question ? question.trait2 : null,
        trait3: question ? question.trait3 : null
    });

    const { addPsychQuestions, editPsychQuestions, removeStoredPsychQuestion } = useAdminStore((state) => state);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleTraitToggle = (traitId) => {
        setFormState((prevState) => {
          const selectedTraits = [prevState.trait1, prevState.trait2, prevState.trait3];
          const traitIndex = selectedTraits.indexOf(traitId);
      
          if (traitIndex !== -1) {
            return { ...prevState, [`trait${traitIndex + 1}`]: null };
          }
      
          const emptyIndex = selectedTraits.findIndex((trait) => trait === null);
          if (emptyIndex !== -1) {
            return { ...prevState, [`trait${emptyIndex + 1}`]: traitId };
          }
          
          return prevState;
        });
      };
      
    const canSave = formState.trait1 || formState.trait2 || formState.trait3 || formState.question_first_person || formState.question_third_person;

    const handleCancel = () => {
        setIsEditing(false);
        if (isCreating) {
            onCollapse();
        }
    };

    const createQuestion = async () => {
        if (!canSave) return;
        const newQuestion = await createPsychQuestion(formState);
        addPsychQuestions(newQuestion);
        onCollapse();
        setIsEditing(false);
    };

    const updateQuestion = async () => {
        if (!canSave) return;
        const updatedQuestion = await editPsychQuestion(formState);
        editPsychQuestions(formState);
        onCollapse();
        setIsEditing(false);
    };

    const deleteQuestion = async () => {
        const deletedQuestion = await deletePsychQuestion(question.id);
        if (deletedQuestion.success) {
            removeStoredPsychQuestion(deletedQuestion.data.id);
            onDelete(); // Trigger onDelete to reset expandedQuestionId
        } else {
            console.log(deletedQuestion.error);
        }
    };

    return (
        <div className="col-span-full bg-white shadow-lg rounded-lg border border-gray-200 p-8 flex flex-col min-h-screen">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-800">
                    {isCreating ? "New Question" : question.question_third_person}
                </h2>
                <button onClick={onCollapse} className="text-gray-500 text-2xl hover:text-gray-700">×</button>
            </div>

            {isEditing ? (
                <div>
                    <label className="block font-medium text-gray-700 mb-2">First-Person Statement</label>
                    <textarea
                        name="question_first_person"
                        value={formState.question_first_person}
                        onChange={handleFormChange}
                        className="w-full p-3 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:border-blue-500"
                    />

                    <label className="block font-medium text-gray-700 mb-2">Third-Person Statement</label>
                    <textarea
                        name="question_third_person"
                        value={formState.question_third_person}
                        onChange={handleFormChange}
                        className="w-full p-3 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:border-blue-500"
                    />

                    <label className="block font-medium text-gray-700 mb-2">Select Traits (Max 3)</label>
                    <div className="flex flex-wrap gap-2 mb-6">
                        {traits.map((trait) => (
                            <button
                                key={trait.id}
                                onClick={() => handleTraitToggle(trait.id)}
                                className={`px-3 py-1 rounded-full ${[formState.trait1, formState.trait2, formState.trait3].includes(trait.id) ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-700'} font-medium`}
                            >
                                {trait.trait_name}
                            </button>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="text-gray-700 space-y-6">
                    <div>
                        <h3 className="font-semibold text-gray-800">First-Person Statement</h3>
                        <p className="text-sm">{question.question_first_person}</p>
                    </div>
                    <div>
                        <h3 className="font-semibold text-gray-800">Traits</h3>
                        {[{ name: question.trait1_name, description: question.trait1_description },
                        { name: question.trait2_name, description: question.trait2_description },
                        { name: question.trait3_name, description: question.trait3_description }]
                            .filter(trait => trait.name)
                            .map((trait, index) => (
                                <div key={index} className="mb-4">
                                    <h4 className="text-sm font-semibold">{trait.name}</h4>
                                    <p className="text-xs text-gray-600">{trait.description}</p>
                                </div>
                            ))}
                    </div>
                    <div className="text-gray-500 text-xs">
                        Created: {new Date(question.created_at).toLocaleDateString()}<br />
                        Updated: {new Date(question.updated_at).toLocaleDateString()}
                    </div>
                </div>
            )}

            <div className="mt-8 flex space-x-4">
                {isCreating || isEditing ? (
                    <>
                        <button
                            onClick={handleCancel}
                            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md font-semibold hover:bg-gray-400 transition"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                if (canSave) {
                                    isCreating ? createQuestion() : updateQuestion();
                                }
                            }}
                            className={`px-4 py-2 rounded-md font-semibold transition ${canSave ? 'bg-green-600 text-white hover:bg-green-700' : 'bg-green-300 text-gray-100 cursor-not-allowed'}`}
                        >
                            Save
                        </button>
                    </>
                ) : (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="bg-indigo-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-indigo-700 transition"
                    >
                        Edit
                    </button>
                )}
                {!isCreating && (
                    <button
                        onClick={() => deleteQuestion()}
                        className="bg-red-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-red-700 transition"
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>
    );
}

export default QuestionsPanel;
