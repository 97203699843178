import {CardWithFooter} from "../../../components/Cards/CardWithFooter";
import Link from "../../../components/Link/Link";
import { H1, H3, Small, P } from "../../../components/Typography/Typography";

function Cards() {
    return <>
    <H3>Cards</H3>
    <CardWithFooter 
        contentData={
            <>
                <H1>New Features on GoSkilling</H1> 
                <Small>Hello World</Small>
                <P>Ut a molestie turpis. Quisque ultricies ornare porttitor. Integer sit amet mi sit amet nulla auctor dictum sed et felis. Ut lacus neque, convallis ac dolor ullamcorper, sollicitudin venenatis risus. Donec pharetra lectus quis nisi mattis rutrum. Maecenas scelerisque nunc sit amet sem molestie aliquam. Pellentesque sollicitudin quam vel scelerisque facilisis. </P>  
                <H3>+30%</H3>
                <Link to="#">Read more</Link>
            </>
        }
        footerContent={<Small>Published on Janaury 2, 2024</Small>}
    />
    </>
}

export default Cards;