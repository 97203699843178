import { useState } from "react";
import { useParams } from "react-router-dom";
import useExperimentStore from "../../../store/experimentStore";
import Button from "../../../components/Buttons/Buttons";
import { useForm } from "react-hook-form";
import { SelectReference } from "../../../components/Form/Form";
import useGlobalStore from "../../../store/globalStore";
import WorkReferencePage from "./1-2-RC-Work";


export default function Invitations() {
    const [showEditor, setShowEditor] = useState(false);

    const {
        findAssessmentById, sentInvitations, userReferences, addNewSentInvitation
    } = useExperimentStore(state => state);
    const { setAppNotificationWithTimeout } = useGlobalStore((state) => state);
    let { id } = useParams();
    const data = findAssessmentById(id);
    const { register: register10, handleSubmit: handleSubmit10, setValue: setValue10, reset: reset10, formState: { errors: errors10, isValid: isValid10 } } = useForm({
        mode: 'onChange', // Enables validation on change
    });

    const expirationDate = new Date(data.expiration_date);
    const today = new Date();
    const selectableReferences = userReferences.filter(userReference => {
        // Check if the userReference.id is not present in any of the sentInvitations
        return !sentInvitations.some(sentInvitation => sentInvitation.reference_id === userReference.id && sentInvitation.job_candidate_id === id);
    });

    const invitedReferences = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === id; // Compare with the extracted work_history_id
    });

    const onInviteReference = async (details) => {
        console.log(details)
        const selectedReference = userReferences.find(userReference => {
            //console.log('Checking job ID:', job.id, 'against', work_history_id);
            return userReference.id === details.reference_id; // Compare with the extracted work_history_id
        });
        console.log(selectedReference)
        details.user_id = selectedReference.user_id;
        details.job_candidate_id = id;
        details.email = selectedReference.reference_email;
        console.log(details)

        try {
            // const response = await inviteReferenceToAssessment(details);
            // console.log(response);
            // if (response.success) {
            //     response.data.reference_first_name = selectedReference.reference_first_name;
            //     response.data.reference_last_name = selectedReference.reference_last_name;
            //     response.data.reference_email = selectedReference.reference_email;
            //     response.data.user_id = selectedReference.user_id;
            //     response.data.expiration_date = data.expiration_date;
            //     response.data.assessment_name = data.assessment_name;
            //     response.data.job_name = data.job_name;
            //     response.data.job_company_name = data.job_company_name;
            //     response.data.job_location = data.job_location;
            //     response.data.recruiter_first_name = data.recruiter_first_name;
            //     response.data.recruiter_last_name = data.recruiter_last_name;
            //     response.data.recruiter_email = data.recruiter_email;
            //     //response.data.     
            //     console.log(response.data)
            //     addNewSentInvitation(response.data);
            //     setAppNotificationWithTimeout('Invitation sent.', 'success', 'Invitation successfully sent', 3000); // 3 seconds
            // } else {
            //     setAppNotificationWithTimeout('Error.', 'error', 'An error occured, invitation not sent.', 3000); // 3 seconds           
            // }
        } catch (error) {
            setAppNotificationWithTimeout('Error.', 'error', 'An error occured, invitation not sent.', 3000); // 3 seconds           
        }
    }

    return <div>
        <WorkReferencePage id={id}/>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Invited References ({invitedReferences.length}/3 invited)</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">Invite between 3 and 5 references to peer-assess you.</p>
        {/* {
            invitedReferences.length === 0
                ? <div className="text-center py-20 min-h-40">
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No sent invitations</h3>
                    <p className="mt-1 text-sm text-gray-500">You have not invited anybody to a peer-assessment. Invitations will appear here.</p>
                </div>
                : <>
                    <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                        {invitedReferences.map((sentInvitation, index) => (
                            <li className="flex justify-between gap-x-6 py-6">
                                {sentInvitation.reference_first_name} {sentInvitation.reference_last_name}
                                <div className="mt-1 flex flex-col text-gray-500">
                                    <span>{sentInvitation.reference_email}</span>
                                </div>
                                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                                    <span>{sentInvitation.job_name}</span>
                                    <span>{sentInvitation.assessment_name}</span>
                                </div>
                            </li>
                        ))
                        }
                    </ul>
                </>
        }

        <div className="border-t border-gray-100 pt-6">
            {
                showEditor && <form onSubmit={handleSubmit10(onInviteReference)}>
                    {
                        selectableReferences.length > 0
                            ? <SelectReference
                                label="Reference User"
                                id="reference_id"
                                options={selectableReferences}
                                required={true}
                                register={register10}
                                errors={errors10}
                                className={"w-full"}
                            />
                            : null
                    }

                    <div className="mt-4 sm:flex sm:justify-end">
                        <Button
                            variant="primary"
                            size="medium"
                            type="submit"
                            disabled={!isValid10}
                            className="mr-2"
                        >
                            Add Reference
                        </Button>
                        <Button
                            variant="secondary"
                            size="medium"
                            type="button"
                            onClick={() => setShowEditor(!showEditor)}
                            className=""
                        >
                            Return
                        </Button>
                    </div>
                </form>
            }

            {
                !showEditor && selectableReferences.length > 0 && invitedReferences.length < 5
                    ? <Button
                        variant="primary"
                        size="medium"
                        className={"w-full"}
                        type="button"
                        onClick={() => setShowEditor(!showEditor)}
                        disabled={today >= expirationDate || sentInvitations.length === 4}
                    >
                        Invite additional Reference
                    </Button>
                    : showEditor ? null :
                        <div className="text-center py-20 min-h-40">
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">Can't invite references</h3>

                            <p className="mt-1 text-sm text-gray-500">
                                {
                                    selectableReferences.length === 0
                                        ? "You have no more references to invite. To invite more references, please add some in your references section."
                                        : selectableReferences.length > 0 && invitedReferences === 5
                                            ? "You have invited the maximum number of reference users to review you."
                                            : "You have no more selectable references and you have invited the maximum number of reference users."
                                }
                            </p>
                        </div>
            }
        </div> */}
    </div>
}