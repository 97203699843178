import {create} from 'zustand';

const useExperimentStore = create((set, get) => ({
  tests: [],    
  friends: [],
  sentInvitations: [],
  receivedInvitations: [],
  recruiterInvitations: [],
  userReferences: [],
  workHistory: [],


  addFriends: (newFriends) => set((state) => ({
    friends: [
      ...state.friends,
      ...newFriends.filter(
        newFriend => !state.friends.some(friend => friend.id === newFriend.id)
      ),
    ]
  })),

  initializeSentInvitations: (initialSentInvitations) => set((state) => ({
    sentInvitations: [
      ...state.sentInvitations,
      ...initialSentInvitations.filter(
        initialSentInvitation => !state.sentInvitations.some(sentInvitation => sentInvitation.id === initialSentInvitation.id)
      ),
    ]
  })),

  addNewSentInvitation: (newSentInvitation) => set((state) => ({
    sentInvitations: [...state.sentInvitations, newSentInvitation]
  })),
  
  initializeReceivedInvitations: (initialReceivedInvitations) => set((state) => ({
    receivedInvitations: [
      ...state.receivedInvitations,
      ...initialReceivedInvitations.filter(
        initialReceivedInvitation => !state.receivedInvitations.some(receivedInvitation => initialReceivedInvitation.id === receivedInvitation.id)
      ),
    ]
  })),

  initializeRecruiterInvitations: (initialRecruiterInvitations) => {
    console.log(initialRecruiterInvitations);
    return set((state) => ({
      recruiterInvitations: [
        ...state.recruiterInvitations,
        ...(Array.isArray(initialRecruiterInvitations) ? initialRecruiterInvitations : []).filter(
          initialRecruiterInvitation => !state.recruiterInvitations.some(recruiterInvitation => initialRecruiterInvitation.id === recruiterInvitation.id)
        ),
      ]
    }));
  },
  
  editReceivedInvitation: (editedInvitation) => set((state) => ({
    receivedInvitations: state.receivedInvitations.map(receivedInvitation =>
      receivedInvitation.id === editedInvitation.id ? editedInvitation : receivedInvitation
    )
  })),


  findReceivedInvitation: (id) => {
    const { receivedInvitations } = get();
    const invitation = receivedInvitations.find(receivedInvitation => {
      return receivedInvitation.job_candidate_id === id;
    });

    return invitation;
  },

  initializeUserReferences: (initialUserReferences) => set((state) => ({
    userReferences: [
      ...state.userReferences,
      ...initialUserReferences.filter(
        initialUserReference => !state.userReferences.some(userReference => initialUserReference.id === userReference.id)
      ),
    ]
  })),

  addNewReference: (newUserReference) => set((state) => ({
    userReferences: [...state.userReferences, newUserReference]
  })),

  initializeWorkHistory: (initialWorkHistories) => set((state) => ({
    workHistory: [
      ...state.workHistory,
      ...initialWorkHistories.filter(
        initialWorkHistorie => !state.workHistory.some(workHistoryItem => initialWorkHistorie.id === workHistoryItem.id)
      ),
    ]
  })),

  addNewWorkHistory: (newWorkHistory) => set((state) => ({
    workHistory: [...state.workHistory, newWorkHistory]
  })),

  editWorkHistory: (editedWorkHistory) => set((state) => ({
    workHistory: state.workHistory.map(workHistoryEntry =>
        workHistoryEntry.id === editedWorkHistory.id ? editedWorkHistory : workHistoryEntry
    )
  })),

  removeWorkHistory: (deletableCandidateSkill) => set((state) => ({
    workHistory: state.workHistory.filter(historyEntry => historyEntry.id !== deletableCandidateSkill)
  })),

  findMutualWorkHistory: (idObj) => {
    const { work_history_id } = idObj; // Extract work_history_id from the passed object
    const { workHistory } = get();
    //console.log('Searching for work_history_id:', work_history_id);
  
    const mutualWork = workHistory.find(job => {
      //console.log('Checking job ID:', job.id, 'against', work_history_id);
      return job.id === work_history_id; // Compare with the extracted work_history_id
    });
  
    //console.log('Mutual Work:', mutualWork);
    return mutualWork;
  },
  
  findReferencesByWorkHistoryId: (workHistoryId, jobCandidateId) => {
    const { userReferences, sentInvitations } = get();
  
    const references = userReferences
      .filter(userReference => userReference.work_history_id === workHistoryId)
      .map(userReference => {
        // Check if there's a matching sentInvitation
        const isInSentInvitations = sentInvitations.some(invitation => {
          return (
            invitation.reference_id === userReference.id &&
            invitation.job_candidate_id === jobCandidateId // Assuming job_candidate_id matches user_id
          );
        });
  
        return {
          ...userReference,
          isInSentInvitations, // Add the key-value indicating presence in sentInvitations
        };
      });
  
    return references;
  },
  
  findReferencesByWorkHistoryIdWithStatus: (workHistoryId, jobCandidateId) => {
    const { userReferences, sentInvitations } = get();
  
    const references = userReferences
      .filter(userReference => userReference.work_history_id === workHistoryId)
      .map(userReference => {
        // Find the matching sentInvitation
        const matchingInvitation = sentInvitations.find(invitation => {
          return (
            invitation.reference_id === userReference.id &&
            invitation.job_candidate_id === jobCandidateId
          );
        });
  
        // Add 'status' from the found invitation, if any
        return {
          ...userReference,
          isInSentInvitations: !!matchingInvitation,
          status: matchingInvitation ? matchingInvitation.status : null, // Add status or null if no match
        };
      });
  
    return references;
  },
  

  findAssessmentById: (id) => {
    const { recruiterInvitations } = get();
    const assessment = recruiterInvitations.find(invitation => {
      return invitation.id === id;
    });

    return assessment;
  },

  findJobById: (id) => {
    const { recruiterInvitations } = get();
    const invitation = recruiterInvitations.find(recruiterInvitation => {
      return recruiterInvitation.id === id;
    });

    return invitation;
  },

  checkReferenceExists: (email) => {
    const { userReferences } = get();
    return userReferences.some(
      (ref) => ref.email === email
    );
  },

  
}))

export default useExperimentStore;