import { useState } from "react";
import Alert from "../../../components/Alert/Alert";
import Modal from "../../../components/Modal/Modal";
import Button from "../../../components/Buttons/Buttons";
import Dialog from "../../../components/Dialog/Dialog";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Notification from "../../../components/Notification/Notification";


function Feedback() {
    const [openModal, setOpenModal] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    return <div>
        <ul>
            <li>Alerts</li>
            <Alert type='success' message="Hello World"/>
            <li>Modals</li>
            <Button variant="brand" size="small" onClick={()=>setOpenModal(true)}>Modal</Button>
            <Modal isOpen={openModal} title={"Hello World"} children={"Hello"} onClose={()=>setOpenModal(!openModal)} />
            <li>Dialogs</li>
            <Button variant="brand" size="small" onClick={()=>setOpenDialog(true)}>Dialog</Button>
            <Dialog isOpen={openDialog} title={"Hello World"} children={"Hello"} onClose={()=>setOpenDialog(!openDialog)} />
            <li>Tooltips</li>
            <Tooltip text="Hello World">Get the idea</Tooltip>
            <li>Notifications</li>
            <Button variant="brand" size="small" onClick={()=>setOpenNotification(true)}>Dialog</Button>
            <Notification show={openNotification} type="info" message={"Hello World"} children={"Hello"} onClose={()=>setOpenNotification(!openNotification)} />
        </ul>
    </div>
}

export default Feedback;