import { H2, H3, P } from "../../../components/Typography/Typography";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
    createWorkHistory, updateWorkHistory, deleteWorkHistory,
} from "../../../services/candidateService";

import useGlobalStore from "../../../store/globalStore";
import useExperimentStore from "../../../store/experimentStore";
import useUserStore from "../../../store/userStore";
import { formatDate } from "../../../../legacy/utils/dateFormat";
import Button from "../../../components/Buttons/Buttons";
import { Input } from "../../../components/Form/Form";
import WorkUserReferences from "./1-2-RC-Work-References";
import Beautifier from "../../../../legacy/components/common/beautifier";
import WorkReferenceEditor from "./1-2-RC-Work-References-Editor";
import { useParams } from "react-router-dom";
import { HiPencil } from "react-icons/hi2";

function WorkReferencePage() {
    return <div>
        <WorkReferences />
    </div>
}
export default WorkReferencePage;

function WorkReferences({ id }) {
    const [editorOn, setEditorOn] = useState(false);
    const [editingJobAssessment, setEditingJobAssessment] = useState(false);
    const [editingWorkHistory, setEditingWorkHistory] = useState(false);
    const [editingCandidate, setEditingCandidate] = useState(false);
    const { setAppNotificationWithTimeout, setNotification, setNotificationStatus, notification } = useGlobalStore((state) => state);

    const { register: register8, handleSubmit: handleSubmit8, setValue: setValue8, reset: reset8, formState: { errors: errors8, isValid: isValid8 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingJobAssessment
    });

    // work history
    const { register: register9, handleSubmit: handleSubmit9, setValue: setValue9, reset: reset9, formState: { errors: errors9, isValid: isValid9 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingWorkHistory
    });

    // reference
    const { register: register10, handleSubmit: handleSubmit10, setValue: setValue10, reset: reset10, formState: { errors: errors10, isValid: isValid10 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingJobAssessment
    });

    const {
        addNewWorkHistory, removeWorkHistory, editWorkHistory, workHistory, sentInvitations, userReferences
        //findReferenceByWorkHistoryId
    } = useExperimentStore((state) => state);

    const { setUser, user } = useUserStore((state) => state);


    // ✅
    const onAddWorkHistory = async (data) => {
        const carry = { assessment_id: data.assessment, job_id: data.job, candidate_id: user.id };
        data.end_date = data.end_date ? data.end_date : null;
        data.candidate_id = user.id;
        const response = await createWorkHistory(data);
        console.log(response)
        addNewWorkHistory(response);
        setEditorOn(false);
        setAppNotificationWithTimeout('Work History added.', 'success', 'Work History added', 3000); // 3 seconds
        reset9();
    };


    // ✅
    const onEditWorkHistory = async (data) => {
        data.id = editingWorkHistory.id;
        const result = await updateWorkHistory(data);
        if (result.error) {
            setAppNotificationWithTimeout('Error updating work history.', 'error', 'Error updating work history', 3000); // 3 seconds
        } else {
            editWorkHistory({ ...editingWorkHistory, ...data });
            setEditingCandidate(null); // Clear editing state
            setAppNotificationWithTimeout('Work History updated.', 'success', 'Work History updated', 3000); // 3 seconds
            setEditorOn(false);
            reset9();
        }
    };

    const onAbortEditor = async (data) => {
        setEditorOn(false);
        reset9();
    }

    // ✅
    const onDeleteWorkHistory = async (data) => {

        // const result = await deleteWorkHistory(data);
        // if (!result.success) {
        //     setAppNotificationWithTimeout('Error deleting Work History.', 'error', 'Error deleting Work History.', 3000); // 3 seconds
        // } else {
        //     removeWorkHistory(data.id);
        //     setAppNotificationWithTimeout('Work History deleted.', 'success', 'Work History deleted', 3000); // 3 seconds
        // }
    }

    const startEditingWorkHistory = (workHistory) => {
        setEditingWorkHistory(workHistory);
        setValue9('company_name', workHistory.company_name, { shouldValidate: true });
        setValue9('company_url', workHistory.company_url, { shouldValidate: true });
        setValue9('position', workHistory.position, { shouldValidate: true });
        setValue9('start_date', formatDate(workHistory.start_date), { shouldValidate: true });
        setValue9('end_date', workHistory.end_date ? formatDate(workHistory.end_date) : null, { shouldValidate: true });
    };

    return <div className="mt-4">
        <H2>
            Work History


            <Button
                size="small"
                variant="tertiary"
                onClick={() => setEditorOn(true)}
                className="ml-2 rounded-full"
            >
                <HiPencil />
                {/* Add Work History */}
            </Button>
        </H2>
        {!editorOn &&
            <div className="-mx-4 mt-4  ring-gray-300 sm:mx-0 sm:rounded-lg">
                <ul role="list" className="divide-y divide-gray-200 gap-4">
                    {workHistory && workHistory.map((history, jobIdx) => {
                        //const workHistoryReferences = findReferenceByWorkHistoryId(history.id);
                        const workHistoryReferences = []
                        return <li key={history.id} className="py-4">
                            <WorkHistoryItem id={id} history={history} onDelete={onDeleteWorkHistory} onEditingWorkHistory={startEditingWorkHistory} references={workHistoryReferences} openWorkHistoryEditor={setEditorOn} />
                        </li>
                    })}
                </ul>
                <hr />
                <div className="mt-4 sm:flex sm:justify-end">
                    <Button
                        size="medium"
                        variant="primary"
                        onClick={() => setEditorOn(true)}
                        className=""
                    >
                        Add Work History
                    </Button>
                </div>
            </div>
        }

        {
            editorOn &&
            <>
                <form onSubmit={editingWorkHistory ? handleSubmit9(onEditWorkHistory) : handleSubmit9(onAddWorkHistory)} className="space-y-8 bg-white p-8 rounded-lg ">
                    <Input
                        label="Company Name"
                        id="company_name"
                        register={register9}
                        required={true}
                        errors={errors9}
                        type="text"
                    />
                    <Input
                        label="Company URL"
                        id="company_url"
                        register={register9}
                        required={true}
                        errors={errors9}
                        type="text"
                    />
                    <Input
                        label="Position"
                        id="position"
                        register={register9}
                        required={true}
                        errors={errors9}
                        type="text"
                    />
                    <Input
                        label="Start Date"
                        id="start_date"
                        register={register9}
                        required={true}
                        errors={errors9}
                        type="date"
                    />
                    <Input
                        label="End Date (leave blank if still present)"
                        id="end_date"
                        register={register9}
                        required={false}
                        errors={errors9}
                        type="date"
                    />

                    <div className="mt-4 sm:flex sm:justify-end">
                        <Button
                            size="medium"
                            variant="primary"
                            type="submit" disabled={!isValid9}
                            onClick={() => setEditorOn(true)}
                            className="px-3 py-2 mx-2"
                        >
                            {editingWorkHistory ? "Edit Work History" : "Add Work History"}
                        </Button>
                        <Button
                            size="medium"
                            variant="secondary"
                            onClick={() => onAbortEditor()}
                            className=""
                        >
                            Return
                        </Button>
                    </div>
                </form>

            </>


        }
    </div>
}
const WorkHistoryItem = ({ history, onDelete, id, references, openWorkHistoryEditor, onEditingWorkHistory }) => {
    const [referenceEditorOn, setReferenceEditorOn] = useState(false);
    const onOpenEditor = () => {
        openWorkHistoryEditor(true);
        onEditingWorkHistory(history);
    }

    const {
        sentInvitations
    } = useExperimentStore(state => state);

    const { id: invitationId } = useParams();

    //const {id} = useParams();
    const invitedReferences = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === invitationId; // Compare with the extracted work_history_id
    });


    return <div className="mb-4 border rounded-lg shadow-sm">
        <div className="flex flex-col md:flex-row justify-between items-center p-4 cursor-pointer">
            <div>
                <h3 className="font-semibold">{history.company_name}</h3>
                <p className="text-sm text-gray-600">
                    {history.position} | {formatDate(history.start_date)} - {history.end_date ? formatDate(history.end_date) : 'present'}
                </p>
            </div>
            <div className="flex space-x-2 mt-4 md:mt-0">
                <Button
                    size="small"
                    variant="secondary"
                    type="submit"
                    onClick={() => onOpenEditor()}
                    className="px-3 py-2"
                >
                    Edit
                </Button>
                {/* <Button
                    type="button"
                    size="small"
                    variant="tertiary"
                    onClick={() => onDelete(history)}
                >
                    Delete
                </Button> */}
            </div>
        </div>

        {
            referenceEditorOn && <WorkReferenceEditor changeEditorStatus={setReferenceEditorOn} workHistoryEntry={history.id} jobCandidateId={id} />
        }
        {
            !referenceEditorOn && <div className="p-4">
            <WorkUserReferences workId={history.id} sentInvitationsLength={invitedReferences.length}/>
            {
                invitedReferences.length<5 && <Button onClick={() => setReferenceEditorOn(!referenceEditorOn)} className="mt-2 text-brand-600 hover:underline flex items-center">
                Add a reference for {history.company_name}
            </Button>
            }

        </div>
        }



    </div>
}