import Footer from "../components/Footer/Footer";
import CandidateNavbar from "../components/Navbars/candidateNavbar/CandidateNavbar";
import useUserStore from "../store/userStore";
import ContainerFull from "../components/Containers/ContainerFull";
import AdminNavbar from "../components/Navbars/adminNavbar/AdminNavbar";


const AdminLayout = ({ children }) => {
    return <>
        <AdminNavbar />
            <ContainerFull>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 min-h-screen">{children}</div>
            </ContainerFull>
        <Footer />
    </>
}

export default AdminLayout;