import './../../../assets/styles/loadingScreen.css';
import Introduction from './1_introduction';
import Foundations from './2_foundations';
import Components from './3_components';
function DesignSystem() {
    return <div>
        <Introduction />
        <Foundations />
        <Components />
    </div>
}

export default DesignSystem;