// components/common/Heading.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
  HiChevronLeft as ChevronLeftIcon,
} from 'react-icons/hi2';
import Breadcrumbs from '../Breadcrumbs/breadcrumbs';
import classNames from '../../utils/ClassNames';

const Heading = ({
  title,
  breadcrumbs,
  details,
  actions,
  backLink,
  status
}) => {
  return (
    <div>
      <div>
        {backLink && (
          <nav className="sm:hidden" aria-label="Back">
            <Link to={backLink.to} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
              <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {backLink.text}
            </Link>
          </nav>
        )}
        {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {title} {status ?
              <span
                className={classNames(
                  'bg-gray-100 text-gray-900',
                  'ml-3 hidden rounded-full px-2.5 py-0.5 text-base font-medium md:inline-block',
                )}
              >
                {status}
              </span> : null
            }
          </h2>
          {details && (
            <dl className="mt-6 flex flex-col sm:mt-1 sm:flex-row sm:flex-wrap">
              {details.map((detail) => (
                
                <dd key={detail.label} className="flex items-center text-sm font-medium text-gray-500 sm:mr-6">
                  {detail.icon}
                  {detail.content}
                </dd>
              ))}
            </dl>
          )}
        </div>
        {actions && (
          <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            {actions.map((action) => (
              <button
                key={action.text}
                type="button"
                onClick={action.onClick}
                disabled={action.disabled}
                className={`${action.primary ? 'bg-brand-600 text-white hover:bg-brand-700' : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'} ml-3 inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600`}
              >
                {action.text}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;