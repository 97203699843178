export const job_data = [
    {
        "id": "43d4baff-f8cc-4909-878d-a60259fa24e5",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Updated Test Job",
        "description": "Updated test job description",
        "company_name": "GoSkilling",
        "location": "New York, NY",
        "status": "open",
        "created_at": "2024-06-12T15:35:44.250Z",
        "updated_at": "2024-08-16T18:21:02.878Z",
        "assessment_id": "ffa45d5b-dee9-4b98-821c-c07e481c8bc2",
        "assessment_name": "Istanbul Assessment",
        "assessment_description": "Test description",
        "recruiter_first_name": "Christian",
        "recruiter_last_name": "Kramp"
    },
    {
        "id": "9967406d-6172-433e-9354-24afb878aecd",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Transaction Analyst",
        "description": "Test description",
        "company_name": "General Electric",
        "location": "Cheyenne, WY, USA",
        "status": "draft",
        "created_at": "2024-06-04T10:36:26.477Z",
        "updated_at": "2024-07-03T12:29:28.554Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    },
    {
        "id": "a616e788-e5a5-4b0e-8d7e-0a8c2112390a",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Security Analyst",
        "description": "Analyzing stocks, bonds, and options.",
        "company_name": "JP Morgan Chase",
        "location": "New York, NY",
        "status": "draft",
        "created_at": "2024-06-09T21:45:44.364Z",
        "updated_at": "2024-06-09T21:45:44.364Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    },
    {
        "id": "d0a61147-628b-441b-8340-04a30e056fca",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Greenkeeper",
        "description": "Keep our golf courses green.",
        "company_name": "Augusta Golf & Country Club",
        "location": "Augusta, GA",
        "status": "draft",
        "created_at": "2024-07-05T16:16:11.104Z",
        "updated_at": "2024-07-05T16:16:11.104Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    },
    {
        "id": "d5b6be6a-6b75-4b4e-b47e-28a1b75efcd8",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Job August 4 2024",
        "description": "Get a job",
        "company_name": "GoSkilling",
        "location": "San Francisco, CA",
        "status": "draft",
        "created_at": "2024-08-04T13:52:56.884Z",
        "updated_at": "2024-08-04T13:52:56.884Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    },
    {
        "id": "dfdd5c7a-fb4c-47ec-a8b1-5ae1360e8e59",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Cybersecurity Analyst",
        "description": "You analyze our cyber security measures, change all pws from password to password1.",
        "company_name": "Levi Strauss, Inc.",
        "location": "San Francisco, CA",
        "status": "draft",
        "created_at": "2024-07-05T15:56:29.277Z",
        "updated_at": "2024-07-05T15:56:29.277Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    },
    {
        "id": "ec1db309-8cb6-49c7-9100-e7a4e01d4308",
        "recruiter_id": "d1e60917-72bf-4760-b89c-b63bf5d227f3",
        "organization_id": null,
        "name": "Sales Associate",
        "description": "Just a test job.",
        "company_name": "Fair Isaac",
        "location": "Seattle, WA",
        "status": "draft",
        "created_at": "2024-07-05T16:17:24.061Z",
        "updated_at": "2024-07-05T16:17:24.061Z",
        "assessment_id": null,
        "assessment_name": null,
        "assessment_description": null,
        "recruiter_first_name": null,
        "recruiter_last_name": null
    }
];