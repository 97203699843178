import { useState } from "react";
import useAdminStore from "../../../store/adminStore";
import { deleteUsers } from "../../../services/adminService";

function UserDetail({ user, onClose, onEdit }) {
  const [confirmDelete, setConfirmDelete] = useState("");
  const { removeStoredUser } = useAdminStore();
  const handleDelete = async() => {
    if (confirmDelete === "DELETE") {
      const deletedUser = await deleteUsers(user.id);
      if(deletedUser.success) {
        removeStoredUser(deletedUser.data.id)
        console.log("User deleted and removed from store");
      } else {
        console.log("Couldn't delete User");
      }
      onClose();
    }
  };

  return (
    <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
      <button onClick={onClose} className="text-gray-500 text-xl">×</button>
      <h2 className="text-xl font-bold mb-4">User Details</h2>
      <p><strong>ID:</strong> {user.id}</p>
      <p><strong>First Name:</strong> {user.first_name}</p>
      <p><strong>Last Name:</strong> {user.last_name}</p>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Role:</strong> {user.role}</p>

      <div className="mt-4 flex flex-wrap gap-2">
        <button
          onClick={onEdit}
          className="px-3 py-1 bg-gray-200 rounded-md hover:bg-gray-300"
        >
          Edit
        </button>
        <input
          type="text"
          placeholder="Type 'DELETE' to confirm"
          value={confirmDelete}
          onChange={(e) => setConfirmDelete(e.target.value)}
          className="border px-3 py-1 rounded-md"
        />
        <button
          onClick={handleDelete}
          disabled={confirmDelete !== "DELETE"}
          className={confirmDelete !== "DELETE" ? "px-3 py-1 bg-red-300 text-white rounded-md hover:bg-red-600" : "px-3 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

export default UserDetail;

