// components/common/Breadcrumbs.js
import React from 'react';
import { Link } from 'react-router-dom';
import {
    HiChevronRight as ChevronRightIcon,
} from 'react-icons/hi2';

const Breadcrumbs = ({ items }) => {
  return (
    <nav className="hidden sm:flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        {items.map((item, index) => (
          <li key={item.text}>
            <div className="flex items-center">
              {index > 0 && <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
              <Link 
                to={item.to} 
                className={`${index > 0 ? 'ml-4 ' : ''}text-sm font-medium ${
                  item.current ? 'text-gray-700' : 'text-gray-500 hover:text-gray-700'
                }`}
                aria-current={item.current ? 'page' : undefined}
              >
                {item.text}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;