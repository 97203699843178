import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/userStore';

const useHandleInvalidToken = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useUserStore();

  const handleInvalidToken = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return handleInvalidToken;
};

export default useHandleInvalidToken;
