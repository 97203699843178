import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/userStore';
import { checkAndValidateToken } from '../services/authService';

export const ProtectedRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useUserStore(); // Assume isAuthenticated is a boolean in the store
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      if (!isAuthenticated) {
        const result = await checkAndValidateToken();
        if (result.success) {
          setUser(result.user); // Set the user in the store
          setIsAuthenticated(true); // Mark as authenticated
        } else {
          localStorage.removeItem('authToken'); // Clean up token
          setIsAuthenticated(false);
          navigate('/login');
        }
      }
      setLoading(false);
    };

    if (!user) {
      validateToken();
    } else {
      setLoading(false);
    }
  }, [user, isAuthenticated, navigate, setUser, setIsAuthenticated]);

  useEffect(() => {
    if (!loading && user && allowedRoles && !allowedRoles.includes(user.role)) {
      navigate('/unauthorized');
    }
  }, [user, loading, navigate, allowedRoles]);

  if (loading || !user || (allowedRoles && !allowedRoles.includes(user.role))) {
    return null; // Show nothing while loading or redirecting
  }

  return children; // Render the wrapped component if authorized
};
