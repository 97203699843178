import Footer from "../components/Footer/Footer";
import RecruiterNavbar from "../components/Navbars/recruiterNavbar/RecruiterNavbar";
import ContainerFull from "../components/Containers/ContainerFull";


const RecruiterLayout = ({ children }) => {
    return <div className="">
        <RecruiterNavbar />
            <ContainerFull>
                {children}
            </ContainerFull>
        <Footer />
    </div>
}

export default RecruiterLayout;