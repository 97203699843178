import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
//import HeatmapTable from './HM';
import { fetchCanddiateResult } from '../../../services/recruiterService';
import Beautifier from '../../../../legacy/components/common/beautifier';
import { H1, H2, H3, H4, P } from '../../../components/Typography/Typography';
import { CardSimple } from '../../../components/Cards/CardWithFooter';
import Tabs from '../../../components/Tabs/Tabs';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';
import CandidateAssessmentRequirementResults from './CA_Requirements';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
const pageQuestionsB = [
    {
        id: "q1",
        question: "How motivated are you?",
        trait1: "1001",
        trait1_name: "Motivation",
        trait1_description: "Level of commitment to achieving goals.",
        trait2: "1002",
        trait2_name: "Work Ethic",
        trait2_description: "Reliability and responsibility in fulfilling tasks.",
        trait3: null,
    },
    {
        id: "q2",
        question: "How adaptable are you to change?",
        trait1: "1003",
        trait1_name: "Adaptability",
        trait1_description: "Ability to adjust to new situations.",
        trait2: "1004",
        trait2_name: "Stress Tolerance",
        trait2_description: "Capability to handle stressful situations.",
        trait3: null,
    },
    {
        id: "q3",
        question: "How do you handle criticism?",
        trait1: "1002",
        trait1_name: "Work Ethic",
        trait1_description: "Reliability and responsibility in fulfilling tasks.",
        trait2: "1003",
        trait2_name: "Adaptability",
        trait2_description: "Ability to adjust to new situations.",
        trait3: null,
    }
];

const requirementResults = [
    {
        name: "Communication",
        rating: "80",
        role: "manager"
    },
    {
        name: "Problem Solving",
        rating: "70",
        role: "subordinate"
    },
    {
        name: "Communication",
        rating: "90",
        role: "coworker"
    },
    {
        name: "Leadership",
        rating: "60",
        role: "manager"
    },
    {
        name: "Problem Solving",
        rating: "75",
        role: "manager"
    },
    {
        name: "Technical Skills",
        rating: "85",
        role: "subordinate"
    },
    {
        name: "Technical Skills",
        rating: "75",
        role: "coworker"
    }
];


const questionResultsB = [
    {
        id: "r1",
        question_id: "q1",
        rating: "80",
        role: "supervisor", // Maps to "Manager" in our setup
        trait1: "1001",
        trait2: "1002",
        trait3: null,
    },
    {
        id: "r2",
        question_id: "q1",
        rating: "70",
        role: "subordinate", // Maps to "Peer" in our setup
        trait1: "1001",
        trait2: "1002",
        trait3: null,
    },
    {
        id: "r3",
        question_id: "q2",
        rating: "90",
        role: "supervisor",
        trait1: "1003",
        trait2: "1004",
        trait3: null,
    },
    {
        id: "r4",
        question_id: "q2",
        rating: "60",
        role: "subordinate",
        trait1: "1003",
        trait2: "1004",
        trait3: null,
    },
    {
        id: "r5",
        question_id: "q3",
        rating: "75",
        role: "self", // Maps to "Self Reflection" in our setup
        trait1: "1002",
        trait2: "1003",
        trait3: null,
    }
];


const extractAndTransformDataForHeatmap = (questionResults, uniqueTraits) => {
    const roleScores = {
        average: {},
        manager: {},
        peer: {},
        self_reflection: {},
    };

    const roleMapping = {
        supervisor: "manager",
        subordinate: "peer",
        self: "self_reflection",
    };

    // Step 1: Aggregate ratings for each trait by role
    questionResults.forEach((result) => {
        const rating = parseInt(result.rating, 10) || 0;
        const role = roleMapping[result.role?.toLowerCase()] || 'average';

        // Ensure the role key exists in roleScores
        if (!roleScores[role]) roleScores[role] = {};

        [result.trait1, result.trait2, result.trait3].forEach((trait) => {
            if (trait) {

                // Initialize arrays to collect ratings
                if (!roleScores[role][trait]) roleScores[role][trait] = [];
                roleScores[role][trait].push(rating);

                // Add to overall (average) score as well
                if (!roleScores.average[trait]) roleScores.average[trait] = [];
                roleScores.average[trait].push(rating);
            }
        });
    });

    // Step 2: Compute average scores for each trait and role
    const computeAverageScores = (scoresObj) => {
        const averageScores = {};
        Object.keys(scoresObj).forEach(trait => {
            const scores = scoresObj[trait];
            const sum = scores.reduce((acc, score) => acc + score, 0);
            averageScores[trait] = scores.length ? sum / scores.length : 0;
        });
        return averageScores;
    };

    // Step 3: Map trait IDs in uniqueTraits to names and transform scores
    const labels = uniqueTraits.map(trait => trait.name); // Use trait names for labels

    const scores = {
        average: labels.map(label => {
            const trait = uniqueTraits.find(tr => tr.name === label)?.id;
            return computeAverageScores(roleScores.average)[trait] || 0;
        }),
        manager: labels.map(label => {
            const trait = uniqueTraits.find(tr => tr.name === label)?.id;
            return computeAverageScores(roleScores.manager)[trait] || 0;
        }),
        peer: labels.map(label => {
            const trait = uniqueTraits.find(tr => tr.name === label)?.id;
            return computeAverageScores(roleScores.peer)[trait] || 0;
        }),
        self_reflection: labels.map(label => {
            const trait = uniqueTraits.find(tr => tr.name === label)?.id;
            return computeAverageScores(roleScores.self_reflection)[trait] || 0;
        }),
    };
    return { labels, scores };
};


const createTable = (data) => {
    if (!data || !data.labels || !data.scores) {
        return <div>No Data Available</div>;
    }

    return (
        <table className="w-full border-collapse mt-5">
            <thead>
                <tr>
                    <th className="border p-2 bg-gray-100">Attribute</th>
                    <th className="border p-2 bg-gray-100">Overall</th>
                    <th className="border p-2 bg-gray-100">Subordinates</th>
                    <th className="border p-2 bg-gray-100">Managers</th>
                    <th className="border p-2 bg-gray-100">Co-workers</th>
                </tr>
            </thead>
            <tbody>
                {data.labels.map((label, index) => (
                    <tr key={label}>
                        <td className="border p-2">{label}</td>
                        <td className="border p-2">{data.scores.overall[index]}</td>
                        <td className="border p-2">{data.scores.subordinates[index]}</td>
                        <td className="border p-2">{data.scores.managers[index]}</td>
                        <td className="border p-2">{data.scores.coworkers[index]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const chartOptions = {
    scales: {
        r: {
            angleLines: {
                display: false
            },
            suggestedMin: 0,
            suggestedMax: 5
        }
    },
    maintainAspectRatio: false
};

const createChartData = (data, label) => ({
    labels: data.labels,
    datasets: [{
        label: label,
        data: data.scores.overall,
        fill: true,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        pointBackgroundColor: 'rgb(54, 162, 235)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(54, 162, 235)'
    }]
});

const CandidateAssessment = () => {
    const { assessmentId, jobCandidateId } = useParams();

    const [pageQuestions, setPageQuestions] = useState([]);
    const [pageRequirements, setPageRequirements] = useState([]);
    const [questionResults, setQuestionResults] = useState([]);
    const [requirementResults, setRequirementResults] = useState([{
        labels: ["Communication", "Problem Solving", "Leadership", "Technical Skills"],
        scores: {
            overall: {
                "Communication": [80, 90],        // Example ratings for "Communication" in overall scores
                "Problem Solving": [70, 75],
                "Leadership": [60],
                "Technical Skills": [85, 75]
            },
            subordinates: {
                "Communication": [78],
                "Problem Solving": [72],
                "Leadership": [68],
                "Technical Skills": [70]
            },
            managers: {
                "Communication": [82, 88],
                "Problem Solving": [77],
                "Leadership": [80],
                "Technical Skills": [84, 78]
            },
            coworkers: {
                "Communication": [86],
                "Problem Solving": [79, 85],
                "Leadership": [65],
                "Technical Skills": [75, 83]
            }
        }
    }]);
    const [transformedRequirementResults, setTransformedRequirementResults] = useState({
        labels: ["Communication", "Technical Skills", "Problem Solving", "Leadership"],
        scores: {
          overall: {
            "Communication": 85.0,
            "Problem Solving": 72.5,
            "Leadership": 60.0,
            "Technical Skills": 80.0
          },
          subordinates: {
            "Problem Solving": 70.0,
            "Technical Skills": 85.0
          },
          managers: {
            "Communication": 80.0,
            "Leadership": 60.0,
            "Problem Solving": 75.0
          },
          coworkers: {
            "Communication": 90.0,
            "Technical Skills": 75.0
          }
        }
      }
      );
    //const [transformedQuestionResults, setTransformedQuestionResults] = useState({ labels: [], scores: {} });
    const [assessmentData, setAssessmentData] = useState([]);
    const [candidateData, setCandidateData] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [currentTab, setCurrentTab] = useState('Results');
    const [transformedData, setTransformedData] = useState({ labels: [], scores: {} });

    const tabs = [
        { name: 'Results', href: '#', current: currentTab === 'Results' },
        { name: 'Information', href: '#', current: currentTab === 'Information' },
        { name: 'References', href: '#', current: currentTab === 'References' },
    ];

    const extractUniqueTraits = (pageQuestions) => {
        const traitsMap = new Map();

        pageQuestions.forEach((item) => {
            if (item.trait1) {
                traitsMap.set(item.trait1, {
                    id: item.trait1,
                    name: item.trait1_name,
                    description: item.trait1_description,
                });
            }
            if (item.trait2) {
                traitsMap.set(item.trait2, {
                    id: item.trait2,
                    name: item.trait2_name,
                    description: item.trait2_description,
                });
            }
            if (item.trait3) {
                traitsMap.set(item.trait3, {
                    id: item.trait3,
                    name: item.trait3_name,
                    description: item.trait3_description,
                });
            }
        });

        const uniqueTraits = Array.from(traitsMap.values());
        return uniqueTraits;
    };



    useEffect(() => {
        async function fetchData() {
            const data = await fetchCanddiateResult(jobCandidateId, assessmentId);


            if (data) {
                const requirements = data.data.assessmentRequirements || [];
                const questions = data.data.assessmentQuestions || [];
                const assessmentQuestionResults = data.data.assessmentQuestionResults || [];
                const assessmentRequirementResults = data.data.assessmentRequirementResults || [];
                const assessmentDataLoad = data.data.assessmentData || [];
                const candidateDataLoad = data.data.candidateData || [];
                const referenceDataLoad = data.data.references || [];
                const uniqueTraits = extractUniqueTraits(pageQuestions); // Extract unique traits
                const transformed = extractAndTransformDataForHeatmap(questionResults, uniqueTraits);
                setTransformedData(transformed);
                setPageQuestions(questions);
                setQuestionResults(assessmentQuestionResults);
                setRequirementResults(assessmentRequirementResults);
                setPageRequirements(requirements);
                setAssessmentData(assessmentDataLoad);
                setCandidateData(candidateDataLoad);
                setReferenceData(referenceDataLoad);
                setPageLoading(false);
            } else {
                setPageError(true);
            }
        }
        fetchData();
    }, [assessmentId, jobCandidateId]);

    useEffect(() => {
        if (requirementResults.length > 0) {
            const transformedData = transformData(requirementResults);
            //setTransformedRequirementResults(transformedData);
        }
    }, [requirementResults]);

    const transformData = (data) => {
        const labelsSet = new Set();
        const roleScores = {
            overall: {},
            subordinates: {},
            managers: {},
            coworkers: {}
        };
    
        data.forEach((item) => {
            const skillName = item.name || 'Unknown Skill';
            const rating = parseInt(item.rating, 10) || 0;
            const role = item.role;
    
            labelsSet.add(skillName);
    
            if (!roleScores.overall[skillName]) roleScores.overall[skillName] = [];
            roleScores.overall[skillName].push(rating);
    
            if (role === 'subordinate') {
                if (!roleScores.subordinates[skillName]) roleScores.subordinates[skillName] = [];
                roleScores.subordinates[skillName].push(rating);
            } else if (role === 'manager') {
                if (!roleScores.managers[skillName]) roleScores.managers[skillName] = [];
                roleScores.managers[skillName].push(rating);
            } else if (role === 'coworker') {
                if (!roleScores.coworkers[skillName]) roleScores.coworkers[skillName] = [];
                roleScores.coworkers[skillName].push(rating);
            }
        });
    
        // Convert Set to Array for labels and return the transformed data
        return {
            labels: Array.from(labelsSet),
            scores: {
                overall: Object.fromEntries(Object.entries(roleScores.overall).map(([key, values]) => [key, values])),
                subordinates: Object.fromEntries(Object.entries(roleScores.subordinates).map(([key, values]) => [key, values])),
                managers: Object.fromEntries(Object.entries(roleScores.managers).map(([key, values]) => [key, values])),
                coworkers: Object.fromEntries(Object.entries(roleScores.coworkers).map(([key, values]) => [key, values]))
            }
        };
    };
    
    //const uniqueTraits = extractUniqueTraits(questionResults);
    if (pageLoading) {
        return <LoadingScreen />
    }

    if (pageError) {
        return <ErrorPage />
    }

    return (
        <CardSimple
            contentData={!transformedRequirementResults.length > -1 &&
                <>
                    <H1>Peer Assessment Results</H1>
                    <H2 className="text-2xl font-semibold ">{candidateData.first_name + ' ' + candidateData.last_name}</H2>
                    <h4 className="text-gray-400 text-xl font-semibold mb-4 ">{candidateData.email}</h4>
                    <Tabs tabs={tabs} onTabChange={setCurrentTab} />
                    {
                        currentTab === 'Results'
                        && <>
                            {
                                !transformedData.length > 0 && !transformedRequirementResults.length > 0 ?
                                    <>
                                        <H3>Results</H3>
                                        {/* <Beautifier value={pageQuestions} />
                            <Beautifier value={questionResults} /> */}
                                        <HeatmapTable transformedData={transformedData} />
                                        {/* <AssessedQuestions questions={pageQuestions} results={questionResults} /> */}
                                        <CandidateAssessmentRequirementResults requirementData={createChartData(transformedRequirementResults, 'Skill Requirements')} transformedRequirementResults={transformedRequirementResults} chartOptions={chartOptions} />
                                    </>
                                    : <P>There's no data available for this candidate.</P>
                            }

                        </>

                    }
                    {currentTab === 'References'
                        && <>
                            <ul className="divide-y divide-gray-200 gap-4">
                                {referenceData.map((reference) => (
                                    <li key={reference.id} className="flex items-center justify-between gap-x-6 py-2">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    {reference.first_name} {reference.last_name}
                                                </p>
                                                <p>
                                                    {reference.company_name}
                                                </p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">Relationship: {candidateData.first_name}'s {reference.role}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <p
                                                className={'mt-0.5 whitespace-nowrap rounded-md px-3 py-1.5 text-base font-medium'}
                                            >
                                                {reference.status}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>}

                    {currentTab === 'Information'
                        && <>
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Job Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Job and assessment data.</p>
                            </div>
                            <div className="mt-4">
                                <dl className="grid grid-cols-1 sm:grid-cols-2">
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Job Title</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.job_name}</dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Company and Location</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.company_name} | {candidateData.location}</dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Assessment</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.assessment_name} </dd>
                                    </div>

                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Job Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                            {candidateData.description}
                                        </dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Assessment Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                            {assessmentData && assessmentData[0] && assessmentData[0].description}
                                        </dd>
                                    </div>
                                    {/* {JSON.stringify(referenceData)} */}
                                </dl>
                            </div>
                        </>}
                </>

            }
        />
    )
}

const AssessedQuestions = ({ questions, results }) => {
    return (
        <>
            {/* Render your questions here */}
            {/* <Beautifier value={questions} />
            <Beautifier value={results} /> */}
        </>
    );
};

const HeatmapTable = ({ transformedData }) => {
    const categories = transformedData.labels || []; // Trait names
    const evaluators = ['Average', 'Manager', 'Peer', 'Self Reflection']; // Columns for each role

    const getScores = (role) => {
        return transformedData.scores[role.toLowerCase().replace(/\s/g, "_")] || [];
    };

    const getColor = (score) => {
        if (score === 0 || score === undefined) return 'bg-gray-200';
        if (score >= 80) return 'bg-teal-600';
        if (score >= 60) return 'bg-teal-400';
        if (score >= 40) return 'bg-yellow-400';
        return 'bg-red-400';
    };

    return (
        <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Psychometric Questions Scorecard</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-separate border-spacing-2">
                    <thead>
                        <tr>
                            <th className="p-2 text-left bg-gray-200 w-32">Traits</th>
                            {evaluators.map((evaluator, idx) => (
                                <th key={idx} className="p-2 text-center bg-gray-200 w-16">
                                    <div className="text-sm">{evaluator}</div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category, rowIndex) => (
                            <tr key={`row-${rowIndex}`}>
                                {/* Display Trait Name */}
                                <td className="p-2 text-left bg-gray-100 w-32">{category}</td>

                                {/* Display Score Cells for Each Evaluator */}
                                {evaluators.map((evaluator, colIndex) => {
                                    const scores = getScores(evaluator); // Get scores for this evaluator
                                    const score = scores[rowIndex];
                                    return (
                                        <td
                                            key={`cell-${rowIndex}-${colIndex}`}
                                            className={`w-16 h-16 ${getColor(score)} rounded-md`}
                                        >
                                            {score !== undefined ? score.toFixed(1) : ""} {/* Display score */}
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CandidateAssessment;