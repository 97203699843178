import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { H1 } from '../../../components/Typography/Typography';
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);
export default function CandidateAssessmentRequirementResults({ requirementData, chartOptions, transformedRequirementResults }) {

    const createTable = (data) => {
        if (!data || !data.labels || !data.scores) {
            return <div>No Data Available</div>;
        }
    
        return (
            <table className="w-full border-collapse mt-5">
                <thead>
                    <tr>
                        <th className="border p-2 bg-gray-100">Attribute</th>
                        <th className="border p-2 bg-gray-100">Overall</th>
                        <th className="border p-2 bg-gray-100">Subordinates</th>
                        <th className="border p-2 bg-gray-100">Managers</th>
                        <th className="border p-2 bg-gray-100">Co-workers</th>
                    </tr>
                </thead>
                <tbody>
                    {data.labels.map((label) => (
                        <tr key={label}>
                            <td className="border p-2">{label}</td>
                            <td className="border p-2">{data.scores.overall[label] !== undefined ? data.scores.overall[label].toFixed(1) : 'N/A'}</td>
                            <td className="border p-2">{data.scores.subordinates[label] !== undefined ? data.scores.subordinates[label].toFixed(1) : 'N/A'}</td>
                            <td className="border p-2">{data.scores.managers[label] !== undefined ? data.scores.managers[label].toFixed(1) : 'N/A'}</td>
                            <td className="border p-2">{data.scores.coworkers[label] !== undefined ? data.scores.coworkers[label].toFixed(1) : 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };
    
    return (
        <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Skill Requirements Scorecard</h2>
            <div className="overflow-x-auto">
                {transformedRequirementResults && transformedRequirementResults.labels.length > 0 && createTable(transformedRequirementResults)}
            </div>
        </div>
    );
    



}