import { create } from 'zustand';

const useStore = create((set) => ({
  user: null,
  isLoading: true,
  setUser: (userData) => set({ user: userData, isLoading: false }),
  clearUser: () => set({ user: null, isLoading: false }),
  setLoading: (isLoading) => set({ isLoading }),
  
}));

export default useStore;