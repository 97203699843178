// src/components/SpacingSection.js

import React from 'react';
import { H1, H2, P } from '../../../components/Typography/Typography';

function Spacing() {
  return (
    <div className="p-8">
      <H1 className="mb-8">Spacing in GoSkilling</H1>
      
      <div className="mb-8">
        <H2 className="mb-4">Margin and Padding</H2>
        <P className="mb-4">
          Tailwind CSS provides a powerful set of utilities to manage spacing within your application. 
          Here, we demonstrate how to use these utilities to achieve consistent spacing.
        </P>
        
        <div className="bg-gray-200 p-4 mb-4">
          <P className="mb-2">Box with padding (p-4)</P>
          <div className="bg-white p-4">
            <P>Inner box with padding (p-4)</P>
          </div>
        </div>
        
        <div className="bg-gray-200 mb-4">
          <P className="p-4">Box with padding (p-4)</P>
        </div>
        
        <div className="bg-gray-200 mb-8 p-4">
          <P className="mb-4">Box with margin bottom (mb-4)</P>
          <div className="bg-white p-4 mb-4">
            <P>Inner box with margin bottom (mb-4)</P>
          </div>
        </div>
      </div>

      <div className="mb-8">
        <H2 className="mb-4">Examples</H2>
        
        <div className="bg-gray-200 p-6 mb-8">
          <P className="mb-4">Example with padding (p-6)</P>
          <div className="bg-white p-6">
            <P>Inner example with padding (p-6)</P>
          </div>
        </div>

        <div className="bg-gray-200 mb-8 p-4">
          <P className="mb-4">Example with margin bottom (mb-8)</P>
          <div className="bg-white p-4 mb-4">
            <P>Inner example with margin bottom (mb-4)</P>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spacing;
