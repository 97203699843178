// src/components/Alert.js

import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/ClassNames';

const Alert = ({ type, message }) => {
  const baseStyles = 'p-4 mb-4 rounded';
  const typeStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    warning: 'bg-yellow-500 text-black',
    info: 'bg-blue-500 text-white',
  };

  return (
    <div className={classNames(baseStyles, typeStyles[type])}>
      {message}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  message: PropTypes.string.isRequired,
};

export default Alert;
