// src/config.js

// Company Information
export const COMPANY_NAME = "GoSkilling";
export const COMPANY_TAGLINE = "Innovation and Excellence";
export const COMPANY_DESCRIPTION = "We provide cutting-edge solutions to meet your needs.";
export const COMPANY_MISSION = "To revolutionize the industry with innovative technology.";
export const COMPANY_VALUES = [
  "Integrity",
  "Customer Satisfaction",
  "Innovation",
  "Excellence"
];

// Contact Information
export const SUPPORT_EMAIL = "support@goskilling.com";
export const SALES_EMAIL = "sales@goskilling.com";
export const PHONE_NUMBER = "+1-555-555-5555";
export const CONTACT_ADDRESS = "1234 Main St, New York, NY, USA";

// Social Media Links
export const SOCIAL_MEDIA = {
  tiktok: "https://tiktok.com/goskilling",
  facebook: "https://facebook.com/goskilling",
  linkedin: "https://linkedin.com/company/goskilling",
  instagram: "https://instagram.com/goskilling"
};

// Marketing Copy
// Homepage Hero
export const HOMEPAGE_HEADLINE = "AI-supported Candidate Assessments";
export const HOMEPAGE_SUBHEADLINE = "Where science and humans go hand in hand to find your right candidate.";

// Homepage Features
export const FEATURE_HEADLINE = "Hire Faster. And Better.";
export const FEATURE_SUBHEADLINE = "Science meets People";
export const FEATURE_INTRO = "Unleash the power of your business with the help of GoSkilling's peer-assessments.";
export const FEATURE_F1_HEADLINE = "Peer-Assessments";
export const FEATURE_F1_DESCRIPTION = "We are using assessment-related surveys to evaluate a candidate's abilities so you don't have to.";
export const FEATURE_F2_HEADLINE = "Moving, Not Fixed";
export const FEATURE_F2_DESCRIPTION = "Our results are always up to date and never based on recommendations from one or more years ago.";
export const FEATURE_F3_HEADLINE = "Unbiased";
export const FEATURE_F3_DESCRIPTION = "We focus on providing an unbiased report. Results are anonymized to guarantee objective results.";

// Homepage CTA
export const CTA_HEADING1 = "Get in Touch";
export const CTA_HEADING2 = "Let's level up your recruting.";

// Homepage Pricing
export const PRICING_DESCRIPTION = "Every organization is different. We are tailoring prices to meet your needs."
export const PRICING_FEATURES = ['Talent Insights','Assessments'];
export const PRICING_CATEGORY_NAME = "Monthly Membership";
export const PRICING_CATEGORY_DESCRIPTION = "All-in-one package to speed up your hiring decisions.";
export const PRICING_TURNUS = "Pay monthly. Cancel at month's end..";

// Homepage FAQ
export const FAQ_QUESTIONS = [{
  question: 'How does GoSkilling work?',
  answer:
    `GoSkilling uses advanced assessment techniques to gather detailed insights from candidates' peers. Recruiters can create tailored assessments, allowing peers to evaluate a candidate's qualities based on various scientifically-backed criteria. These assessments provide a holistic understanding of the candidate's strengths, going beyond conventional evaluation methods.`,
}, {
  question: 'Can GoSkilling really improve hiring in our company?',
  answer:
  `Our approach empowers recruiters to make more data-driven hiring decisions, leading to a stronger match between candidates and job roles. By leveraging peer insights, recruiters gain a deeper understanding of each candidate's suitability, improving hiring outcomes. For candidates, this process offers a fair and comprehensive evaluation that highlights their true potential beyond traditional interviews.`
},
]
// Homepage Footer


// About Us
export const ABOUT_US_HEADING = "About Us";
export const ABOUT_US_TEXT = "Your Company has been a leader in the industry for over a decade...";


// Legal Texts
export const TERMS_OF_SERVICE = "By using our services, you agree to the following terms...";
export const PRIVACY_POLICY = "We value your privacy. Read our policy to learn more...";

// Product Descriptions
export const PRODUCT_1_NAME = "Product One";
export const PRODUCT_1_DESCRIPTION = "A revolutionary product that offers unmatched performance.";
export const PRODUCT_2_NAME = "Product Two";
export const PRODUCT_2_DESCRIPTION = "An innovative solution designed to meet your needs.";
