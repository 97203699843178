import React from 'react';
import PropTypes from 'prop-types';

// Define the predefined color sets
const colorClasses = {
  green: {
    bgColor: 'bg-green-50',
    textColor: 'text-green-700',
    ringColor: 'ring-green-600/20',
  },
  red: {
    bgColor: 'bg-red-50',
    textColor: 'text-red-700',
    ringColor: 'ring-red-600/20',
  },
  yellow: {
    bgColor: 'bg-yellow-50',
    textColor: 'text-yellow-700',
    ringColor: 'ring-yellow-600/20',
  },
  blue: {
    bgColor: 'bg-blue-50',
    textColor: 'text-blue-700',
    ringColor: 'ring-blue-600/20',
  },
};

const Badge = ({
  text = 'Active',
  color = 'green', // Default to green
  size = 'small',
  additionalClasses = ''
}) => {
  // Get the color classes based on the color prop
  const { bgColor, textColor, ringColor } = colorClasses[color];

  // Define size-based padding and text classes
  const sizeClasses = {
    small: 'px-2 py-1 text-xs',
    medium: 'px-3 py-1.5 text-sm',
    large: 'px-4 py-2 text-base',
  };

  return (
    <span
      className={`inline-flex items-center rounded-md ${bgColor} ${sizeClasses[size]} font-medium ${textColor} ring-1 ring-inset ${ringColor} ${additionalClasses}`}
    >
      {text}
    </span>
  );
};

// Define prop types for validation
Badge.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(['green', 'red', 'yellow', 'blue']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  additionalClasses: PropTypes.string,
};

export default Badge;
