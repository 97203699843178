// src/components/Notification.js

import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';

const Notification = ({ show, type, message, onClose }) => {
  const typeStyles = {
    success: 'bg-green-500 text-white',
    error: 'bg-red-500 text-white',
    warning: 'bg-yellow-500 text-black',
    info: 'bg-blue-500 text-white',
  };

  return (
    <Transition
      show={show}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${typeStyles[type]}`}
    >
      <div className="flex justify-between items-center">
        <span>{message}</span>
        <button className="ml-4 text-lg font-bold" onClick={onClose}>&times;</button>
      </div>
    </Transition>
  );
};

Notification.propTypes = {
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'warning', 'info']).isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notification;
