import { P } from "../../../components/Typography/Typography";
import './../../../assets/styles/app.css';
import image from './../../../assets/images/image.jpeg';
function Imagery() {
    return <div>
        <img src={image} alt=""/>
        <P>An image of a person in a modern office in a flat illustration style. The background should be bright abstract shapes and colors should be cool, but welcoming.</P>
    </div>
}

export default Imagery;