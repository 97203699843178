import React, { useState } from 'react';
import useStore from '../../../state/userState';
import useAdminStore from "../../../state/adminState";
import useCandidateStore from "../../../state/candidateState";
import useRecruiterStore from "../../../state/recruiterState";
import { useForm } from "react-hook-form";
import {
    createCandidateSkills, deleteCandidateSkill,
} from './../../../services/candidateService';
import Beautifier from '../../../components/common/beautifier';


function SkillPage() {
    //const { setUser, user } = useStore((state) => state);
    const { appSkills, findSkillById, setNotification, setNotificationWithTimeout} = useAdminStore((state) => state);

    const { setUser, user } = useStore((state) => state);
    const { register: register8, handleSubmit: handleSubmit8, setValue: setValue8, reset: reset8, formState: { errors: errors8, isValid: isValid8 } } = useForm({
        mode: 'onChange', // Enables validation on change
    });

    //const [notificationText, setNotificationText] = useState("All systems nominal.");
    const [editorOn, setEditorOn] = useState(false);
    const {
        //addNewCandidateSkill 
    } = useRecruiterStore((state) => state);
    const {
        candidateSkills, removeCandidateSkill, addNewCandidateSkill
    } = useCandidateStore((state) => state);

    const availableSkills = appSkills.filter(
        (skill) => !candidateSkills.some((candidateSkill) => candidateSkill.skill_id === skill.id)
    )

    const onAddCandidateSkill = async (data) => {
        const carry = { candidate_id: user.id, skill_id: data.skill_id };
        const skillData = findSkillById({ id: data.skill_id });
        carry.skill_name = skillData[0].name;
        carry.skill_description = skillData[0].name;
        carry.score = null;
        carry.score_date = null;
        const response = await createCandidateSkills(carry);
        carry.id = response.id;
        addNewCandidateSkill(carry);
        setNotificationWithTimeout("Skill added.",'success',3000);
        setEditorOn(false);

    };

    const onDeleteCandidateSkill = async (data) => {
        console.log(data)
        const result = await deleteCandidateSkill({ id: data });
        console.log(result)
        if (result.error) {
            setNotificationWithTimeout('Error deleting candidate skill.','error',3000);
            //setNotification("Error deleting candidate skill.");
        } else {
            removeCandidateSkill(data);
            setNotificationWithTimeout('Candidate deleted.','success',3000);
            //setNotification("Candidate deleted.");
        }
    }

    return <div className="px-4 sm:px-6 lg:px-8 card">
        {!editorOn &&
            <div className="-mx-4 mt-10  ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Skill
                            </th>
                            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                Score
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Remove</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {JSON.stringify(candidateSkills)} */}
                        {candidateSkills && candidateSkills.map((userSkill, jobIdx) => (
                            <UserSkillItem key={userSkill.id} userSkill={userSkill} onDelete={onDeleteCandidateSkill} />
                        ))}
                    </tbody>
                </table>
                <div className="mt-4 sm:flex sm:justify-end">
                    <button
                        onClick={() => setEditorOn(true)}
                        className="block rounded-md bg-accent-600 px-3 py-2 mx-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                        Add
                    </button>
                </div>
            </div>
        }

        {
            editorOn &&
            <form onSubmit={handleSubmit8(onAddCandidateSkill)} className="space-y-8 bg-white p-8 rounded-lg ">
                <div>
                    <label htmlFor="skill_id" className="block text-base font-medium text-gray-700">Skill</label>
                    <div className="mt-2">
                        <select
                            id="skill_id"
                            {...register8('skill_id', { required: 'Skill is required' })}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-lg"
                        >
                            <option value="">Select a skill</option>
                            {availableSkills.map(skill => (
                                <option key={skill.id} value={skill.id}>{skill.name}</option>
                            ))}
                        </select>
                        {errors8.skill_id && <p className="mt-2 text-base text-red-600">{errors8.skill_id.message}</p>}
                    </div>
                </div>
                <div className="mt-4 sm:flex sm:justify-end">
                    <button
                        type="submit" disabled={!isValid8}
                        className="block rounded-md bg-brand-600 px-3 py-2 mx-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                        Add
                    </button>
                    <button
                        onClick={() => setEditorOn(false)}
                        className="block rounded-md bg-accent-600 px-3 py-2 mx-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-accent-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                    >
                        Return
                    </button>
                </div>
            </form>
        }
    </div>
}


const UserSkillItem = ({ userSkill, onDelete, onEdit }) => {
    //const { countAssessmentSkills, countAssessmentJobs } = useRecruiterStore((state) => state);

    return (
        <tr key={userSkill.id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {userSkill.skill_name} {userSkill.category}
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span>{userSkill.location}</span>
                    {/* <span>{countAssessmentSkills(assessment.id)}</span>
            <span>{countAssessmentJobs(assessment.id)}</span> */}
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {userSkill.score ? userSkill.score : "--"}
            </td>
            <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button
                    type="button"
                    onClick={() => onDelete(userSkill.id)}
                    className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                    Delete
                </button>
            </td>
        </tr>
    );
};

export default SkillPage;