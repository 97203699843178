import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';
import HeatmapTable from './HM';
import { fetchCanddiateResult } from '../../../services/recruiterService';
import Beautifier from '../../../../legacy/components/common/beautifier';
import { H1, H2, H4 } from '../../../components/Typography/Typography';
import { CardSimple } from '../../../components/Cards/CardWithFooter';
import Tabs from '../../../components/Tabs/Tabs';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const PeerAssessmentResults = () => {
    const {assessmentId, jobCandidateId } = useParams();
    //let assessmentId = '14db6ecb-3fbd-437b-9028-733ff25dbd50';
    //let jobCandidateId = '53e9d5c5-918b-4391-ba10-8040d9b204ee';
    const [pageQuestions, setPageQuestions] = useState([]);
    const [pageRequirements, setPageRequirements] = useState([]);
    const [questionResults, setQuestionResults] = useState([]);
    const [requirementResults, setRequirementResults] = useState([]);
    const [transformedRequirementResults, setTransformedRequirementResults] = useState({ labels: [], scores: {} });
    const [transformedQuestionResults, setTransformedQuestionResults] = useState({ labels: [], scores: {} });
    const [assessmentData, setAssessmentData] = useState([]);
    const [candidateData, setCandidateData] = useState([]);
    const [referenceData, setReferenceData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [pageError, setPageError] = useState(false);
    const [currentTab, setCurrentTab] = useState('Results');


    useEffect(() => {
        async function fetchData() {
            const data = await fetchCanddiateResult(jobCandidateId,assessmentId);
            console.log("data")
            console.log(data)
            if (data) {
                const requirements = data.data.assessmentRequirements || [];
                const questions = data.data.assessmentQuestions || [];
                const assessmentQuestionResults = data.data.assessmentQuestionResults || [];
                const assessmentRequirementResults = data.data.assessmentRequirementResults || [];
                const assessmentDataLoad = data.data.assessmentData || [];
                const candidateDataLoad = data.data.candidateData || [];
                const referenceDataLoad = data.data.references || [];

                setPageQuestions(questions);
                setQuestionResults(assessmentQuestionResults);
                setRequirementResults(assessmentRequirementResults);
                setPageRequirements(requirements);
                setAssessmentData(assessmentDataLoad);
                setCandidateData(candidateDataLoad);
                setReferenceData(referenceDataLoad);
                setPageLoading(false);
            } else {
                setPageError(true);
            }
        }
        fetchData();
    }, [assessmentId, jobCandidateId]);

    useEffect(() => {
        if (requirementResults.length > 0) {
            const transformedData = transformData(requirementResults);
            setTransformedRequirementResults(transformedData);
        }
        if (questionResults.length > 0) {
            const transformedPsychometricData = transformPsychometricData(uniqueTraitys);
            setTransformedQuestionResults(transformedPsychometricData);
        }
    }, [requirementResults]);

    const transformData = (data) => {
        const labelsSet = new Set();
        const roleScores = {
            overall: {},
            subordinates: {},
            managers: {},
            coworkers: {}
        };

        data.forEach((item) => {
            const skillName = item.name || 'Unknown Skill';
            const rating = parseInt(item.rating, 10) || 0;
            const role = item.role;

            labelsSet.add(skillName);

            if (!roleScores.overall[skillName]) roleScores.overall[skillName] = [];
            roleScores.overall[skillName].push(rating);

            if (role === 'subordinate') {
                if (!roleScores.subordinates[skillName]) roleScores.subordinates[skillName] = [];
                roleScores.subordinates[skillName].push(rating);
            } else if (role === 'manager') {
                if (!roleScores.managers[skillName]) roleScores.managers[skillName] = [];
                roleScores.managers[skillName].push(rating);
            } else if (role === 'coworker') {
                if (!roleScores.coworkers[skillName]) roleScores.coworkers[skillName] = [];
                roleScores.coworkers[skillName].push(rating);
            }
        });

        const computeAverageScores = (scoresObj) => {
            return Object.keys(scoresObj).map((skill) => {
                const ratings = scoresObj[skill];
                const sum = ratings.reduce((acc, score) => acc + score, 0);
                return ratings.length ? sum / ratings.length : 0;
            });
        };

        return {
            labels: Array.from(labelsSet),
            scores: {
                overall: computeAverageScores(roleScores.overall),
                subordinates: computeAverageScores(roleScores.subordinates),
                managers: computeAverageScores(roleScores.managers),
                coworkers: computeAverageScores(roleScores.coworkers)
            }
        };
    };

// Function to extract unique traits from the questionResults data
const extractUniqueTraits = (questionResults) => {
    const traitsMap = new Map();

    questionResults.forEach((item) => {
        // Collect each trait and its properties (assuming `trait1_name`, etc., are available)
        if (item.trait1) {
            traitsMap.set(item.trait1, {
                id: item.trait1,
                name: item.trait1_name,
                description: item.trait1_description,
            });
        }
        if (item.trait2) {
            traitsMap.set(item.trait2, {
                id: item.trait2,
                name: item.trait2_name,
                description: item.trait2_description,
            });
        }
        if (item.trait3) {
            traitsMap.set(item.trait3, {
                id: item.trait3,
                name: item.trait3_name,
                description: item.trait3_description,
            });
        }
    });

    // Convert the Map to an array for easy access
    const uniqueTraits = Array.from(traitsMap.values());
    console.log("Unique Traits with Details:", uniqueTraits); // Check the extracted traits with details
    return uniqueTraits;
};


// 2. Call extractUniqueTraits with questionResults data
const uniqueTraitys = extractUniqueTraits(pageQuestions);


const transformPsychometricData = (pageQuestions, uniqueTraits) => {
    const roleScores = {
        overall: {},
        subordinates: {},
        managers: {},
        coworkers: {},
        unknown: {} // For undefined roles
    };

    pageQuestions.forEach((item) => {
        const traits = [item.trait1, item.trait2, item.trait3].filter(trait => trait);
        const rating = parseInt(item.rating, 10) || 0;
        const role = (item.role || 'unknown').toLowerCase(); // Set default role to 'unknown'

        // Calculate the score for each trait
        traits.forEach(trait => {
            if (!roleScores.overall[trait]) roleScores.overall[trait] = [];
            roleScores.overall[trait].push(rating);

            // Initialize arrays for each specific role if missing, including 'unknown'
            if (!roleScores[role][trait]) roleScores[role][trait] = [];
            roleScores[role][trait].push(rating);
        });
    });

    const computeAverageScores = (scoresObj) => {
        const averageScores = {};
        Object.keys(scoresObj).forEach(trait => {
            const scores = scoresObj[trait];
            const sum = scores.reduce((acc, score) => acc + score, 0);
            averageScores[trait] = scores.length ? sum / scores.length : 0;
        });
        return averageScores;
    };

    const labels = uniqueTraits.map(trait => trait.name);
    
    // Calculate the average scores for each trait and role
    const scores = {
        overall: labels.map(label => computeAverageScores(roleScores.overall)[label] || 0),
        subordinates: labels.map(label => computeAverageScores(roleScores.subordinates)[label] || 0),
        managers: labels.map(label => computeAverageScores(roleScores.managers)[label] || 0),
        coworkers: labels.map(label => computeAverageScores(roleScores.coworkers)[label] || 0),
        unknown: labels.map(label => computeAverageScores(roleScores.unknown)[label] || 0), // Include 'unknown' role scores
    };

    return { labels, scores };
};


    
    
    

    const createTable = (data) => {
        if (!data || !data.labels || !data.scores) {
            return <div>No Data Available</div>;
        }

        return (
            <table className="w-full border-collapse mt-5">
                <thead>
                    <tr>
                        <th className="border p-2 bg-gray-100">Attribute</th>
                        <th className="border p-2 bg-gray-100">Overall</th>
                        <th className="border p-2 bg-gray-100">Subordinates</th>
                        <th className="border p-2 bg-gray-100">Managers</th>
                        <th className="border p-2 bg-gray-100">Co-workers</th>
                    </tr>
                </thead>
                <tbody>
                    {data.labels.map((label, index) => (
                        <tr key={label}>
                            <td className="border p-2">{label}</td>
                            <td className="border p-2">{data.scores.overall[index]}</td>
                            <td className="border p-2">{data.scores.subordinates[index]}</td>
                            <td className="border p-2">{data.scores.managers[index]}</td>
                            <td className="border p-2">{data.scores.coworkers[index]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    // Sample data for chart rendering (not modified)
    const psychometricData = {
        labels: ['Openness', 'Conscientiousness', 'Extraversion', 'Agreeableness', 'Neuroticism'],
        scores: {
            overall: [85, 92, 78, 88, 70],
            subordinates: [82, 90, 75, 89, 72],
            managers: [87, 94, 80, 86, 68],
            coworkers: [86, 92, 79, 89, 70]
        }
    };

    const skillData = {
        labels: ['Communication', 'Problem Solving', 'Teamwork', 'Technical Skills', 'Leadership'],
        scores: {
            overall: [90, 85, 95, 80, 75],
            subordinates: [92, 83, 96, 78, 73],
            managers: [88, 87, 94, 82, 77],
            coworkers: [90, 85, 95, 80, 75]
        }
    };

    const chartOptions = {
        scales: {
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 5
            }
        },
        maintainAspectRatio: false
    };

    const createChartData = (data, label) => ({
        labels: data.labels,
        datasets: [{
            label: label,
            data: data.scores.overall,
            fill: true,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: 'rgb(54, 162, 235)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgb(54, 162, 235)'
        }]
    });

    const overallScore = Math.round(
        [...psychometricData.scores.overall, ...skillData.scores.overall].reduce((a, b) => a + b) /
        (psychometricData.scores.overall.length + skillData.scores.overall.length)
    );

    const tabs = [
        { name: 'Results', href: '#', current: currentTab === 'Results' },
        { name: 'Information', href: '#', current: currentTab === 'Information' },
        { name: 'References', href: '#', current: currentTab === 'References' },
    ];
    const uniqueTraits = extractUniqueTraits(questionResults);
    if(pageLoading) {
        return <LoadingScreen />
    }
    
    return (
        <div className="max-w-4xl mx-auto p-5">
            <h1 className="text-3xl font-bold text-center mb-6">Peer Assessment Results</h1>
            <H1>Question Results</H1>


            {assessmentData && <CardSimple
                contentData={<div>
                    <H2 className="text-2xl font-semibold ">{candidateData.first_name + ' ' + candidateData.last_name}</H2>
                    <h4 className="text-gray-400 text-xl font-semibold mb-4 ">{candidateData.email}</h4>

                    <Tabs tabs={tabs} onTabChange={setCurrentTab} />

                    {currentTab === 'Results'
                        && <>
                            <HeatmapTable transformedData={transformedQuestionResults} />
                            <div className="p-6 mb-6">
                                <h2 className="text-2xl font-semibold mb-4">Psychometric Assessment</h2>
                                <div className="h-[300px] max-w-[500px] mx-auto mb-6">
                                    <Radar data={createChartData(transformedQuestionResults, 'Psychometric Assessment')} options={chartOptions} />
                                </div>
                                {createTable(transformedQuestionResults)}
                            </div>
                            <div className="p-6">
                                <h2 className="text-2xl font-semibold mb-4">Skill Requirements</h2>
                                <div className="h-[300px] max-w-[500px] mx-auto mb-6">
                                    <Radar data={createChartData(transformedRequirementResults, 'Skill Requirements')} options={chartOptions} />
                                </div>
                                {createTable(transformedRequirementResults)}
                            </div>
                        </>}

                    {currentTab === 'References'
                        && <>
                            <ul className="divide-y divide-gray-200 gap-4">
                                {referenceData.map((reference) => (
                                    <li key={reference.id} className="flex items-center justify-between gap-x-6 py-2">
                                        <div className="min-w-0">
                                            <div className="flex items-start gap-x-3">
                                                <p className="text-sm font-semibold leading-6 text-gray-900">
                                                    {reference.first_name} {reference.last_name}
                                                </p>
                                                <p>
                                                    {reference.company_name}
                                                </p>
                                            </div>
                                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                                <p className="truncate">Relationship: {candidateData.first_name}'s {reference.role}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-none items-center gap-x-4">
                                            <p
                                                className={'mt-0.5 whitespace-nowrap rounded-md px-3 py-1.5 text-base font-medium'}
                                            >
                                                {reference.status}
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </>}

                    {currentTab === 'Information'
                        && <>
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Job Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Job and assessment data.</p>
                            </div>
                            <div className="mt-4">
                                <dl className="grid grid-cols-1 sm:grid-cols-2">
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Job Title</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.job_name}</dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Company and Location</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.company_name} | {candidateData.location}</dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Assessment</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{candidateData.assessment_name} </dd>
                                    </div>

                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Job Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                            {candidateData.description}
                                        </dd>
                                    </div>
                                    <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Assessment Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                            {assessmentData && assessmentData[0] && assessmentData[0].description}
                                        </dd>
                                    </div>
                                    {/* {JSON.stringify(referenceData)} */}
                                </dl>
                            </div>
                        </>}

                </div>}
            />}
        </div>
    );
};

export default PeerAssessmentResults;


