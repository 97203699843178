// src/components/Button.js

import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/ClassNames';

// Base Button Component
const Button = ({ variant, size, className, children, disabled, onClick, ...props }) => {
    const baseStyles = 'font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline';
    const variantStyles = {
      primary: 'rounded bg-accent0-500 px-2 py-1 font-semibold text-primary1-50 shadow-sm ring-1 ring-inset ring-accent0-500 hover:bg-accent0-700 disabled:bg-accent0-300 disabled:ring-accent0-300',
      secondary: 'rounded bg-transparent px-2 py-1 font-semibold text-accent0-500 shadow-sm ring-1 ring-inset ring-accent0-500 hover:bg-accent0-100 disabled:text-accent0-300 disabled:ring-accent0-300',
      tertiary: 'rounded bg-primary1-200 px-2 py-1 font-semibold text-primary1-800 shadow-sm ring-1 ring-inset ring-primary1-200 hover:bg-primary1-300 disabled:text-accent0-400 disabled:ring-accent0-300',
      caution: 'rounded px-2 py-1 font-semibold text-red-700 shadow-sm ring-1 ring-inset ring-red-700 hover:bg-red-50',
    };
    const sizeStyles = {
      small: 'text-sm',
      medium: 'text-base',
      large: 'text-lg',
    };
  
    return (
      <button
        className={classNames(baseStyles, variantStyles[variant], sizeStyles[size], className, { 'opacity-50 cursor-not-allowed': disabled })}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  };
  
  Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'highlight']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };
  
  Button.defaultProps = {
    variant: 'primary',
    size: 'medium',
    className: '',
    disabled: false,
    onClick: null,
  };
  
  export default Button;