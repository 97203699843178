import { ArrowDownCircleIcon } from "@heroicons/react/16/solid";
import { P } from "../../../components/Typography/Typography";
import './../../../assets/styles/app.css';


<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
</svg>

function Icons() {
    return <div>
        <P>For icons, we are using Heroicons 2, Solid, 16.</P>
        <ArrowDownCircleIcon className="w-10 m-10"/>
        <ArrowDownCircleIcon className="text-accent0-600 w-10 m-10"/>
    </div>
}

export default Icons;