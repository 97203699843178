import useExperimentStore from "../../../store/experimentStore";
import useUserStore from "../../../store/userStore";
import AssessmentDetails from "../AssessmentDetails/AssessmentDetails";
import Invitations from "../Invitations/Invitations";



function DashboardCandidate() {
    const {
        sentInvitations, userReferences, addNewSentInvitation, receivedInvitations, recruiterInvitations, workHistory
    } = useExperimentStore(state => state);
    const {
        user 
    } = useUserStore(state => state);

    const stats = [
        { "name": "Received Invitations", "stat": receivedInvitations.length + recruiterInvitations.length },
        { "name": "References", "stat": userReferences.length },
        { "name": "Work Entries", "stat": workHistory.length },
    ]

    return <div>
        <div>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                {stats.map((item) => (
                    <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                        <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                    </div>
                ))}
            </dl>
        </div>
        <Invitations />
    </div>
}

export default DashboardCandidate;