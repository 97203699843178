import { create } from 'zustand';

const useRecruiterStore = create((set, get) => ({
  // Stores
  user: null,
  assessments: [],
  assessmentSkills: [],
  jobs: [],
  jobCandidates: [],

  editingAssessmentSkill: null,

  // User interaction
  setUser: (userData) => set({ user: userData }),

  // Set assessments (e.g., from an API response)
  addAssessment: (newAssessments) => set((state) => ({
    assessments: [
      ...state.assessments,
      ...newAssessments.filter(
        newAssessment => !state.assessments.some(
          assessment => assessment.id === newAssessment.id
        )
      )
    ]
  })),

  // Add a single new assessment
  addNewAssessment: (newAssessment) => set((state) => ({
    assessments: [...state.assessments, newAssessment]
  })),

  editAssessment: (editedAssessment) => set((state) => ({
    assessments: state.assessments.map(assessment =>
      assessment.id === editedAssessment.id ? editedAssessment : assessment
    )
  })),

  canDeleteAssessment: (assessmentId) => {
    const { assessmentSkills, jobs } = get();
    const hasRelatedSkills = assessmentSkills.some(skill => skill.assessment_id === assessmentId);
    const isAssessmentUsedInJobs = jobs.some(job => job.assessment_id === assessmentId);
    return !(hasRelatedSkills || isAssessmentUsedInJobs);
  },

  removeAssessment: (assessmentId) => set((state) => ({
    assessments: state.assessments.filter(assessment => assessment.id !== assessmentId)
  })),

  // Assessment Skills interactions
  addAssessmentSkill: (newSkills) => set((state) => ({
    assessmentSkills: [
      ...state.assessmentSkills,
      ...newSkills.filter(
        newSkill => !state.assessmentSkills.some(
          assessment => assessment.id === newSkill.id
        )
      )
    ]
  })),

  // Add a single new assessment skill
  addNewAssessmentSkill: (newAssessmentSkill) => set((state) => ({
    assessmentSkills: [...state.assessmentSkills, newAssessmentSkill]
  })),

  editAssessmentSkill: (editedAssessmentSkill) => set((state) => ({
    assessmentSkills: state.assessmentSkills.map(skill =>
      skill.id === editedAssessmentSkill.id ? editedAssessmentSkill : skill
    )
  })),

  canDeleteAssessmentSkill: (assessmentId) => {
    const { jobs } = get();
    const isAssessmentUsedInJobs = jobs.some(job => job.assessment_id === assessmentId);
    return !(isAssessmentUsedInJobs);
  },

  countAssessmentSkills: (assessmentId) => {
    const { assessmentSkills } = get();
    const assessmentCount = assessmentSkills.filter(skill => skill.assessment_id === assessmentId).length;
    return assessmentCount;
  },

  countAssessmentJobs: (assessmentId) => {
    const { jobs } = get();
    const assessmentCount = jobs.filter(job => job.assessment_id === assessmentId).length;
    return assessmentCount;
  },

  removeAssessmentSkill: (skillId) => set((state) => ({
    assessmentSkills: state.assessmentSkills.filter(skill => skill.id !== skillId)
  })),

  // Jobs interactions
  addJob: (newJobs) => set((state) => ({
    jobs: [
      ...state.jobs,
      ...newJobs.filter(
        newJob => !state.jobs.some(
          job => job.id === newJob.id
        )
      )
    ]
  })),
  addNewJob: (newJob) => set((state) => ({
    jobs: [...state.jobs, newJob]
  })),
  editJob: (editedJob) => set((state) => ({
    jobs: state.jobs.map(job =>
      job.id === editedJob.id ? editedJob : job
    )
  })),

  canDeleteJob: (jobId) => {
    const { jobCandidates } = get();
    // Filter jobCandidates based on jobId)
    const filteredCandidates = jobCandidates.filter(candidate => candidate.job_id === jobId);
    // Check if any filtered candidates have 'in-progress' or 'pending' status
    const isJobUsed = filteredCandidates.some(candidate => candidate.status === 'in-progress' || candidate.status === 'pending');
    return !isJobUsed;
  },

  

  removeJob: (jobId) => set((state) => ({
    jobs: state.jobs.filter(job => job.id !== jobId)
  })),

  addAssessmentIdToJob: (editedJob) => set((state) => ({
    jobs: state.jobs.map(job => (
      job.id === editedJob.id ? editedJob : job
    ))
  })),


  // Job Candidates interactions
  addJobCandidate: (newJobCandidates) => set((state) => ({
    jobCandidates: [
      ...state.jobCandidates,
      ...newJobCandidates.filter(
        newJobCandidate => !state.jobCandidates.some(
          jobCandidate => jobCandidate.id === newJobCandidate.id
        )
      )
    ]
  })),
  addNewJobCandidate: (newJobCandidate) => set((state) => ({
    jobCandidates: [...state.jobCandidates, newJobCandidate]
  })),


  editJobCandidate: (editedJobCandidates) => set((state) => ({
    jobCandidates: state.jobCandidates.map(candidate =>
      candidate.id === editedJobCandidates.id ? editedJobCandidates : candidate
    )
  })),

  deleteJobCandidate: (candidateId) => set((state) => ({
    jobCandidates: state.jobCandidates.filter(candidate => candidate.id !== candidateId)
  })),

  findItemById: (id, arrayName) => {
    const state = useRecruiterStore.getState();
    return state[arrayName]?.find(item => item.id === id) || null;
  },

  findAssessmentSkillsByAssessmentId: (assessment_id) => {
    const state = useRecruiterStore.getState();
    return state.assessmentSkills.filter(item => item.assessment_id === assessment_id);
  },

  findAssessmentSkillsById: (id) => {
    const state = useRecruiterStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.assessmentSkills.filter(item => item.id === id); 
  },

  findAssessmentById: (id) => {
    const state = useRecruiterStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.assessments.filter(item => item.id === id);
  },


  findJobsWithAssessmentId: ({id}) => {
    const { jobs } = get();
    const isAssessmentUsedInJobs = jobs.filter(job => job.assessment_id === id);
    //const x= state.jobs.filter(item => item.assessment_id === assessment_id);
    return isAssessmentUsedInJobs;
  },
  

  setEditingAssessmentSkill: (skill) => set({ editingAssessmentSkill: skill }),

  getJobById: ({ id }) => {
    const { jobs } = get();
    return jobs.find(job => job.id === id);
  },

  getCandidateByJobAndCandidateId: ({ jobId, candidateId }) => {
    const { jobCandidates } = get();
    return jobCandidates.find(candidate => candidate.job_id === jobId && candidate.candidate_id === candidateId);
  },

  clearRecruiter: () => set({ user: null, assessments: [], assessmentSkills: [], jobs: [], jobCandidates: []})
}));

export default useRecruiterStore;
