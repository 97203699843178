import apiCall from "../utils/apiCall";

export const getCandidateSkills = async(id) => {
    try {
        const response = await apiCall('get',`/candidateSkills/user/${id}/skills`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch candidate skills:', error);
        throw error;
    }
}

export const createCandidateSkills = async(data) => {
    try {
        const response = await apiCall('post',`/candidateSkills/create`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to add candidate skill:', error);
        throw error;
    }
}

export const deleteCandidateSkill = async(data) => {
    try {
        const response = await apiCall('delete',`/candidateSkills/delete`,data);
        return response;
    } catch (error) {
        console.error('Failed to delete candidate skill:', error);
        throw error;
    }
}

// Work History
export const getWorkHistory = async(id) => {
    try {
        const response = await apiCall('get',`/workHistory/candidate/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch candidate work history:', error);
        throw error;
    }
}

export const createWorkHistory = async(data) => {
    try {
        const response = await apiCall('post',`/workHistory/create`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to add work history:', error);
        throw error;
    }
}

export const updateWorkHistory = async(data) => {
    try {
        const response = await apiCall('put',`/workHistory/update`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to update work history:', error);
        throw error;
    }
}

export const deleteWorkHistory = async(data) => {
    try {
        const response = await apiCall('delete',`/workHistory/delete`,data);
        return response;
    } catch (error) {
        console.error('Failed to delete work history:', error);
        throw error;
    }
}

// References
export const getCandidateReferences = async(id) => {
    try {
        const response = await apiCall('get', `/references/candidate/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch references:', error);
        throw error;  
    }
}


export const createReference = async(data) => {
    try {
        const response = await apiCall('post', `/references/create`,data);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Failed to create references:', error);
        throw error;  
    }
}

export const updateReference = async(data) => {
    try {
        const response = await apiCall('put', `/references/update`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to update references:', error);
        throw error;  
    }
}

export const deleteReference = async(id) => {
    console.log(id.id)
    try {
        const response = await apiCall('delete', `/references/delete/${id.id}`);
        return response;
    } catch (error) {
        console.error('Failed to delete references:', error);
        throw error;  
    }
}

// Assessment Invitations
export const fetchAssessmentInvitations = async(id) => {
    try {
        const response = await apiCall('get', `/candidateAssessments/candidate/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessment invitations:', error);
        throw error;  
    }
}

// Reference Invitations Sent
export const fetchReferenceInvitationsSent = async(id) => {
    try {
        const response = await apiCall('get', `/referenceInvitations/candidate/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessment invitations:', error);
        throw error;  
    }
}

// Received Invitations
export const fetchReceivedReferenceInvitations = async(id) => {
    try {
        const response = await apiCall('get', `/referenceInvitations/receivedInvitations`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessment invitations:', error);
        throw error;  
    }
}

// Send Invitation to Referencde
export const createReferenceInvitation = async(data) => {
    try {
        const response = await apiCall('post', `/referenceInvitations/invite`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to invite reference user:', error);
        throw error;  
    }
}


// Send Assessments
export const getAssessmentPageData = async(data) => {
    try {
        const response = await apiCall('post', `/referenceInvitations/candidateAssessmentData`,data);
        return response;
    } catch (error) {
        console.error('Failed to fetch Assessment page data:', error);
        throw error;  
    }
}

export const sendAssessments = async(data) => {
    try {
        const response = await apiCall('post', `/skillassessments/test`,data);
        return response;
    } catch (error) {
        console.error('Failed to submit skill assessments:', error);
        throw error;  
    }
}