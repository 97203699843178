import {create} from 'zustand';

const useAdminStore = create((set,get) => ({
  categories: [],
  appSkills: [],
  notification: null,
  notificationStatus: null,
  addCategories: (newCategory) => set((state) => {
    if (!state.categories.some(category => category.id === newCategory.id)) {
      return { categories: [...state.categories, newCategory] };
    }
    return state;
  }),
  addSkills: (newSkills) => set((state) => ({
    appSkills: [
      ...state.appSkills,
      ...newSkills.filter(
        newSkill => !state.appSkills.some(
          skill => skill.id === newSkill.id
        )
      )
    ]
  })),
  setNotification: (newNotification) => set((state) => ({
    notification: newNotification
  })),
  setNotificationStatus: (newNotificationStatus) => set((state) => ({
    notificationStatus: newNotificationStatus
  })),
  clearNotification: () => set({
    notification: null,
    notificationStatus: null
  }),
  setNotificationWithTimeout: (newNotification, newNotificationStatus, timeout) => {
    set({ notification: newNotification, notificationStatus: newNotificationStatus });
    
    setTimeout(() => {
      get().clearNotification();
    }, timeout);
  },
  findSkillById: ({id}) => {
    const { appSkills } = get();
    console.log(id)
    const result = appSkills.filter(skill => skill.id === id);
    //const x= state.jobs.filter(item => item.assessment_id === assessment_id);
    return result;
  },
}));

export default useAdminStore;
