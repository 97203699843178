import { useState } from "react";

import {CardWithFooter} from "../../../components/Cards/CardWithFooter";
import { SkeletonCard, SkeletonText } from "../../../components/Skeleton/Skeleton";


import { H3, P, Small } from "../../../components/Typography/Typography";
import Button from "../../../components/Buttons/Buttons";

function DataDisplay() {
    const [loading, setLoading] = useState(false);
    const showEffect = () => {
        // Simulate a loading process
        setLoading(true)
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000); // Adjust this to your loading duration
        //setLoading(true);
        return () => clearTimeout(timer);
    };

    return <div>
        <H3 className={"py-2"}>Loading</H3>
        <P>When loading data, a Skeleton component is being used until data is loaded.</P>
        <SkeletonCard />
        <H3 className={"py-2"}>Data fetched</H3>
        <P>When loading data, a Skeleton component is being used until data is loaded.</P>
        <H3 className={"py-2"}>Data</H3>
        <CardWithFooter
            contentData={<H3 className={"text-accent-400"}>+34% YoY<Small>based on Stats</Small></H3>}
            footerContent={"Monthly active users"}
        />
        <H3 className={"py-2"}>Example</H3>
        {loading ? "Loading" : "Done"}
        <Button onClick={() => showEffect()}>Start Example</Button>
        {
            loading
                ? <SkeletonCard />
                : <CardWithFooter
                    contentData={<H3 className={"text-accent-400"}>+34% YoY<Small>based on Stats</Small></H3>}
                    footerContent={"Monthly active users"}
                />
        }
        <P  className={"py-2"}>

        {loading ? "Loading" : "Done"}
        <Button onClick={() => showEffect()}>Start Example</Button>            
        </P>

        <CardWithFooter
            contentData={<H3 className={"text-accent-400"}>{loading ? <SkeletonText lines={1} /> : (
                <>
                  +34% YoY <Small>based on Stats</Small>
                </>
              )}</H3>}
            footerContent={"Monthly active users"}
        />
    </div>
}

export default DataDisplay;