
import './assets/styles/app.css';
import { useEffect, useState } from "react";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Public/Homepage/Homepage";
import Login from "./pages/Public/Login/Login";
import DashboardRecruiter from "./pages/Recruiter/Dashboard/RecruiterDashboard";
import DashboardCandidate from "./pages/Candidate/Dashboard/CandidateDashboard";
import { candidateProtectedRoute, recruiterProtectedRoute } from "./utils/protectedRoutes";
import DesignSystem from './pages/Public/DesignSystem/DesignSystem';
import Notification from "./components/Notification/Notification";

// Stores
import useGlobalStore from './store/globalStore';
import Footer from './components/Footer/Footer';
import { ProtectedRoute } from './utils/protectedRoutes';
import DashboardAdmin from './pages/Admin/Dashboard/AdminDashboard';
import CandidateLayout from './layouts/candidateLayout';
import AdminLayout from './layouts/adminLayout';
import PublicLayout from './layouts/publicLayout';
import RecruiterLayout from './layouts/recruiterLayout';
import NotFound from './pages/Public/404';
import AssessmentCandidate from './pages/Candidate/AssessmentDetails/AssessmentDetails';
import AssessmentPage from './pages/Recruiter/Assessments/AssessmentDetails';
import Assessments from './pages/Recruiter/Assessments/Assessments';
import JobView from './pages/Recruiter/Jobs/JobView';
import Jobs from './pages/Recruiter/Jobs/Jobs';
import JobPage from './pages/Recruiter/Jobs/JobView';
import JobPageView from './pages/Recruiter/Jobs/JobPage'
import { checkAndValidateToken } from './services/authService';
import useUserStore from './store/userStore';
import { PublicRoute } from './utils/publicRoute';
import Invitations from './pages/Candidate/Invitations/Invitations';
import Profile from './pages/Candidate/CandidateSettings/Profile';
import AssessmentTest from './pages/Candidate/AssessmentTest/AssessmentTest';
import AssessmentDetails from './pages/Candidate/AssessmentDetails/AssessmentDetails';
import Work from './pages/Candidate/Work/Work';
import CandidateReferences from './pages/Candidate/CandidateReferences/References';
import Pricing from './pages/Public/Homepage/Homepage_Pricing';
import PeerAssessmentResults from './pages/Recruiter/Jobs/CA_Questions';
import PsychometricQuestionManager from './pages/Admin/Psychometrics/PsychometricQuestions';
import ActivityLog from './pages/Admin/ActivityLog/ActivityLog';
import JobList from './pages/Admin/Jobs/Jobs';
import QuestionsPanel from './pages/Admin/Psychometrics/PQ';
import UserList from './pages/Admin/Users/UserList';
import CandidateAssessment from './pages/Recruiter/Jobs/CandidateAssessment';


function AribaApp() {

    //const globalStore = useGlobalStore.getState();
    const { notification, notificationStatus, notificationText, setNotification, loading, setLoading } = useGlobalStore((state) => state);
    const {
        setUser
    } = useUserStore(state => state);
    const handleCloseNotification = () => {
        //setNotification(null); // Close the notification when the button is clicked
    };
    //globalStore.setLoading(false);
    //globalStore.setNotificationWithTimeout("test","success",3000)
    //const [loading, setLoading] = useState(true);
    useEffect(() => {
        const validateToken = async () => {
          const response = await checkAndValidateToken();
            console.log(response)
          if (response.success) {
            setUser(response.user);
            //setNotificationWithTimeout('User authenticated successfully.', 'success', 3000);
          } else {
            //setNotificationWithTimeout(response.message, 'error', 3000);
          }
        };
    
        validateToken();
      }, []);
    // useEffect(() => {
        // Simulate a loading process
        // const timer = setTimeout(() => {
        //     console.log("timer")
        //     setLoading(false);
        // }, 3000); // Adjust this to your loading duration
        // console.log("ooop")
        // console.log(loading)
        // return () => clearTimeout(timer);
    // }, []);

    if (loading) {
        return <>45<LoadingScreen /></>;
    }

    return (
        <>
            {notification && (
                <Notification
                    show={!!notificationText} // Use `notificationText` to determine if the notification should be shown
                    message={notificationText} // Use `notificationText` as the actual message
                    type={notificationStatus} // Use `notificationStatus` for the type
                    onClose={handleCloseNotification} // Pass the function reference correctly
                />
            )}

            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/pricing" element={<PublicRoute><PublicLayout><Pricing /></PublicLayout></PublicRoute>} />
                <Route path="/about" element={<PublicRoute><PublicLayout><Pricing /></PublicLayout></PublicRoute>} />
                <Route path="/pricing" element={<PublicRoute><PublicLayout><Pricing /></PublicLayout></PublicRoute>} />
                <Route path="/terms" element={<PublicRoute><PublicLayout><Pricing /></PublicLayout></PublicRoute>} />
                <Route path="/privacy" element={<PublicRoute><PublicLayout><Pricing /></PublicLayout></PublicRoute>} />
                <Route path="/design" element={<PublicRoute><PublicLayout><DesignSystem /></PublicLayout></PublicRoute>} />
                <Route path="/login" element={<PublicRoute><PublicLayout><Login /></PublicLayout></PublicRoute>} />
                <Route path="/candidate">
                    <Route path="dashboard" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><DashboardCandidate /></CandidateLayout></ProtectedRoute>} />
                    <Route path="assessments/:assessmentId/:jobCandidateId/:referenceId" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><AssessmentTest /></CandidateLayout></ProtectedRoute>} />                    
                    <Route path="assessments/:id" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><AssessmentDetails /></CandidateLayout></ProtectedRoute>} />                    
                    <Route path="invitations" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><Invitations /></CandidateLayout></ProtectedRoute>} />                    
                    <Route path="profile" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><Profile /></CandidateLayout></ProtectedRoute>} />                                    
                    <Route path="references" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><CandidateReferences /></CandidateLayout></ProtectedRoute>} />                                    
                    <Route path="work" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><Work /></CandidateLayout></ProtectedRoute>} />                                    
                </Route>
                <Route path="/recruiter">
                    <Route path="assessments/:id" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><AssessmentPage /></RecruiterLayout></ProtectedRoute>} />
                    <Route path="dashboard" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><DashboardRecruiter data="x" /></RecruiterLayout></ProtectedRoute> } />
                    <Route path="assessments" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><Assessments data="x" /></RecruiterLayout></ProtectedRoute>} />
                    <Route path="jobs/:id" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><JobView /></RecruiterLayout></ProtectedRoute>} />
                    <Route path="jobs" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><Jobs data="x" /></RecruiterLayout></ProtectedRoute>} />
                    <Route path="results/:assessmentId/:jobCandidateId" element={<ProtectedRoute allowedRoles={['recruiter']}><RecruiterLayout><CandidateAssessment /></RecruiterLayout></ProtectedRoute>} />
                </Route>
                <Route path="/admin">
                    <Route path="dashboard" element={<ProtectedRoute allowedRoles={['admin']}><AdminLayout><DashboardAdmin /></AdminLayout></ProtectedRoute>} />
                    <Route path="users" element={<ProtectedRoute allowedRoles={['admin']}><AdminLayout><UserList /></AdminLayout></ProtectedRoute>} />
                    <Route path="questions" element={<ProtectedRoute allowedRoles={['admin']}><AdminLayout><QuestionsPanel /></AdminLayout></ProtectedRoute>} />
                    <Route path="activities" element={<ProtectedRoute allowedRoles={['admin']}><AdminLayout><ActivityLog /></AdminLayout></ProtectedRoute>} />
                    <Route path="jobs" element={<ProtectedRoute allowedRoles={['admin']}><AdminLayout><JobList /></AdminLayout></ProtectedRoute>} />

                </Route>             
                <Route path="*"
                    element={
                        <PublicLayout>
                            <NotFound />
                        </PublicLayout>
                    }
                />
            </Routes>
        </>

    );
}

export default AribaApp;