// Skeleton.js
import React from 'react';
import PropTypes from 'prop-types';

const SkeletonAvatar = () => {
    return (
      <div className="animate-pulse">
        <div className="bg-gray-300 rounded-full h-20 w-20 mb-4"></div>
      </div>
    );
  };

  const SkeletonButton = () => {
    return (
      <div className="animate-pulse">
        <div className="bg-gray-300 h-10 w-32 rounded"></div>
      </div>
    );
  };

const SkeletonCard = () => {
    return (
      <div className="animate-pulse">
        <div className="bg-gray-300 h-40 w-full mb-4"></div>
        <div className="bg-gray-300 h-6 w-3/4 mb-2"></div>
        <div className="bg-gray-300 h-6 w-1/2 mb-2"></div>
      </div>
    );
  };

  const SkeletonText = ({ lines }) => {
    const skeletonLines = Array(lines).fill(null).map((_, index) => (
      <div key={index} className={`bg-gray-300 h-6 ${index !== lines - 1 ? 'mb-4' : ''}`}></div>
    ));
  
    return <div className="animate-pulse">{skeletonLines}</div>;
  };
  
  SkeletonText.propTypes = {
    lines: PropTypes.number,
  };
  
  SkeletonText.defaultProps = {
    lines: 3,
  };

export {SkeletonAvatar, SkeletonButton, SkeletonCard, SkeletonText}
