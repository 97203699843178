// src/components/Link.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from '../../utils/ClassNames';

const LinkButton = ({ variant, size, to, external, className, children, ...props }) => {
  const isHashLink = to.startsWith('#');
  const baseStyles = 'font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline';
  const variantStyles = {
    primary: 'rounded bg-accent0-500 px-2 py-1 font-semibold text-primary1-50 shadow-sm ring-1 ring-inset ring-accent0-500 hover:bg-accent0-700',
    secondary: 'rounded bg-transparent px-2 py-1 font-semibold text-accent0-500 shadow-sm ring-1 ring-inset ring-accent0-500 hover:bg-accent0-100',
    tertiary: 'rounded bg-primary1-200 px-2 py-1 font-semibold text-primary1-800 shadow-sm ring-1 ring-inset ring-primary1-200 hover:bg-primary1-300',
    caution: 'rounded px-2 py-1 font-semibold text-red-700 shadow-sm ring-1 ring-inset ring-red-700 hover:bg-red-50',
  };
  const sizeStyles = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-lg',
  };

  if (external) {
    return (
      <a href={to} className={classNames(baseStyles, variantStyles[variant], sizeStyles[size],'', className)} {...props}>
        {children}
      </a>
    );
  }

  if (isHashLink) {
    return (
      <a href={to} className={classNames(baseStyles, variantStyles[variant], sizeStyles[size],'', className)} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={to} className={classNames(baseStyles, variantStyles[variant], sizeStyles[size],'', className)} {...props}>
      {children}
    </RouterLink>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  external: false,
  className: '',
};

export default LinkButton;

