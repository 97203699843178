import { CardSimple } from "../../../components/Cards/CardWithFooter";
import { H1 } from "../../../components/Typography/Typography";
import Work from "../Work/Work";

function CandidateSettings() {
    return <div>
        <CardSimple
            contentData={<>
                <H1>Profile</H1>
                <Work/>
            </>}
        />
    </div>
}

export default CandidateSettings;