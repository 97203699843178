import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import useAdminStore from "../../../store/adminStore";

// Sample data (replace with API fetch)
const sampleData = [
  {
"id": "1615acac-c0f9-45ca-9d9b-f1380591a7d0",
            "question_third_person": "The candidate enjoys collaborating with others to achieve common goals.",
            "created_at": "2024-09-27T09:28:11.997Z",
            "updated_at": "2024-09-27T09:28:11.997Z",
            "trait1": "1007165608548564993",
            "trait2": "1007165608548761601",
            "trait3": null,
            "question_first_person": "I enjoy collaborating with others to achieve common goals.",
            "trait1_name": "Teamwork",
            "trait1_description": "Capacity for collaboration, communication, and building positive relationships with colleagues.",
            "trait2_name": "Communication",
            "trait2_description": "Clarity, openness, and effectiveness in sharing information, listening, and responding within a work environment.",
            "trait3_name": null,
            "trait3_description": null
  },
  // Additional questions...
];

const sampleTraits = [
  { id: "1007165608548564993", name: "Teamwork" },
  { id: "1007165608548761601", name: "Communication" },
  { id: "1007165608548630529", name: "Decision-Making" },
  // More traits...
];

function PsychometricQuestionManager() {
  const [questions, setQuestions] = useState([]);
  const [traits, setTraits] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const { psychQuestions } = useAdminStore((state) => state);

  useEffect(() => {
    setQuestions(sampleData); // Replace with API fetch
    setTraits(sampleTraits); // Replace with API fetch
  }, []);

  const openDrawer = (question = null) => {
    setSelectedQuestion(question);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedQuestion(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <header className="flex justify-between items-center mb-10">
        <h1 className="text-3xl font-bold text-gray-900">Psychometric Questions</h1>
        <button onClick={() => openDrawer()} className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 shadow transition">
          <FaPlus className="mr-2" /> Add Question
        </button>
      </header>

      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {psychQuestions.map((question) => (
          <QuestionCard key={question.id} question={question} onEdit={() => openDrawer(question)} />
        ))}
      </div>

      {isDrawerOpen && (
        <QuestionEditorDrawer question={selectedQuestion} onClose={closeDrawer} traits={traits} />
      )}
    </div>
  );
}

function QuestionCard({ question, onEdit }) {
  return (
    <div className="bg-white rounded-xl shadow-lg p-6 space-y-4 transition hover:shadow-xl relative">
      <div className="absolute top-3 right-3 text-gray-500 text-xs">
        Updated on {new Date(question.updated_at).toLocaleDateString()}
      </div>
      <h2 className="font-semibold text-gray-800">Question</h2>
      <p className="text-gray-700 text-sm">{question.question_third_person}</p>
      
      <div className="mt-6">
        <h3 className="font-semibold text-gray-800 mb-2">Traits</h3>
        <div className="flex space-x-2">
          {question.trait1_name && <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full">{question.trait1_name}</span>}
          {question.trait2_name && <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full">{question.trait2_name}</span>}
        </div>
      </div>

      <button onClick={onEdit} className="w-full mt-6 flex items-center justify-center px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition">
        <FaEdit className="mr-2" /> Edit
      </button>
    </div>
  );
}

function QuestionEditorDrawer({ question, onClose }) {
    // Local state for each form field
    const [questionFirstPerson, setQuestionFirstPerson] = useState("");
    const [questionThirdPerson, setQuestionThirdPerson] = useState("");
    const [selectedTraits, setSelectedTraits] = useState([]);
  
    const { traits } = useAdminStore((state) => state);

    // Populate fields when question data is provided (for editing)
    useEffect(() => {
      if (question) {
        setQuestionFirstPerson(question.question_first_person || "");
        setQuestionThirdPerson(question.question_third_person || "");
  
        // Set selected traits based on the question data, ensuring traits are pre-selected for editing
        const initialTraits = [
          question.trait1 || null,
          question.trait2 || null,
          question.trait3 || null,
        ].filter(Boolean); // Filter out any null values
        setSelectedTraits(initialTraits);
      } else {
        // Clear fields when creating a new question
        setQuestionFirstPerson("");
        setQuestionThirdPerson("");
        setSelectedTraits([]);
      }
    }, [question]);
  
    const handleTraitToggle = (traitId) => {
      setSelectedTraits((prevSelected) => {
        if (prevSelected.includes(traitId)) {
          return prevSelected.filter((id) => id !== traitId);
        } else if (prevSelected.length < 3) {
          return [...prevSelected, traitId];
        }
        return prevSelected; // No change if limit is reached
      });
    };
  
    const onSubmit = (event) => {
      event.preventDefault();
      const formData = {
        question_first_person: questionFirstPerson,
        question_third_person: questionThirdPerson,
        traits: selectedTraits,
      };
      console.log("Form Submitted:", formData);
      onClose();
    };
  
    return (
      <div className="fixed inset-y-0 right-0 bg-white shadow-lg p-8 w-full max-w-lg z-50 overflow-y-auto">
        <button onClick={onClose} className="text-gray-500 text-xl hover:text-gray-700 mb-6">×</button>
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">{question ? "Edit Question" : "Add New Question"}</h2>
        <form onSubmit={onSubmit} className="space-y-6">
          <div>
            <label className="block font-semibold text-gray-700 mb-1">First-Person Statement</label>
            <textarea
              value={questionFirstPerson}
              onChange={(e) => setQuestionFirstPerson(e.target.value)}
              placeholder="Enter candidate's perspective"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition"
              required
            />
          </div>
  
          <div>
            <label className="block font-semibold text-gray-700 mb-1">Third-Person Statement</label>
            <textarea
              value={questionThirdPerson}
              onChange={(e) => setQuestionThirdPerson(e.target.value)}
              placeholder="Enter third-person perspective"
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition"
              required
            />
          </div>
          
          <div>
            <label className="block font-semibold text-gray-700 mb-1">Traits (Select up to 3)</label>
            <div className="space-y-2">
              {traits.map((trait) => (
                <label key={trait.id} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    value={trait.id}
                    checked={selectedTraits.includes(trait.id)}
                    onChange={() => handleTraitToggle(trait.id)}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                  <span className="text-gray-700">{trait.trait_name}</span>
                </label>
              ))}
            </div>
          </div>
          
          <button type="submit" className="w-full py-3 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 transition">Save</button>
        </form>
      </div>
    );
  }

export default PsychometricQuestionManager;
