export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    const year = result.getFullYear();
    const month = String(result.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(result.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
