import React from 'react';

const Tabs = ({ tabs, onTabChange }) => {
  return (
    <div>
      {/* Small screen, horizontally scrollable tabs */}
      <div className="sm:hidden">
        <div className="overflow-x-auto">
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => onTabChange(tab.name)}
                className={`whitespace-nowrap px-3 py-2 text-sm font-medium ${
                  tab.current
                    ? 'border-b-2 border-brand-500 text-brand-600'
                    : 'border-b-2 border-transparent text-gray-500 hover:text-gray-700'
                }`}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      {/* Larger screens */}
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => onTabChange(tab.name)}
                className={`whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium ${
                  tab.current
                    ? 'border-brand-500 text-brand-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
