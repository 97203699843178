import { useState } from "react";
import UserDetail from "./UserDetail";
import useAdminStore from "../../../store/adminStore";
import UserEditForm from "./UserEditForm";

function UserList() {
    const { registeredUsers } = useAdminStore();
    const [filters, setFilters] = useState({ first_name: "", last_name: "", email: "", role: "" });
    const [sortConfig, setSortConfig] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Track edit mode
    const [visibleCount, setVisibleCount] = useState(20);
  
    const handleFilterChange = (e) => setFilters({ ...filters, [e.target.name]: e.target.value });
  
    const filteredUsers = registeredUsers
      .filter(
        (user) =>
          user.first_name.toLowerCase().includes(filters.first_name.toLowerCase()) &&
          user.last_name.toLowerCase().includes(filters.last_name.toLowerCase()) &&
          user.email.toLowerCase().includes(filters.email.toLowerCase()) &&
          (filters.role === "" || user.role === filters.role)
      )
      .sort((a, b) => {
        if (!sortConfig) return 0;
        const { key, direction } = sortConfig;
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      });
  
    const handleSort = (key) => {
      setSortConfig((prevSortConfig) =>
        prevSortConfig && prevSortConfig.key === key
          ? { key, direction: prevSortConfig.direction === "asc" ? "desc" : "asc" }
          : { key, direction: "asc" }
      );
    };
  
    return (
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Users</h1>
  
        {!selectedUser && !isCreating && !isEditing ? (
          <>
            {/* Filters */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              {["first_name", "last_name", "email"].map((field) => (
                <input
                  key={field}
                  name={field}
                  placeholder={`Filter by ${field.replace("_", " ")}`}
                  value={filters[field]}
                  onChange={handleFilterChange}
                  className="px-4 py-2 border rounded-md"
                />
              ))}
              <select
                name="role"
                value={filters.role}
                onChange={handleFilterChange}
                className="px-4 py-2 border rounded-md"
              >
                <option value="">All Roles</option>
                <option value="candidate">Candidate</option>
                <option value="recruiter">Recruiter</option>
                <option value="admin">Admin</option>
              </select>
            </div>
  
            {/* Create New User Button */}
            <button
              onClick={() => setIsCreating(true)}
              className="mb-4 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              Create New User
            </button>
  
            {/* User Table */}
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full">
                <thead className="bg-gray-100">
                  <tr>
                    {["first_name", "last_name", "email", "role"].map((field) => (
                      <th
                        key={field}
                        onClick={() => handleSort(field)}
                        className="py-3 px-4 text-left font-medium cursor-pointer hover:underline"
                      >
                        {field.replace("_", " ").toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.slice(0, visibleCount).map((user) => (
                    <tr
                      key={user.id}
                      onClick={() => setSelectedUser(user)}
                      className="hover:bg-gray-50 cursor-pointer"
                    >
                      <td className="py-2 px-4">{user.first_name}</td>
                      <td className="py-2 px-4">{user.last_name}</td>
                      <td className="py-2 px-4">{user.email}</td>
                      <td className="py-2 px-4 capitalize">{user.role}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
  
            {filteredUsers.length > visibleCount && (
              <button
                onClick={() => setVisibleCount(visibleCount + 20)}
                className="mt-4 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
              >
                Load More
              </button>
            )}
          </>
        ) : selectedUser && !isEditing ? (
          <UserDetail
            user={selectedUser}
            onClose={() => setSelectedUser(null)}
            onEdit={() => {
              setIsEditing(true);
            }}
          />
        ) : (
          <UserEditForm
            user={isCreating ? null : selectedUser}
            onClose={() => {
              setIsCreating(false);
              setIsEditing(false);
              setSelectedUser(null);
            }}
          />
        )}
      </div>
    );
  }
  
  export default UserList;