import Footer from "../components/Footer/Footer";
import PublicNavbar from "../components/Navbars/publicNavbar/PublicNavbar";

const PublicLayout = ({ children }) => {
    return <>
        <PublicNavbar/>
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 min-h-screen">{children}</div>
        <Footer/>
    </>
}

export default PublicLayout;