import React from 'react';
import { CardSimple } from '../Cards/CardWithFooter';

const TwoColumnContainer = ({ leftContent, rightContent }) => {
    return (
        <CardSimple
            contentData={<div className="">

                {/* Main Flex Container */}
                <div className="flex flex-col md:flex-row">

                    {/* Left Column */}
                    <div className="md:w-1/4 p-2 border-b md:border-b-0 md:border-r">
                        {leftContent}
                    </div>

                    {/* Right Column */}
                    <div className="md:w-3/4 p-6">
                        {rightContent}
                    </div>

                </div>
            </div>
            }

        />

    );
};

export default TwoColumnContainer;
