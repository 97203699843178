import apiCall from "../utils/apiCall";


export const fetchCategories = async(data) => {
    try {
        const response = await apiCall('get','/categories');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch categories:', error);
        throw error;
    }
}

export const fetchSkills = async(data) => {
    try {
        const response = await apiCall('get','/skills/skill');
        return response.data;
    } catch (error) {
        console.error('Failed to fetch skills:', error);
        throw error;
    }
}

export const fetchUsers = async() => {
    try {
        const response = await apiCall('get','/users/users');
        return response.data;
    } catch (error) {
        console.error('Failed to query users:', error);
        throw error;
    }
}

export const createUsers = async(data) => {
    try {
        const response = await apiCall('post','/users/admin/user', data);
        return response;
    } catch (error) {
        console.error('Failed to create user:', error);
        throw error;
    }
}

export const editUsers = async(data) => {
    try {
        const response = await apiCall('put',`/users/admin/user/${data.id}`,data);
        return response;
    } catch (error) {
        console.error('Failed to edit user:', error);
        throw error;
    }
}

export const resetPassword = async(data) => {
    try {
        const response = await apiCall('delete',`/users/admin/userpassword`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to reset password user:', error);
        throw error;
    }
}

export const deleteUsers = async(id) => {
    try {
        const response = await apiCall('delete',`/users/admin/user/${id}`);
        return response;
    } catch (error) {
        console.error('Failed to delete user:', error);
        throw error;
    }
}

export const checkEmail = async(data) => {
    try {
        const response = await apiCall('post','/users/email',{"email":data});
        console.log(response)
        return response;
    } catch (error) {
        console.error('Failed to check email:', error);
        throw error;
    }
}

export const fetchPsychQuestions = async() => {
    try {
        const response = await apiCall('get','/assessments/questions/psych/questions');
        return response.data;
    } catch (error) {
        console.error('Failed to query psychometric questions:', error);
        throw error;
    }
}

export const createPsychQuestion = async(data) => {
    try {
        const response = await apiCall('post','/assessments/questions/psych/questions', data);
        return response.data;
    } catch (error) {
        console.error('Failed to create psychometric question:', error);
        throw error;
    }
}

export const editPsychQuestion = async(data,id) => {
    try {
        const response = await apiCall('put',`/assessments/questions/psych/questions/${data.id}`,data);
        return response.data;
    } catch (error) {
        console.error('Failed to edit psychometric question:', error);
        throw error;
    }
}

export const deletePsychQuestion = async(id) => {
    try {
        const response = await apiCall('delete',`/assessments/questions/psych/questions/${id}`);
        return response;
    } catch (error) {
        console.error('Failed to delete psychometric question:', error);
        throw error;
    }
}

export const fetchPsychTraits = async() => {
    try {
        const response = await apiCall('get','/assessments/questions/psych/traits');
        return response.data;
    } catch (error) {
        console.error('Failed to query psychometric questions:', error);
        throw error;
    }
}

export const fetchAllJobs = async() => {
    try {
        const response = await apiCall('get','/jobs/job');
        return response.data;
    } catch (error) {
        console.error('Failed to query psychometric questions:', error);
        throw error;
    }
}

