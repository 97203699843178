import React from 'react';

const LoadingScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen bg-white fixed top-0 left-0 z-50">
      <div className="loader border-8 border-t-8 border-blue-600 w-16 h-16 rounded-full animate-spin"></div>
      <p className="mt-4 text-2xl text-blue-600">Loading...</p>
    </div>
  );
};

export default LoadingScreen;
