import {create} from 'zustand';

const useCandidateStore = create((set) => ({
  user: null,
  candidateReferences: [],
  friends: [],
  receivedInvitations: [],
  recruiterInvitations: [],
  scores: [],
  sentInvitations: [],
  workHistory: [],
  
  editingAssessmentSkill: null,
  editingAssessmentRequirement: null,
  editingAssessmentQuestion: null,

  // Recruiter Invitations
  initializeRecruiterInvitations: (newRecruiterInvitations) => set((state) => ({
    recruiterInvitations: [
      ...state.recruiterInvitations,
      ...newRecruiterInvitations.filter(
        newRecruiterInvitation => !state.recruiterInvitations.some(
          recruiterInvitation => recruiterInvitation.id === newRecruiterInvitation.id
        )
      )
    ]
  })),

  appendRecruiterInvitation: (newRecruiterInvitation) => set((state) => ({
    recruiterInvitations: [...state.recruiterInvitations, newRecruiterInvitation]
  })),

  editRecruiterInvitation: (editedRecruiterInvitation) => set((state) => ({
    recruiterInvitations: state.recruiterInvitations.map(recruiterInvitation =>
      recruiterInvitation.id === editedRecruiterInvitation.id ? editedRecruiterInvitation : recruiterInvitation
    )
  })),

  // Sent Invitations
  initializeSentInvitations: (newSentInvitations) => set((state) => ({
    sentInvitations: [
      ...state.sentInvitations,
      ...newSentInvitations.filter(
        newSentInvitation => !state.sentInvitations.some(
          recruiterInvitation => recruiterInvitation.id === newSentInvitation.id
        )
      )
    ]
  })),

  appendRecruiterInvitation: (newSentInvitation) => set((state) => ({
    sentInvitations: [...state.sentInvitations, newSentInvitation]
  })),

  editRecruiterInvitation: (editedSentInvitation) => set((state) => ({
    sentInvitations: state.sentInvitations.map(sentInvitation =>
      sentInvitation.id === editedSentInvitation.id ? editedSentInvitation : sentInvitation
    )
  })),

  // Received Invitations
  initializeReceivedInvitations: (newReceivedInvitations) => set((state) => ({
    receivedInvitations: [
      ...state.receivedInvitations,
      ...newReceivedInvitations.filter(
        newReceivedInvitation => !state.receivedInvitations.some(
          receivedInvitation => receivedInvitation.id === newReceivedInvitation.id
        )
      )
    ]
  })),

  appendReceivedInvitation: (newReceivedInvitation) => set((state) => ({
    receivedInvitations: [...state.receivedInvitations, newReceivedInvitation]
  })),

  editReceivedInvitation: (editedReceivedInvitation) => set((state) => ({
    receivedInvitations: state.receivedInvitations.map(receivedInvitation =>
      receivedInvitation.id === editedReceivedInvitation.id ? editedReceivedInvitation : receivedInvitation
    )
  })),

  // Work History
  addWorkHistory: (newWorkHistories) => set((state) => ({
    workHistory: [
      ...state.workHistory,
      ...newWorkHistories.filter(
        newWorkHistories => !state.workHistory.some(
          historyEntry => historyEntry.id === newWorkHistories.id
        )
      )
    ]
  })),

  addNewWorkHistory: (newWorkHistory) => set((state) => ({
    workHistory: [...state.workHistory, newWorkHistory]
  })),

  editWorkHistory: (editedWorkHistory) => set((state) => ({
    workHistory: state.workHistory.map(workHistoryEntry =>
        workHistoryEntry.id === editedWorkHistory.id ? editedWorkHistory : workHistoryEntry
    )
  })),

  removeWorkHistory: (deletableCandidateSkill) => set((state) => ({
    workHistory: state.workHistory.filter(historyEntry => historyEntry.id !== deletableCandidateSkill)
  })),

  // Friends
  addFriends: (newFriends) => set((state) => ({
    friends: [
      ...state.friends,
      ...newFriends.filter(
        newFriend => !state.friends.some(friend => friend.id === newFriend.id)
      ),
    ]
  })),

  // Candidate References
  addCandidateReferences: (newReferences) => set((state) => ({
    candidateReferences: [
      ...state.candidateReferences,
      ...newReferences
    ]
  })),

  addNewReference: (newReference) => set((state) => ({
    candidateReferences: [...state.candidateReferences, newReference]
  })),

  editReference: (editedReference) => set((state) => ({
    candidateReferences: state.candidateReferences.map(reference =>
      reference.id === editedReference.id ? editedReference : reference
    )
  })),

  removeReference: (deletableReference) => set((state) => ({
    candidateReferences: state.candidateReferences.filter(reference => reference.id !== deletableReference)
  })),


  checkReferenceExists: (email) => {
    const { candidateReferences } = useCandidateStore.getState();
    return candidateReferences.some(
      (ref) => ref.email === email
    );
  },

}));

export default useCandidateStore;
