import ContainerNarrow from "../../../components/Containers/ContainerNarrow";
import { H1, H3, H5, P } from "../../../components/Typography/Typography";

function Introduction() {
    return <ContainerNarrow>
        <H1>Introduction</H1>
        <>
        <H3>GoSkilling Design System</H3>
        <P>Welcome to the GoSkilling Design System</P>

        <P>The GoSkilling Design System is a comprehensive guide to the visual and interaction principles that drive the design and development of our user interfaces. It ensures consistency, efficiency, and scalability across all our products, helping us create cohesive and delightful user experiences.</P>
        <H5>Design Principles</H5>
        
        <P>Our design principles are the foundation of our design system. They guide every design decision and ensure our products are intuitive, inclusive, and accessible.</P>
            <ul>
               <li>Consistency: Maintain visual and functional consistency across all products.</li>
               <li>Simplicity: Keep designs clean and straightforward, avoiding unnecessary complexity.</li>
               <li>Accessibility: Ensure our products are usable by people of all abilities.</li>
               <li>Responsiveness: Create designs that work seamlessly on all devices and screen sizes. </li>
            </ul>
            
        
        <H5>Brand Identity</H5>
        
        <P>Our brand identity reflects who we are and what we stand for. It encompasses our logo, color palette, typography, and imagery guidelines, creating a distinct and recognizable visual language.</P>
        
        <li>Logo: Use the official GoSkilling logo in all communications.</li>
        <li>Color Palette: Our primary colors are #408ff1 for primary actions and #6b7280 for secondary elements.</li>
        <li>Typography: Use the 'Roboto' font family for all text, maintaining consistency in size and weight.</li>
        <li>Imagery: Use high-quality images that align with our brand values and messaging.</li>
        
        <H5>Inspirations</H5>
        <ul>
            <li>https://youtu.be/NJGLR5gl6m4?si=3Y8GenfLIsWn1kBM</li>
            <li>https://youtu.be/Kkjpvfwhv-c?si=vRoxurhSgDw6P7fy</li>
            <li>https://youtu.be/qyomWr_C_jA?si=wajnosHwKsh2xUsL</li>
        </ul>
        </>
    </ContainerNarrow>
}

export default Introduction;