function CardWithFooter({ contentColor, footerContent, contentData }) {
  return <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
    <div className={`px-4 py-5 sm:p-6 ${contentColor}`}>{contentData}</div>
    <div className="px-4 py-4 sm:px-6">
      {/* Content goes here */}
      {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
      {footerContent}
    </div>
  </div>
}

function CardSimple({ contentData }) {
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow my-4">
      <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
        {contentData}
      </div>
    </div>
  )
}

function CardWithHeader({ headerContent, contentData }) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        {/* Content goes here */}
        {/* We use less vertical padding on card headers on desktop than on body sections */}
      </div>
      <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
        {contentData}
      </div>
    </div>
  )
}

function CardWithHeaderFooter({headerContent, contentData, footerContent}) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        {/* Content goes here */}
        {/* We use less vertical padding on card headers on desktop than on body sections */}
        {headerContent}
      </div>
      <div className="px-4 py-5 sm:p-6">{/* Content goes here */}
        {contentData}
      </div>
      <div className="px-4 py-4 sm:px-6">
        {/* Content goes here */}
        {/* We use less vertical padding on card footers at all sizes than on headers or body sections */}
        {footerContent}
      </div>
    </div>
  )
}



export {CardWithFooter, CardWithHeader, CardWithHeaderFooter, CardSimple};