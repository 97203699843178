import Link from '../../../components/Link/Link';
import Badge from '../../../components/Badge/Badge';
import { H3 } from '../../../components/Typography/Typography';
import useExperimentStore from '../../../store/experimentStore';
import Beautifier from '../../../../legacy/components/common/beautifier';
//import Beautifier from "../../../components/common/beautifier";

const data = [
    {
      "uri_id": 1,
      "status": "pending", // Example status
      "uri_data": "https://example.com/invite/12345",
      "user_id": 123,
      "user_first_name": "John",
      "user_last_name": "Doe",
      "user_email": "johndoe@example.com",
      "reference_user_id": 456,
      "reference_first_name": "Jane",
      "reference_last_name": "Smith",
      "reference_email": "janesmith@example.com",
      "job_id": 789,
      "job_name": "Software Engineer",
      "job_description": "Looking for a talented developer...",
      "job_company_name": "Acme Corp",
      "job_location": "San Francisco, CA",
      "job_candidate_id": 1011,
      "assessment_id": 12,
      "assessment_name": "Programming Skills Assessment",
      "assessment_description": "Test your coding abilities...",
      "recruiter_first_name": "Sarah",
      "recruiter_last_name": "Lee",
      "recruiter_email": "sarahlee@example.com",
      "expiration_date": "2024-10-01"
    }
  ]

const SkillItem = ({ candidate, onDelete, onEdit, job }) => {
    const expiration_date = new Date(candidate.expiration_date).toLocaleString('en-US', {
        dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
        timeStyle: 'short' // Adjust the time style as needed (e.g., 'full', 'medium', 'long')
    });
    let status = "complete" //candidate.status;
    return (
        <tr key={candidate.candidate_id}>
            {/* <Beautifier value={candidate}/> */}
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                From: {candidate.user_first_name} {candidate.user_last_name}
                <div className="mt-1 flex flex-col text-gray-500">
                    <span>{candidate.user_email}</span>
                </div>
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span>      {
                        candidate.status === 'complete'
                            ? `Assessed`
                            : <Link to={`/candidate/assessments/${candidate.assessment_id}/${candidate.job_candidate_id}/${candidate.reference_id}`}>Assess till {expiration_date}</Link>
                            // <Link to={`/candidate/assessments/${candidate.assessment_id}/${candidate.job_candidate_id}/${candidate.reference_id}`}>Results</Link> assessments/:assessmentId/:jobCandidateId/:referenceId
                    }  </span>
                    <span>Status: {candidate.status}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <Badge
                    text={candidate.status === "pending" ? "pending" : candidate.status === "expired" ? "expired" : "complete"}
                    color={candidate.status === "pending" ? "blue" : candidate.status === "expired" ? "red" : "green"}
                />
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                {
                    candidate.status === 'complete'
                        ? "Assessed"
                        : <Link to={`/candidate/assessments/${candidate.assessment_id}/${candidate.job_candidate_id}/${candidate.reference_id}`}>Assess till {expiration_date}</Link>
                }
            </td>
        </tr>
    );
};

export default function ReceivedInvitation({ candidates, onDelete, onEdit, jobItem }) {
    const {
        receivedInvitations
    } = useExperimentStore(state => state);

    return (
        <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <H3>Received</H3>
            {
                receivedInvitations.length === 0
                    ? <div className="text-center py-20 min-h-40">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No received assessment invitations</h3>
                        <p className="mt-1 text-sm text-gray-500">You will be notified about assessment invitations from your peers.</p>
                    </div>
                    : <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                </th>

                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Status
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    {
                                        receivedInvitations.status !== 'complete'
                                            ? "Expiration Date"
                                            : "Results"
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody className='divider divide-y divide-x'>
                            {receivedInvitations.map((receivedInvitation, index) => (
                                <>
                                    <SkillItem
                                        key={receivedInvitation.id}
                                        candidate={receivedInvitation}
                                        onDelete={onDelete}
                                        onEdit={onEdit}
                                    />
                                </>
                            ))}
                        </tbody>
                    </table>

            }

        </div>
    );
};