import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { checkEmail, createUsers, editUsers } from "../../../services/adminService";
import useAdminStore from "../../../store/adminStore";

function UserEditForm({ user, onClose }) {
  const { register, handleSubmit, setError, clearErrors, watch } = useForm({
    defaultValues: {
      id: user?.id || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      role: user?.role || "candidate",
    },
  });
  const { editStoredUser, addNewUser } = useAdminStore();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailChanged, setIsEmailChanged] = useState(false); // New state
  const email = watch("email");

  useEffect(() => {
    // Set isEmailChanged to true if the current email differs from the original
    setIsEmailChanged(email !== user?.email);
    setIsEmailChecked(email === user?.email); // Reset email check status when email changes
    setIsEmailValid(true); // Reset to valid by default until checked
  }, [email, user?.email]);

  const onSubmit = async (data) => {
    console.log(user ? "Editing user:" : "Creating new user:", data);
    if (user) {
      console.log("Update");
      data.emailChanged = isEmailChanged; // Use isEmailChanged to indicate if email was modified
      const updatedUser = await editUsers(data);
      if (updatedUser.success) {
        await editStoredUser(updatedUser.data);
        console.log("User updated");
      } else {
        console.error("User not updated:", updatedUser.error);
      }
    } else {
      console.log("Create");
      const createdUser = await createUsers(data);
      if (createdUser.success) {
        await addNewUser(createdUser.data);
        console.log("User created");
      } else {
        console.error("User not created:", createdUser.error);
      }
    }
    onClose();
  };

  const checkEmailExists = async () => {
    try {
      const response = await checkEmail(email);
      const emailExistsMessage = response.message;

      if (emailExistsMessage === "Email does not exist") {
        setIsEmailValid(true);
        clearErrors("email");
      } else {
        setIsEmailValid(false);
        setError("email", { type: "manual", message: "Email already exists" });
      }

      setIsEmailChecked(true); // Set email as checked only if we got a backend response
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };

  return (
    <div className="mt-6 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">{user ? "Edit User" : "Create New User"}</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <input
          {...register("first_name", { required: "First name is required" })}
          placeholder="First Name"
          className="w-full p-2 border rounded-md"
        />
        <input
          {...register("last_name", { required: "Last name is required" })}
          placeholder="Last Name"
          className="w-full p-2 border rounded-md"
        />

        <div className="relative flex items-center">
          <input
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Invalid email format",
              },
            })}
            placeholder="Email"
            className="w-full p-2 border rounded-md"
          />
          <button
            type="button"
            onClick={checkEmailExists}
            disabled={isEmailChecked || !isEmailChanged}
            className={`ml-2 px-2 py-1 rounded-md ${
              isEmailChecked ? "bg-gray-300 text-gray-700" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Check Email
          </button>
        </div>
        {!isEmailValid && <p className="text-red-500 text-sm">Email already exists</p>}

        <select
          {...register("role", { required: "Role is required" })}
          className="w-full p-2 border rounded-md"
        >
          <option value="candidate">Candidate</option>
          <option value="recruiter">Recruiter</option>
          <option value="admin">Admin</option>
        </select>

        <div className="flex space-x-2">
          <button type="button" onClick={onClose} className="bg-gray-200 rounded-md px-4 py-2">Cancel</button>
          <button
            type="submit"
            disabled={
              !isEmailChecked ||
              !isEmailValid ||
              !watch("first_name") ||
              !watch("last_name") ||
              !watch("role")
            }
            className={`px-4 py-2 rounded-md font-semibold ${
              !isEmailChecked || !isEmailValid ? "bg-gray-300 text-gray-700 cursor-not-allowed" : "bg-green-500 text-white hover:bg-green-600"
            }`}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default UserEditForm;
