import { HiChevronRight as ChevronRightIcon } from 'react-icons/hi2';

import { useState } from 'react';
import { useForm } from 'react-hook-form';

//import useRecruiterStore from '../../state/recruiterState';
import Link from '../../../components/Link/Link';
import useGlobalStore from '../../../store/globalStore';
import useRecruiterStore from '../../../store/recruiterStore';
import { createAssessment } from '../../../services/recruiterService';
import Button from '../../../components/Buttons/Buttons';
import { CardSimple } from '../../../components/Cards/CardWithFooter';
import { H1 } from '../../../components/Typography/Typography';
import { Input, Textbox } from '../../../components/Form/Form';
const AssessmentItem = ({ assessment, onDelete, onEdit }) => {
  const { countAssessmentQuestions, countAssessmentRequirements, countAssessmentJobs } = useRecruiterStore((state) => state);

  return (
    <tr key={assessment.id}>
      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {assessment.name}
        <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
          <span>{assessment.description}</span>
          <span>{assessment.location}</span>
          <span>{countAssessmentQuestions(assessment.id)}</span>
          <span>{countAssessmentRequirements(assessment.id)}</span>
          <span>{countAssessmentJobs(assessment.id)}</span>
        </div>
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
        {assessment.description}
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
        {countAssessmentQuestions(assessment.id)}
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
        {countAssessmentRequirements(assessment.id)}
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
        {countAssessmentJobs(assessment.id)}
      </td>
      <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link
          to={`/recruiter/assessments/${assessment.id}`}
          className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          View
          <ChevronRightIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
        </Link>
      </td>
    </tr>
  );
};

export default function Assessments() {
  const [notificationText, setNotificationText] = useState("All systems nominal.");
  const [editorOn, setEditorOn] = useState(false);
  const [entriesPage, setEntriesPage] = useState(5);
  const {
    assessments, addNewAssessment, psychQuestions
  } = useRecruiterStore((state) => state);
  const { setAppNotificationWithTimeout } = useGlobalStore((state) => state);
  // Assessment form
  const { register: register3, handleSubmit: handleSubmit3, setValue: setValue3, reset: reset3, formState: { errors: errors3, isValid: isValid3 } } = useForm({
    mode: 'onChange', // Enables validation on change
  });

  const onCreateAssessment = async (data) => {
    const result = await createAssessment(data);
    if (result) {
      addNewAssessment(result);
      setAppNotificationWithTimeout('Assessment appended.', 'success', 'Assessment created!', 3000);
      setEditorOn(false);
      reset3();
    } else {
      setAppNotificationWithTimeout('Error appending assessment.', 'error', 'Error appending assessment', 3000);
    }
  }

  return (

    <CardSimple
      className="px-4 sm:px-6 lg:px-8"
      contentData={
        <><H1>Assessment Tests</H1>

          {!editorOn &&
            <div className="-mx-4 mt-10  ring-gray-300 sm:mx-0 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Assessment Title
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Description
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Questions
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Requirements
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                      Jobs
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {assessments.map((assessment, jobIdx) => ( */}
                  {assessments.slice(0, entriesPage).map((assessment, jobIdx) => (
                    <AssessmentItem key={assessment.id} assessment={assessment} />
                  ))}
                </tbody>
              </table>
              <div className="mt-4 sm:flex sm:justify-end">
                {assessments.length > entriesPage &&
                  <Button
                    variant="primary"
                    size="small"
                    onClick={() => setEntriesPage(entriesPage + 5)}
                    className="block mr-2"

                  >
                    View more
                  </Button>

                }
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => setEditorOn(true)}
                >
                  Create
                </Button>
              </div>
            </div>
          }

          {
            editorOn &&
            <form onSubmit={handleSubmit3(onCreateAssessment)} className="space-y-8 bg-white p-8 rounded-lg ">
              <Input
                label="Assessment name"
                id="name"
                register={register3}
                required={true}
                errors={errors3}
                type="text"
              />
              <Textbox
                label="Assessment description"
                id="description"
                register={register3}
                required={true}
                errors={errors3}
              />

              <div className="mt-4 sm:flex sm:justify-end flex flex-shrink-0 md:ml-4 md:mt-0">
                <Button
                  variant="primary"
                  size="small"
                  className={"w-full mr-2"}
                  type="submit"
                  disabled={!isValid3}
                >
                  Create Assessment
                </Button>

                <Button
                  variant="secondary"
                  size="small"
                  className={"blocked"}
                  type="submit"
                  onClick={() => setEditorOn(false)}
                //disabled={!isValid}
                >
                  Close
                </Button>

              </div>
            </form>
          }
        </>

      }
    >

    </CardSimple>
  );
}
