import { useForm } from "react-hook-form";
//import useCandidateStore from "../../../store/globalStore";
import { deleteReference, createReference } from "../../../services/candidateService";
import useUserStore from "../../../store/userStore";
import useExperimentStore from "../../../store/experimentStore";
import { CardSimple } from "../../../components/Cards/CardWithFooter";
import { H1, H3 } from "../../../components/Typography/Typography";
import Button from "../../../components/Buttons/Buttons";
import { useState } from "react";
import { Input, Select, SelectCV, SelectJobCandidate } from "../../../components/Form/Form";
import useGlobalStore from "../../../store/globalStore";
import useCandidateStore from "../../../store/candidateStore";
import Beautifier from "../../../../legacy/components/common/beautifier";

const data = [
    {
      // user_reference_invitations (uri) data
      id: "550e8400-e29b-41d4-a716-446655440000",
      job_candidate_id: "7c9e6679-7425-40de-944b-e07fc1f90ae7",
      reference_id: "8f9e1680-c81a-4f8a-9b4c-d9a111b31001",
      status: "pending",
      created_at: "2024-09-01T10:00:00Z",
      updated_at: "2024-09-01T10:00:00Z",
  
      // user (u1) data
      user_id: "a1b2c3d4-e5f6-4a5b-8c7d-9e0f1a2b3c4d",
      user_first_name: "John",
      user_last_name: "Doe",
      user_email: "john.doe@example.com",
  
      // reference user (u2) data
      reference_user_id: "b2c3d4e5-f6a7-5b6c-9d0e-1f2a3b4c5d6e",
      reference_first_name: "Jane",
      reference_last_name: "Smith",
      reference_email: "jane.smith@example.com",
  
      // job (j) data
      job_id: "c3d4e5f6-a7b8-6c7d-0e1f-2a3b4c5d6e7f",
      job_name: "Software Engineer",
      job_description: "Developing cutting-edge web applications",
      job_company_name: "TechCorp",
      job_location: "San Francisco, CA",
  
      // job_candidate (jc) data
      job_candidate_id: "7c9e6679-7425-40de-944b-e07fc1f90ae7",
      expiration_date: "2024-12-31T23:59:59Z",
  
      // assessment (a) data
      assessment_id: "d4e5f6a7-b8c9-7d0e-1f2a-3b4c5d6e7f8g",
      assessment_name: "Web Development Skills",
      assessment_description: "Evaluating proficiency in web technologies",
  
      // owner (uo) data
      recruiter_first_name: "Alice",
      recruiter_last_name: "Johnson",
      recruiter_email: "alice.johnson@techcorp.com"
    },
    {
      // user_reference_invitations (uri) data
      id: "660f9511-f30c-52e5-b827-557766551111",
      job_candidate_id: "8d0f7780-8536-51ef-055c-f18fd2f01bf8",
      reference_id: "9g0f2791-d92b-5g9b-0c5d-e0b222c42112",
      status: "completed",
      created_at: "2024-09-02T11:30:00Z",
      updated_at: "2024-09-05T14:45:00Z",
  
      // user (u1) data
      user_id: "e5f6a7b8-c9d0-5e6f-7a8b-9c0d1e2f3a4b",
      user_first_name: "Emily",
      user_last_name: "Brown",
      user_email: "emily.brown@example.com",
  
      // reference user (u2) data
      reference_user_id: "f6a7b8c9-d0e1-6f7a-8b9c-0d1e2f3a4b5c",
      reference_first_name: "Michael",
      reference_last_name: "Wilson",
      reference_email: "michael.wilson@example.com",
  
      // job (j) data
      job_id: "a7b8c9d0-e1f2-7a8b-9c0d-1e2f3a4b5c6d",
      job_name: "Data Scientist",
      job_description: "Analyzing big data and creating predictive models",
      job_company_name: "DataInsights Inc.",
      job_location: "New York, NY",
  
      // job_candidate (jc) data
      job_candidate_id: "8d0f7780-8536-51ef-055c-f18fd2f01bf8",
      expiration_date: "2025-01-31T23:59:59Z",
  
      // assessment (a) data
      assessment_id: "b8c9d0e1-f2a3-8b9c-0d1e-2f3a4b5c6d7e",
      assessment_name: "Machine Learning Proficiency",
      assessment_description: "Evaluating skills in ML algorithms and data analysis",
  
      // owner (uo) data
      recruiter_first_name: "Robert",
      recruiter_last_name: "Lee",
      recruiter_email: "robert.lee@datainsights.com"
    },
    {
      // user_reference_invitations (uri) data
      id: "770g0622-g41d-63f6-c938-668877662222",
      job_candidate_id: "9e1g8891-9647-62fg-166d-g29ge3g12cg9",
      reference_id: "0h1g3802-e03c-6h0c-1d6e-f1c333d53223",
      status: "in_progress",
      created_at: "2024-09-03T09:15:00Z",
      updated_at: "2024-09-06T16:20:00Z",
  
      // user (u1) data
      user_id: "a7b8c9d0-e1f2-6a7b-8c9d-0e1f2a3b4c5d",
      user_first_name: "David",
      user_last_name: "Garcia",
      user_email: "david.garcia@example.com",
  
      // reference user (u2) data
      reference_user_id: "b8c9d0e1-f2a3-7b8c-9d0e-1f2a3b4c5d6e",
      reference_first_name: "Sarah",
      reference_last_name: "Taylor",
      reference_email: "sarah.taylor@example.com",
  
      // job (j) data
      job_id: "c9d0e1f2-a3b4-8c9d-0e1f-2a3b4c5d6e7f",
      job_name: "UX Designer",
      job_description: "Creating intuitive and engaging user experiences",
      job_company_name: "DesignMasters LLC",
      job_location: "Austin, TX",
  
      // job_candidate (jc) data
      job_candidate_id: "9e1g8891-9647-62fg-166d-g29ge3g12cg9",
      expiration_date: "2025-02-28T23:59:59Z",
  
      // assessment (a) data
      assessment_id: "d0e1f2a3-b4c5-9d0e-1f2a-3b4c5d6e7f8g",
      assessment_name: "UI/UX Design Skills",
      assessment_description: "Evaluating proficiency in user-centered design principles",
  
      // owner (uo) data
      recruiter_first_name: "Jennifer",
      recruiter_last_name: "Martinez",
      recruiter_email: "jennifer.martinez@designmasters.com"
    }
  ]

function CandidateReferences() {
    const [showEditor, setShowEditor] = useState(false);
    const {
        candidateReferences, candidateRefs, whistory
    } = useCandidateStore(state => state);
    const {
        friends, tests, userReferences, recruiterInvitations
    } = useExperimentStore(state => state);
    const onDeleteReference = () => {

    }

    return <CardSimple
        contentData={
            <>
                <H1>References</H1>
                <Beautifier value={userReferences} />
                <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    {
                        userReferences.length === 0
                            ? <div className="text-center py-20 min-h-40">
                                <h3 className="mt-2 text-sm font-semibold text-gray-900">No received assessment invitations</h3>
                                <p className="mt-1 text-sm text-gray-500">You will be notified about assessment invitations from your peers.</p>
                            </div>
                            : <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Reference
                                        </th>
                                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                            Relationship
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='divider divide-y divide-x'>
                                    {userReferences.map((reference, index) => (
                                        <ReferenceItem person={reference} deleteReference={onDeleteReference} />
                                    ))}
                                </tbody>
                            </table>
                    }
                    <Button
                        type="button"
                        variant="primary"
                        size="normal"
                        onClick={() => setShowEditor(true)}
                    >
                        Add Reference
                    </Button>
                    {
                        showEditor
                            ? <ReferenceEditor changeEditorStatus={setShowEditor} />
                            : null
                    }
                </div>
            </>
        }
    />
}

export default CandidateReferences;

function ReferenceItem({ person, deleteReference }) {
    const onDeleteReference = (data) => {
        deleteReference(data);
    }
    const {
        findMutualWorkHistory, workHistory
    } = useExperimentStore(state => state);
    //console.log(person.work_history_id)
    const work_history_id = person.work_history_id;
    const mutualWork = findMutualWorkHistory( {work_history_id} );
    console.log(mutualWork)
    const candidate = person;
    return <tr key={candidate.candidate_id}>
        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            {candidate.reference_first_name} {candidate.reference_last_name}
            <div className="mt-1 flex flex-col text-gray-500">
                <span>{candidate.reference_email}</span>
                <span>{mutualWork.company_name}  </span>
            </div>
            <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                <span> {
                    candidate.role === 'peer'
                        ? "Peer"
                        : candidate.role === 'supervisor'
                            ? "Supervisor"
                            : "Subordinate"
                }</span>
            </div>
        </td>
        <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
            {
                candidate.role === 'peer'
                    ? "Peer"
                    : candidate.role === 'supervisor'
                        ? "Supervisor"
                        : "Subordinate"
            }
        </td>
    </tr>

    // <li key={person.email} className="flex justify-between gap-x-6 py-5 px-4">
    //     {
    //         <>
    //             <div className="flex min-w-0 gap-x-4">
    //                 <div className="min-w-0 flex-auto">
    //                     <p className="text-xl font-semibold leading-6 text-gray-900">{person.reference_first_name + " " + person.reference_last_name}</p>
    //                     <p className="mt-1 truncate text-xs leading-5 text-gray-500">{person.reference_email}</p>
    //                 </div>
    //             </div>
    //             <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
    //                 <p className="text-sm leading-6 text-gray-900">{person.role}</p>

    //                 <>
    //                     <p className="mt-1 text-xs leading-5 text-gray-500">
    //                         Status: {person.confirmed ? "Confirmed" : "Not confirmed"}
    //                     </p>
    //                     <button
    //                         type="button"
    //                         onClick={() => onDeleteReference(person.id)}
    //                     >
    //                         Delete
    //                     </button>
    //                 </>

    //             </div>
    //         </>
    //     }

    // </li>
}

function ReferenceEditor({ editingCandidate, editingJobAssessment, changeEditorStatus, workHistoryData }) {
    const {
        references
    } = useCandidateStore((state) => state);
    const {
        workHistory, recruiterInvitations, checkReferenceExists, addNewReference, addNewSentInvitation, findJobById, findAssessmentById
    } = useExperimentStore(state => state);
    const { setAppNotificationWithTimeout, setNotification, setNotificationStatus, notification } = useGlobalStore((state) => state);
    const { register: register10, handleSubmit: handleSubmit10, setValue: setValue10, reset: reset10, formState: { errors: errors10, isValid: isValid10 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingCandidate
    });


    const onEditorClose = async (data) => {
        changeEditorStatus(false);
        //console.log(data)
        // const result = await createReference(data);
        // if (result.error) {
        //     //setNotificationText("Error deleting work history.");
        // } else {
        //     addNewReference(data);
        //     //setNotificationText("Work History deleted.");
        // }
    };

    const appendReference = async (data) => {
        console.log(data)
        console.log(workHistoryData)

        data.work_history_id = workHistoryData.id;
        data.start_date = workHistoryData.start_date;
        data.end_date = workHistoryData.end_date;
        data.confirmed = false;
        data.reference_id = null;
        data.status = null;
        console.log(data);
        const result = await createReference(data);
        console.log(result);
        // if (result.error) {
        //     setNotification("Error deleting work history.");
        // } else {

        //     addNewReference(data);
        //     setNotification("Work History deleted.");
        // }
    }

    const onAddReference = async (data) => {
        console.log(data)
        const notAddable = checkReferenceExists(data);
        const job = findJobById(data.job_candidate_id);
        console.log(`The reference can ${notAddable ? 'not' : ''} be added.`);
        if (!notAddable) {
            const response = await createReference(data);
            console.log(response);
            if (!response.success && !response.error) {
                setAppNotificationWithTimeout('Adding reference failed.', 'warning', 'You have already invited the user.', 3000); // 3 seconds
            } else if(response.success) {
                setAppNotificationWithTimeout('Reference added.', 'success', 'Reference added successfully', 3000); // 3 seconds
                addNewReference(response.data.userReference);
                const referenceInvitation = response.data.referenceInvitation;
                referenceInvitation.job_name = job.job_name;
                referenceInvitation.assessment_name = job.assessment_name;
                referenceInvitation.expiration_date = job.expiration_date;
                referenceInvitation.reference_first_name = data.first_name;
                referenceInvitation.reference_last_name = data.last_name;
                referenceInvitation.reference_email = data.email;
                addNewSentInvitation(response.data.referenceInvitation);
                reset10();
            } else {
                setAppNotificationWithTimeout('An error occurred.', 'error', 'An error occurred.', 3000); // 3 seconds
            }
        } else {
            setAppNotificationWithTimeout('Can not add reference. Is already a reference.', 'info', 'Can not add reference. Is already a reference.', 3000); // 3 seconds
        }
    };

    return <form onSubmit={handleSubmit10(onAddReference)}>
        <Input
            label="First Name"
            id="first_name"
            type="text"
            required={true}
            register={register10}
            errors={errors10}
        />
        <Input
            label="Last Name"
            id="last_name"
            type="text"
            required={true}
            register={register10}
            errors={errors10}
        />
        <Input
            label="Email"
            id="email"
            type="email"
            required={true}
            register={register10}
            errors={errors10}
        />
        <SelectCV
            label="CV Entry"
            id="work_history_id"
            register={register10}
            required={true}
            errors={errors10}
            options={workHistory}
        />
        <SelectJobCandidate
            label="Pending Assessments"
            id="job_candidate_id"
            register={register10}
            required={true}
            errors={errors10}
            options={recruiterInvitations}
        />
        <Select
            label="Relationship"
            id="reference_role"
            register={register10}
            required={true}
            errors={errors10}
            options={[
                { "id": "subordinate", "name": "Subordinate" },
                { "id": "peer", "name": "Peer/Co-Worker" },
                { "id": "supervisor", "name": "Manager/Supervisor" },
            ]}
        />


        <div className="mt-4 sm:flex sm:justify-end">
            <Button
                variant="primary"
                size="medium"
                type="submit"
                disabled={!isValid10}
                className="mr-2"
            >
                Add Reference
            </Button>
            <Button
                variant="secondary"
                size="medium"
                type="button"
                onClick={() => onEditorClose(false)}
                className=""
            >
                Return
            </Button>
        </div>
    </form>
}