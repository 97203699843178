// src/components/Link.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from '../../utils/ClassNames';

const Link = ({ to, external, className, children, ...props }) => {
  const isHashLink = to.startsWith('#');

  if (external) {
    return (
      <a href={to} className={classNames('text-brand-500 hover:text-brand-600', className)} {...props}>
        {children}
      </a>
    );
  }

  if (isHashLink) {
    return (
      <a href={to} className={classNames('text-brand-500 hover:text-brand-600', className)} {...props}>
        {children}
      </a>
    );
  }

  return (
    <RouterLink to={to} className={classNames('text-brand-500 hover:text-brand-600', className)} {...props}>
      {children}
    </RouterLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Link.defaultProps = {
  external: false,
  className: '',
};

export default Link;

