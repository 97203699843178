import React, { useState } from 'react';
import useAdminStore from '../../../store/adminStore';

function JobList() {
    const { jobs } = useAdminStore((state) => state);
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-semibold text-gray-800 mb-6">Job Listings</h1>
      
      <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {jobs.map((job) => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
    </div>
  );
}

function JobCard({ job }) {
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  return (
    <div className="bg-white shadow-lg rounded-lg border border-gray-200 p-6 flex flex-col justify-between transition-transform transform hover:scale-105 duration-200">
      <div>
        <h2 className="text-lg font-semibold text-gray-800 truncate mb-2">{job.name}</h2>
        <p className="text-gray-500 text-sm truncate">{job.company_name}</p>
        <p className="text-gray-500 text-sm mb-4">{job.location}</p>

        <div className="text-gray-700 mb-2">
          <p className="font-medium">Status: 
            <span className={`font-semibold ml-1 ${job.status === 'draft' ? 'text-yellow-500' : 'text-green-500'}`}>
              {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
            </span>
          </p>
          <p className="font-medium">Recruiter: <span className="font-normal">{job.recruiter_first_name} {job.recruiter_last_name}</span></p>
        </div>

        <div className="text-gray-700">
          <h3 className="text-base font-semibold mt-4 mb-1">Assessment</h3>
          <p className="text-sm font-medium">Name: <span className="font-normal">{job.assessment_name}</span></p>
        </div>

        {isDescriptionVisible && (
          <div className="text-gray-600 mt-4">
            <h3 className="font-semibold text-gray-800">Description</h3>
            <p className="text-sm">{job.description}</p>
          </div>
        )}
      </div>

      <div className="mt-4">
        <button
          onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
          className="text-blue-500 text-sm font-semibold hover:underline focus:outline-none"
        >
          {isDescriptionVisible ? 'Hide Details' : 'View Details'}
        </button>
        <div className="text-gray-400 text-xs mt-2">
          <p>Created: {new Date(job.created_at).toLocaleDateString()}</p>
          <p>Updated: {new Date(job.updated_at).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
}

export default JobList;
