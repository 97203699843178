import { HiTrash as TrashIcon, HiChevronLeft as ChevronLeftIcon, HiChevronRight as ChevronRightIcon, HiPencil as PencilIcon } from 'react-icons/hi2';

import Link from '../../../components/Link/Link';
import useRecruiterStore from '../../../store/recruiterStore';
import Button from '../../../components/Buttons/Buttons';
import Badge from '../../../components/Badge/Badge';
import useCandidateStore from '../../../../legacy/state/candidateState';
import ReceivedInvitation from './ReceivedInvitations';
import RecruiterInvitations from './RecruiterInvitations';
import SentInvitationComponent from './SentInvitations';
import Tabs from '../../../components/Tabs/Tabs';
import { useState } from 'react';
import { CardSimple } from '../../../components/Cards/CardWithFooter';

import { H1 } from '../../../components/Typography/Typography';
//import Beautifier from "../../../components/common/beautifier";


function Invitations() {
    const [currentTab, setCurrentTab] = useState('Recruiter');
    const tabs = [
        { name: 'Recruiter', href: '#', current: currentTab === 'Recruiter' },
        { name: 'Received', href: '#', current: currentTab === 'Received' },
        { name: 'Sent', href: '#', current: currentTab === 'Sent' },
    ];
    return <CardSimple
        contentData={
            <>
                <H1>Candidate Invitations</H1>
                <Tabs
                    tabs={tabs} onTabChange={setCurrentTab}
                />

                {
                    currentTab === 'Recruiter' &&
                    <RecruiterInvitations />
                }
                {
                    currentTab === 'Received' &&
                    <ReceivedInvitation />
                }                
                {
                    currentTab === 'Sent' &&
                    <SentInvitationComponent />
                }



            </>
        }
    />


}

export default Invitations;