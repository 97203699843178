// src/components/Link.js

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from '../../utils/ClassNames';

// List Component using an array of items
const ListWithItems = ({ items, ordered, className, itemClassName }) => {
    const ListTag = ordered ? 'ol' : 'ul';

    return (
        <ListTag className={classNames('list-disc pl-5', className)}>
            {items.map((item, index) => (
                <li key={index} className={classNames('mb-2', itemClassName)}>
                    {item}
                </li>
            ))}
        </ListTag>
    );
};

ListWithItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    ordered: PropTypes.bool,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
};

ListWithItems.defaultProps = {
    ordered: false,
    className: '',
    itemClassName: '',
};

// List Component using children
const ListWithChildren = ({ children, ordered, className }) => {
    const ListTag = ordered ? 'ol' : 'ul';

    return (
        <ListTag className={classNames('list-disc pl-5', className)}>
            {React.Children.map(children, (child, index) => (
                <li key={index} className="mb-2">
                    {child}
                </li>
            ))}
        </ListTag>
    );
};

ListWithChildren.propTypes = {
    children: PropTypes.node.isRequired,
    ordered: PropTypes.bool,
    className: PropTypes.string,
};

ListWithChildren.defaultProps = {
    ordered: false,
    className: '',
};

// Horizontal List Component using an array of items
const HorizontalListWithItems = ({ items, ordered, className, itemClassName }) => {
    const ListTag = ordered ? 'ol' : 'ul';
  
    return (
      <ListTag className={classNames('flex flex-row space-x-4', className)}>
        {items.map((item, index) => (
          <li key={index} className={classNames(itemClassName)}>
            {item}
          </li>
        ))}
      </ListTag>
    );
  };
  
  HorizontalListWithItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.node).isRequired,
    ordered: PropTypes.bool,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
  };
  
  HorizontalListWithItems.defaultProps = {
    ordered: false,
    className: '',
    itemClassName: '',
  };
  
  // Horizontal List Component using children
  const HorizontalListWithChildren = ({ children, ordered, className }) => {
    const ListTag = ordered ? 'ol' : 'ul';
  
    return (
      <ListTag className={classNames('flex flex-row space-x-4', className)}>
        {React.Children.map(children, (child, index) => (
          <li key={index}>
            {child}
          </li>
        ))}
      </ListTag>
    );
  };
  
  HorizontalListWithChildren.propTypes = {
    children: PropTypes.node.isRequired,
    ordered: PropTypes.bool,
    className: PropTypes.string,
  };
  
  HorizontalListWithChildren.defaultProps = {
    ordered: false,
    className: '',
  };

export { ListWithItems, ListWithChildren, HorizontalListWithChildren, HorizontalListWithItems };


