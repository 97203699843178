import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './legacy/App';
import AribaApp from './version_ariba/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
const useNewVersion = true; // Toggle this flag to switch between versions
root.render(
  <React.StrictMode>
    <Router>
    {useNewVersion ? <AribaApp /> : <App />}     
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
