import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { HiTrash as TrashIcon, HiChevronLeft as ChevronLeftIcon, HiChevronRight as ChevronRightIcon, HiPencil as PencilIcon } from 'react-icons/hi2';


//import useRecruiterStore from '../../../state/recruiterState';
import Tabs from "../../../components/Tabs/Tabs";

//import useAdminStore from "../../../state/adminState";

import {
    updateAssessment, deleteAssessment,
    createAssessmentSkill, updateAssessmentSkill, deleteAssessmentSkill,
    createAssessmentRequirement, deleteAssessmentRequirement,
    updateAssessmentRequirement, updateAssessmentQuestion,
    createAssessmentQuestion,
    deleteAssessmentQuestion,
    createAssessmentPsychQuestion,
    deleteAssessmentPsychQuestion
} from '../../../services/recruiterService';

import Link from "../../../components/Link/Link";
import { H4, P } from "../../../components/Typography/Typography";
import ProgressBar from "../../../components/ProgressBar/progressBar";
import Placeholder from "../../../components/Placeholder/placeholder";
import useRecruiterStore from "../../../store/recruiterStore";
import useGlobalStore from "../../../store/globalStore";
import Button from "../../../components/Buttons/Buttons";
import { Input, Select, Textbox } from "../../../components/Form/Form";
import Beautifier from "../../../../legacy/components/common/beautifier";
import useAdminStore from "../../../store/adminStore";


function AssessmentPage() {
    let { id } = useParams();
    const [currentTab, setCurrentTab] = useState('Requirements');
    const [editingAssessment, setEditingAssessment] = useState(null);
    const [showQuestionEditor, setShowQuestionEditor] = useState(false);
    const [showRequirementEditor, setShowRequirementEditor] = useState(false);
    const navigate = useNavigate();
    const {
        findItemById, editAssessment, removeAssessment, canDeleteAssessment,
        removeAssessmentSkill, editAssessmentSkill, addNewAssessmentSkill, canDeleteAssessmentSkill,
        findJobsWithAssessmentId, editingAssessmentSkill, setEditingAssessmentSkill, countAssessmentJobs,
        findAssessmentSkillsByAssessmentId, assessmentQuestions, editingAssessmentRequirement, editingAssessmentQuestion,
        addNewAssessmentRequirements, addNewAssessmentQuestions, assessmentRequirements, editAssessmentRequirement,
        setEditingAssessmentRequirement, removeAssessmentRequirement, removeAssessmentQuestion, setEditingAssessmentQuestion,
        editAssessmentQuestion, findAssessmentQuestionsByAssessmentId, findAssessmentRequirementsByAssessmentId, psychQuestions
    } = useRecruiterStore(state => state);
    //const { psychQuestions } = useAdminStore(state => state);
    const linkedJobs = findJobsWithAssessmentId({ id });
    const {
        appSkills, setNotificationStatus, setNotification, findSkillById, setAppNotificationWithTimeout
    } = useGlobalStore(state => state);

    const assessmentItem = findItemById(id, 'assessments');
    const assessmentRequiredSkills = findAssessmentRequirementsByAssessmentId(id);
    const assessmentQuestionList = findAssessmentQuestionsByAssessmentId(id);

    // Assessment form
    const { register: register3, handleSubmit: handleSubmit3, setValue: setValue3, reset: reset3, formState: { errors: errors3, isValid: isValid3 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: assessmentItem
    });

    // Assessment requirement form
    const { register: register4, handleSubmit: handleSubmit4, setValue: setValue4, reset: reset4, formState: { errors: errors4, isValid: isValid4 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingAssessmentRequirement
    });

    // Assessment requirement form
    const { register: register5, handleSubmit: handleSubmit5, setValue: setValue5, reset: reset5, formState: { errors: errors5, isValid: isValid5 } } = useForm({
        mode: 'onChange', // Enables validation on change
        defaultValues: editingAssessmentQuestion
    });

    const handleClearRequirementForm = () => {
        setEditingAssessmentSkill();
        reset4();
        setShowRequirementEditor(false);
    };

    const handleClearQuestionForm = () => {
        setEditingAssessmentSkill();
        reset5();
        setShowQuestionEditor(false);
    };

    // ASSESSMENT
    // Edit Assessment
    const onEditAssessment = async (data) => {
        console.log("onEditAssessment")
        console.log(data)
        data.id = id;
        const result = await updateAssessment(data);
        if (result.error) {
            setAppNotificationWithTimeout('Error updating assessment.', 'error', 'Error updating assessment', 3000);
            reset3();
        } else {
            //editAssessment.skill_id = data
            editAssessment({ ...editingAssessment, ...data });
            setEditingAssessment(null); // Clear editing state
            setAppNotificationWithTimeout('Assessment updated.', 'success', 'Assessment updated.', 3000);
        }
    };


    // ASSESSMENT SKILL
    // Add Skill
    const onAddAssessmentRequirement = async (data) => {
        const skill_select = appSkills.find(x => x.id === data.skill_id);
        data.assessment_id = id;
        console.log(data)
        const response = await createAssessmentRequirement(data);
        if (response.error) {
            setAppNotificationWithTimeout('Adding Assessment requirement failed', 'error', 'Adding Assessment requirement failed', 3000);
        } else {
            //console.log(response)
            response.data.name = skill_select.name;
            addNewAssessmentRequirements(response.data);
            setAppNotificationWithTimeout('Assessment requirement added', 'success', 'Assessment updated', 3000);
            setShowRequirementEditor(false);
            reset4();
        }
    };

    const onAddAssessmentQuestion = async (data) => {
        const skill_select = appSkills.find(x => x.id === data.skill_id);
        data.assessment_id = id;
        console.log(data)
        const response = await createAssessmentQuestion(data);
        if (response.error) {
            setAppNotificationWithTimeout('Adding Assessment question failed', 'error', 'Adding Assessment question failed', 3000);
        } else {
            if(!response.success) {
                setAppNotificationWithTimeout('Adding Assessment question not successful.', 'error', 'Adding Assessment question not successful.', 3000);
            } else {
                //console.log(response)
                addNewAssessmentQuestions(response.data);
                setAppNotificationWithTimeout('Assessment question added', 'success', 'Assessmentquestion added', 3000);
                setShowQuestionEditor(false);
                reset5();                
            }

        }
    };

    // Editing Skill
    const startEditingAssessmentRequirement = (assessmentRequirement) => {
        if (canDeleteAssessmentSkill(assessmentRequirement)) {
            setShowRequirementEditor(true);
            //console.log(assessmentSkill)
            setEditingAssessmentRequirement(assessmentRequirement);
            setValue4('assessment_id', assessmentRequirement.assessment_id, { shouldValidate: true });
            setValue4('skill_id', assessmentRequirement.skill_id, { shouldValidate: true });
            setValue4('min_score', assessmentRequirement.min_score, { shouldValidate: true });
            setValue4('max_score', assessmentRequirement.max_score, { shouldValidate: true });
        } else {
            //console.log("Assessment still is associated with a job.");
        }
    };

    // Editing Question
    const startEditingAssessmentQuestion = (assessmentQuestion) => {
        if (canDeleteAssessmentSkill(assessmentQuestion)) {
            setShowQuestionEditor(true);
            //console.log(assessmentSkill)
            setEditingAssessmentQuestion(assessmentQuestion);
            setValue5('question', assessmentQuestion.question, { shouldValidate: true });
            setValue5('assessment_id', assessmentQuestion.assessment_id, { shouldValidate: true });

        } else {
            //console.log("Assessment still is associated with a job.");
        }
    };
    // Uppate Skill
    const onEditAssessmentRequirement = async (data) => {
        data.id = editingAssessmentRequirement.id;
        console.log(data.skill_id);
        const skilldata = findSkillById({ id: data.skill_id })
        console.log(skilldata);
        data.name = skilldata[0].name;
        data.skill_description = skilldata[0].description;
        console.log(data)
        const result = await updateAssessmentRequirement(data);
        if (result.error) {
            setAppNotificationWithTimeout('Failed to update assessment requirement', 'error', 'Assessment Requirement not updated', 3000);
        } else {
            editAssessmentRequirement({ ...editingAssessmentRequirement, ...data });
            setEditingAssessmentRequirement(null); // Clear editing state
            setAppNotificationWithTimeout('Assessment requirement updated', 'success', 'Assessment Requirement updated', 3000);
            reset4();
            setShowRequirementEditor(false);
        }
    };

    // Update Question
    const onEditAssessmentQuestion = async (data) => {
        data.id = editingAssessmentQuestion.id;
        console.log(data);
        const result = await updateAssessmentQuestion(data);
        if (result.error) {
            setAppNotificationWithTimeout('Failed to update assessment question', 'error', 'Assessment Question not updated', 3000);
        } else {
            editAssessmentQuestion({ ...editingAssessmentQuestion, ...data });
            setEditingAssessmentQuestion(null); // Clear editing state
            setAppNotificationWithTimeout('Assessment question updated', 'success', 'Assessment Question updated', 3000);
            reset5();
            setShowQuestionEditor(false);
        }
    };

    // Delete Skill
    const deleteRequirement = async (data) => {
        console.log(data)
        if (canDeleteAssessmentSkill(data.id)) {
            const result = await deleteAssessmentRequirement({ id: data.id });
            //console.log(result);
            if (result.error) {
                setAppNotificationWithTimeout('Failed to delete assessment requirement', 'error', 'Assessment Requirement not deleted', 3000);
            } else {
                removeAssessmentRequirement(data.id);
                setAppNotificationWithTimeout('Assessment requirement deleted', 'success', 'Assessment requirement deleted', 3000);
            }
        } else {
            //appSkills("Assessment still is associated with a job.")
        }
    }

    // Delete Skill
    const deleteQuestion = async (data) => {
        console.log(data)
        if (canDeleteAssessmentSkill(data.id)) {
            const result = await deleteAssessmentQuestion({ id: data.id });
            //console.log(result);
            if (result.error) {
                setAppNotificationWithTimeout('Failed to delete assessment question', 'error', 'Assessment question not deleted', 3000);
            } else {
                removeAssessmentQuestion(data.id);
                setAppNotificationWithTimeout('Assessment question deleted', 'success', 'Assessment question deleted', 3000);
            }
        } else {
            //appSkills("Assessment still is associated with a job.")
        }
    }

    const onDeleteAssessment = async () => {
        if (canDeleteAssessment(assessmentItem.id)) {
            const result = await deleteAssessment({ id: assessmentItem.id });
            if (result.error) {
                setAppNotificationWithTimeout('Deleting assessment failed.', 'error', 'Deleting assessment failed.', 3000);
            } else {
                removeAssessment(assessmentItem.id);
                setAppNotificationWithTimeout('Assessment deleted.', 'success', 'Assessment deleted.', 3000);
                navigate('/recruiter/assessments');
            }
        }
    };

    // Define the API function here
    const handlePsychQuestion = async (questionId, action) => {
        try {
            // if(action==="add") {
            //     const response = await createAssessmentPsychQuestion({id: questionId, question: "psych", assessment_id});
            //     return response;
            // } else {
            //     const response = await deleteAssessmentPsychQuestion(question_id);
            //     return response;
            // }
            // Replace with the actual API call logic
            const response = await fetch(`/api/questions/${action}`, {
                method: "POST",
                body: JSON.stringify({ questionId }),
                headers: { "Content-Type": "application/json" },
            });
            const data = await response.json();
            return { status: data.status, data: data.data, error: data.error };
        } catch (error) {
            return { status: "error", error: error.message };
        }
    };

    const tabs = [
        { name: 'Requirements', href: '#', current: currentTab === 'Requirements' },
        { name: 'Jobs', href: '#', current: currentTab === 'Jobs' },
        { name: 'Settings', href: '#', current: currentTab === 'Settings' },
        // { name: 'Details', href: '#', current: currentTab === 'Details' },
    ];



    if (!assessmentItem) {
        return <div>
            Loading
        </div>
    }

    const assessmentSkillsLength = findAssessmentSkillsByAssessmentId(assessmentItem.id).length;

    return <div className="px-4 sm:px-6 lg:px-8 card">
        <div>
            <div>
                <nav className="sm:hidden" aria-label="Back">
                    <Link to="/recruiter/assessments" className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
                        <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        Back
                    </Link>
                </nav>
                <nav className="hidden sm:flex" aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-4">
                        <li>
                            <div className="flex">
                                <Link to="/recruiter/dashboard" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                    Dashboard
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <Link to="/recruiter/assessments" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                    Assessments
                                </Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <Link to={`/recruiter/assessments/${assessmentItem.id}`} aria-current="page" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                    {assessmentItem.name}
                                </Link>
                            </div>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className="mt-2 md:flex md:items-center md:justify-between">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        {assessmentItem.name}

                    </h2>
                </div>
                {/* <div className="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                    <Button
                        variant={"primary"}
                        type="button"
                        className="inline-flex items-center"

                        disabled={!canDeleteAssessment(assessmentItem.id)}
                    >
                        Edit
                    </Button>
                    <Button
                        variant={"tertiary"}
                        type="button"
                        className="ml-3 inline-flex items-center"
                        disabled={!canDeleteAssessment(assessmentItem.id)}
                    >
                        Delete
                    </Button>
                </div> */}
            </div>
        </div>
        <Tabs tabs={tabs} onTabChange={setCurrentTab} />

        {
            currentTab === 'Requirements' &&
            <>

                <PsychometricQuestionsList 
                    assessmentId={id}
                    questions={psychQuestions}
                    apiAction={handlePsychQuestion} // Pass the API function here
                    jobDeletable={countAssessmentJobs(id)}
                />


                <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Skill
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Min Score
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Max Score
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {assessmentRequiredSkills ? assessmentRequiredSkills.map((skill, skillIdx) => (
                                <tr key={skill.id}>
                                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {skill.name}
                                        <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                                            <span>
                                                Min: {skill.min_score}, Max: {skill.max_score}
                                            </span>
                                            <span>{skill.assessment_name} - {skill.assessment_description}</span>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                                        {skill.min_score}
                                    </td>
                                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                                        {skill.max_score}
                                    </td>
                                    <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-white disabled:text-gray-500 disabled:ring-gray-200 px-2.5 py-1.5 disabled:cursor-not-allowed text-sm font-semibold text-gray-900 shadow-sm  ring-inset ring-gray-300 hover:bg-gray-50"
                                            onClick={() => startEditingAssessmentRequirement(skill)}
                                            disabled={countAssessmentJobs(id)}
                                        >
                                            <PencilIcon className={countAssessmentJobs(id) ? `h-5 w-5 text-gray-300` : `h-5 w-5 text-gray-500`} aria-hidden="true" />
                                            <span className="sr-only">Edit {skill.skill_name}</span>
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 disabled:cursor-not-allowed text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ml-2"
                                            onClick={() => deleteRequirement(skill)}
                                            disabled={countAssessmentJobs(id)}
                                        >
                                            <TrashIcon className={countAssessmentJobs(id) ? `h-5 w-5 text-gray-300` : `h-5 w-5 text-gray-500`} aria-hidden="true" />
                                            <span className="sr-only">Delete {skill.skill_name}</span>
                                        </button>
                                    </td>
                                </tr>
                            )) : <Placeholder>No assessment requirements yet.</Placeholder>}
                        </tbody>
                    </table>
                </div>

                {
                    !countAssessmentJobs(id)
                        ?
                        showRequirementEditor
                            ? <form onSubmit={handleSubmit4(editingAssessmentRequirement ? onEditAssessmentRequirement : onAddAssessmentRequirement)} className="space-y-8 bg-white p-8 rounded-lg ">
                                <Select
                                    label="Skill"
                                    id="skill_id"
                                    register={register4}
                                    required={true}
                                    errors={errors4}
                                    options={appSkills}
                                />
                                <Input
                                    label="Minimum Score"
                                    id="min_score"
                                    register={register4}
                                    required={true}
                                    errors={errors4}
                                    type="number"
                                    min={0}
                                    max={100}
                                />
                                <Input
                                    label="Maximum Score"
                                    id="max_score"
                                    register={register4}
                                    required={true}
                                    errors={errors4}
                                    type="number"
                                    min={0}
                                    max={100}
                                />

                                <div className="flex space-x-4">
                                    <Button
                                        variant={"secondary"}
                                        type="button"
                                        className="inline-flex items-center"
                                        onClick={handleClearRequirementForm}
                                        disabled={!canDeleteAssessment(assessmentItem.id)}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant={"primary"}
                                        type="submit"
                                        className="ml-3 inline-flex items-center"
                                        disabled={!isValid4}
                                    >
                                        {editingAssessmentRequirement ? 'Update Requirement' : 'Add Requirement'}
                                    </Button>

                                </div>
                            </form>
                            : <Button
                                type="button"
                                variant={"secondary"}
                                className=""
                                onClick={() => setShowRequirementEditor(true)}>
                                Add Skill to Assessment
                            </Button>
                        : <div className="text-center text-gray-500">
                            <P className={"text-gray-500"}>This assessment is being used in a job project and can't be edited until the job is complete.</P>
                        </div>
                }
                {/* <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Question
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Delete</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {assessmentQuestionList ? assessmentQuestionList.map((question, skillIdx) => (
                                <tr key={question.id}>
                                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {question.question_third_person}
                                    </td>

                                    <td className="py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-white disabled:text-gray-500 disabled:ring-gray-200 px-2.5 py-1.5 disabled:cursor-not-allowed text-sm font-semibold text-gray-900 shadow-sm  ring-inset ring-gray-300 hover:bg-gray-50"
                                            onClick={() => startEditingAssessmentQuestion(question)}
                                            disabled={countAssessmentJobs(id)}
                                        >
                                            <PencilIcon className={countAssessmentJobs(id) ? `h-5 w-5 text-gray-300` : `h-5 w-5 text-gray-500`} aria-hidden="true" />
                                            <span className="sr-only">Edit Question</span>
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 disabled:cursor-not-allowed text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ml-2"
                                            onClick={() => deleteQuestion(question)}
                                            disabled={countAssessmentJobs(id)}
                                        >
                                            <TrashIcon className={countAssessmentJobs(id) ? `h-5 w-5 text-gray-300` : `h-5 w-5 text-gray-500`} aria-hidden="true" />
                                            <span className="sr-only">Delete Question</span>
                                        </button>
                                    </td>
                                </tr>
                            )) : <Placeholder>No assessment questions yet.</Placeholder>}
                        </tbody>
                    </table>
                </div> */}
                {/* {
                    !countAssessmentJobs(id)
                        ?
                        showQuestionEditor
                            ? <form onSubmit={handleSubmit5(editingAssessmentQuestion ? onEditAssessmentQuestion : onAddAssessmentQuestion)} className="space-y-8 bg-white p-8 rounded-lg ">
                                <Input
                                    label="Question"
                                    id="question"
                                    register={register5}
                                    required={true}
                                    errors={errors5}
                                    type="text"
                                />

                                <div className="flex space-x-4">
                                    <Button
                                        variant={"secondary"}
                                        type="button"
                                        className="inline-flex items-center"
                                        onClick={handleClearQuestionForm}
                                        disabled={!canDeleteAssessment(assessmentItem.id)}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant={"primary"}
                                        type="submit"
                                        className="ml-3 inline-flex items-center"
                                        disabled={!isValid5}
                                    >
                                        {editingAssessmentQuestion ? 'Update Question' : 'Add Question'}
                                    </Button>

                                </div>
                            </form>
                            : <Button
                                type="button"
                                variant={"secondary"}
                                className=""
                                onClick={() => setShowQuestionEditor(true)}>
                                Add Question to Assessment
                            </Button>
                        : null
                } */}
            </>
        }
        {
            currentTab === 'Jobs' &&
            <>
                <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Job
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Company
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Location
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {linkedJobs.length > 0 ? linkedJobs.map((job, skillIdx) => (
                                <tr key={job.id}>
                                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                        {job.name}
                                        <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                                            <span>
                                                {job.company_name}
                                            </span>
                                            <span>
                                                {job.location}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                                        {job.company_name}
                                    </td>
                                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                                        {job.location}
                                    </td>
                                </tr>
                            )) : <tr className="col-auto">
                                <td><Placeholder>No jobs linked with assessment.</Placeholder></td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </>
        }
        {
            currentTab === 'Settings' &&
            <div className="p-8">
                <form onSubmit={handleSubmit3(onEditAssessment)} className="space-y-8 bg-white rounded-lg">
                    <Input
                        label="Assessment name"
                        id="name"
                        register={register3}
                        required={true}
                        errors={errors3}
                        type="text"
                    />

                    <Textbox
                        label="Assessment description"
                        id="description"
                        register={register3}
                        required={true}
                        errors={errors3}
                    />
                    <div>
                        <Button
                            variant="primary"
                            size="medium"
                            className={"w-full"}
                            type="submit"
                            disabled={linkedJobs.length > 0 || !isValid3}
                        >
                            Update Assessment
                        </Button>
                    </div>
                </form>
                <div className="mt-8">
                    <H4>Delete Assessment</H4>
                    <div className="my-4 mx-auto">
                        <ProgressBar steps={
                            [
                                {
                                    name: 'No linked jobs',
                                    description: 'No jobs are linked to the assessment.',
                                    href: '#',
                                    status: linkedJobs.length > 0 ? 'upcoming' : 'complete'
                                },
                                {
                                    name: 'No skill requirements',
                                    description: 'You have deleted all skill requirements for this assessment.',
                                    href: '#',
                                    status: assessmentRequiredSkills.length > 0 ? 'upcoming' : 'complete',
                                },
                                {
                                    name: 'No questions',
                                    description: 'You have deleted all questions for this assessment.',
                                    href: '#',
                                    status: assessmentQuestionList.length > 0 ? 'upcoming' : 'complete',
                                }
                            ]

                        } />
                    </div>

                    <div>
                        <Button
                            type="button"
                            variant="caution"
                            onClick={() => onDeleteAssessment()}
                            className="w-full"
                            disabled={!canDeleteAssessment(assessmentItem.id)}
                        >
                            Delete Assessment
                        </Button>
                    </div>
                </div>
            </div>
        }
    </div>

}

const PsychometricQuestionsList = ({ assessmentId, jobDeletable }) => {
    const { psychQuestions, assessmentQuestions, addNewAssessmentQuestions, removeAssessmentQuestion, canDeleteAssessmentSkill } = useRecruiterStore();
    const {
        setAppNotificationWithTimeout
    } = useGlobalStore(state => state);
    const [questionFilter, setQuestionFilter] = useState("");
    const [traitFilter, setTraitFilter] = useState("");

    // Check if a question is already in the assessmentQuestions for this assessment
    const isQuestionInAssessment = (psychQuestionId) =>
        assessmentQuestions.some(
            (q) => q.psych_question_id === psychQuestionId && q.assessment_id === assessmentId
        );

    // Handle add question
    const handleAddQuestion = async (psychQuestion) => {
        const newAssessmentQuestion = {
            //id: "generateUniqueId()",
            assessment_id: assessmentId,
            psych_question_id: psychQuestion.id,
            question: 'psych',
            question_first_person: psychQuestion.question_first_person,
            question_third_person: psychQuestion.question_third_person,
            trait1: psychQuestion.trait1,
            trait2: psychQuestion.trait2,
            trait3: psychQuestion.trait3,
            trait1_name: psychQuestion.trait1_name,
            trait1_description: psychQuestion.trait1_description,
            trait2_name: psychQuestion.trait2_name,
            trait2_description: psychQuestion.trait2_description,
            trait3_name: psychQuestion.trait3_name,
            trait3_description: psychQuestion.trait3_description,
        };
    
        const response = await createAssessmentQuestion(newAssessmentQuestion);
        if (response.error) {
            setAppNotificationWithTimeout(
                'Adding Assessment question failed',
                'error',
                'Adding Assessment question failed',
                3000
            );
        } else {
            if(!response.success) {
                setAppNotificationWithTimeout(
                    'Adding Assessment question not successful',
                    'error',
                    'Adding Assessment question not successful',
                    3000
                );
            } else {
                // Set the new ID from response to newAssessmentQuestion
                newAssessmentQuestion.id = response.data.id;
                
                // Add updated question to the Zustand store
                addNewAssessmentQuestions(newAssessmentQuestion);
        
                // Show success notification and close editor
                setAppNotificationWithTimeout(
                    'Assessment question added',
                    'success',
                    'Assessment question added',
                    3000
                );                
            }

        }
    };

    // Handle remove question
    const handleRemoveQuestion = async(psychQuestionId) => {
        const questionId = useRecruiterStore.getState().findAssessmentQuestionId(psychQuestionId, assessmentId);
        if (questionId) {
            if (canDeleteAssessmentSkill(questionId)) {
                const result = await deleteAssessmentQuestion({ id: questionId });
                //console.log(result);
                if (result.error) {
                    setAppNotificationWithTimeout('Failed to delete assessment question', 'error', 'Assessment question not deleted', 3000);
                } else {
                    removeAssessmentQuestion(questionId);
                    setAppNotificationWithTimeout('Assessment question deleted', 'success', 'Assessment question deleted', 3000);
                }
            } else {
                //appSkills("Assessment still is associated with a job.")
            }            
        }
    };

    // Calculate trait summary for all questions already in assessment
    const selectedTraits = assessmentQuestions.reduce((acc, question) => {
        if (question.assessment_id === assessmentId && question.psych_question_id) {
            [question.trait1_name, question.trait2_name, question.trait3_name].forEach((trait) => {
                if (trait) {
                    acc[trait] = (acc[trait] || 0) + 1;
                }
            });
        }
        return acc;
    }, {});

    // Filter questions by search terms
    const filteredQuestions = psychQuestions.filter((question) => {
        const matchesQuestion = question.question_third_person
            .toLowerCase()
            .includes(questionFilter.toLowerCase());
        const matchesTrait = !traitFilter || 
            [question.trait1_name, question.trait2_name, question.trait3_name]
                .filter(Boolean)
                .some((trait) => trait.toLowerCase().includes(traitFilter.toLowerCase()));
        return matchesQuestion && matchesTrait;
    });

    return (
        <div className="p-4">
            <h2 className="text-xl font-semibold mb-4">Psychometric Questions</h2>
            {/* Search Inputs */}
            <div className="mb-4 flex space-x-4">
                <input
                    type="text"
                    placeholder="Search by question..."
                    value={questionFilter}
                    onChange={(e) => setQuestionFilter(e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1"
                />
                <input
                    type="text"
                    placeholder="Search by trait..."
                    value={traitFilter}
                    onChange={(e) => setTraitFilter(e.target.value)}
                    className="border border-gray-300 rounded px-2 py-1"
                />
            </div>

            {/* Selected Traits Summary */}
            <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Selected Traits Summary</h3>
                {Object.keys(selectedTraits).length > 0 ? (
                    <div className="flex flex-wrap space-x-2">
                        {Object.entries(selectedTraits).map(([trait, count]) => (
                            <span
                                key={trait}
                                className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"
                            >
                                {trait} ({count})
                            </span>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-500">No traits selected yet.</p>
                )}
            </div>

            {/* Responsive Table */}
            <div className="overflow-x-auto">
                <table className="min-w-full border border-gray-200 rounded-lg shadow-sm bg-white">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Question</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Traits</th>
                            <th className="px-4 py-2 text-left text-sm font-medium text-gray-700">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredQuestions.map((question) => {
                            const inAssessment = isQuestionInAssessment(question.id);
                            return (
                                <tr
                                    key={question.id}
                                    className={`border-b hover:bg-gray-50 ${inAssessment ? 'bg-green-100' : 'bg-white'}`}
                                >
                                    <td className="px-4 py-2 text-sm text-gray-800">
                                        {question.question_third_person}
                                    </td>
                                    <td className="px-4 py-2 text-sm">
                                        <div className="flex flex-wrap space-x-1">
                                            {[question.trait1_name, question.trait2_name, question.trait3_name]
                                                .filter(Boolean)
                                                .map((trait) => (
                                                    <span
                                                        key={trait}
                                                        className="inline-block bg-gray-200 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded"
                                                    >
                                                        {trait}
                                                    </span>
                                                ))}
                                        </div>
                                    </td>
                                    <td className="px-4 py-2 text-sm">
                                        <button
                                            disabled={jobDeletable}
                                            onClick={() =>
                                                inAssessment
                                                    ? handleRemoveQuestion(question.id)
                                                    : handleAddQuestion(question)
                                            }
                                            className={`px-4 py-2 rounded ${
                                                inAssessment ? 'bg-red-500' : 'bg-blue-500'
                                            } text-white disabled:bg-gray-500 disabled:text-gray-300`}
                                        >
                                            {inAssessment ? 'Remove' : 'Add'}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};



export default AssessmentPage;