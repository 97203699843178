import Link from '../../../components/Link/Link';

import Badge from '../../../components/Badge/Badge';
import useCandidateStore from '../../../store/candidateStore';
import { H3 } from '../../../components/Typography/Typography';
import useExperimentStore from '../../../store/experimentStore';
import LinkButton from '../../../components/Link/LinkButton';
import Beautifier from '../../../../legacy/components/common/beautifier';

const data = [
    {
        "id": 1,
        "user_id": 123,
        "resume_url": "https://example.com/resume/john.doe.pdf", // Example resume URL
        "cover_letter": "I am a highly motivated...", // Example cover letter
        "created_at": "2024-08-01T00:00:00Z", // Example creation date (UTC)
        "updated_at": "2024-08-20T10:15:00Z", // Example update date (UTC)
        "job_id": 789,
        "job_name": "Software Engineer", // Duplicate job_name removed
        "location": "San Francisco, CA",
        "description": "Looking for a talented developer...",
        "company_name": "Acme Corp",
        "assessment_id": 12,
        "assessment_name": "Programming Skills Assessment",
        "email": "johndoe@example.com",
        "first_name": "John",
        "last_name": "Doe",
        "expiration_date": "2024-09-30" // Example expiration date
    }
]
const SkillItem = ({ candidate, onDelete, onEdit, job }) => {
    const expiration_date = new Date(candidate.expiration_date).toLocaleString('en-US', {
        dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
        timeStyle: 'short' // Adjust the time style as needed (e.g., 'full', 'medium', 'long')
    });
    let status = candidate.status;
    return (
        <tr key={candidate.candidate_id}>
            {/* <Beautifier value={candidate}/> */}
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {candidate.recruiter_first_name} {candidate.recruiter_last_name}
                <div className="mt-1 flex flex-col text-gray-500">
                    <span>{candidate.recruiter_email}</span>
                </div>
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span><b>Job:</b> {candidate.job_name}</span>
                    <span><b>Assessment:</b> {candidate.assessment_name}</span>
                    <span>
                        {
                            status === 'pending'
                                ? `Deadline: ${expiration_date}`
                                : null
                        }
                    </span>
                    <span><b>Status:</b> <Badge
                        text={status === "pending" ? "pending" : status === "expired" ? "expired" : "complete"}
                        color={status === "pending" ? "blue" : status === "expired" ? "red" : "green"}
                    /></span>
                    <LinkButton
                        variant="primary"
                        to={`/candidate/assessments/${candidate.id}`}
                        size="medium"
                        className={"mt-3"}
                    >
                        Start now
                    </LinkButton>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <div className="mt-1 flex flex-col text-gray-500">
                    <span className='text-sm font-medium text-gray-900'>{candidate.job_name}</span>
                    <span>Company: {candidate.company_name}</span>
                    <span>Test: {candidate.assessment_name}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <div className="mt-1 flex flex-col text-gray-500">
                    <span>{expiration_date}</span>
                    <span>
                        <Badge
                            text={status === "pending" ? "pending" : status === "expired" ? "expired" : "complete"}
                            color={status === "pending" ? "blue" : status === "expired" ? "red" : "green"}
                        />
                    </span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <div className="mt-1 flex flex-col text-gray-500">
                    <LinkButton
                        variant="primary"
                        to={`/candidate/assessments/${candidate.id}`}
                        size="medium"
                    >
                        Start now
                    </LinkButton>
                </div>
            </td>

        </tr>
    );
};

export default function RecruiterInvitations({ candidates, onDelete, onEdit, jobItem }) {
    const {
        recruiterInvitations
    } = useExperimentStore(state => state);
    return (
        <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <H3>Recruiter Invitations</H3>
            {
                recruiterInvitations.length === 0
                    ? <div className="text-center py-20 min-h-40">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No Assessment Invitations</h3>
                        <p className="mt-1 text-sm text-gray-500">Once you received an invitation, we will notify you.</p>
                    </div>
                    : <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Recruiter
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Position / Test
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Deadline
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Status
                                </th>

                            </tr>
                        </thead>
                        <tbody className='divider divide-y divide-x'>
                            {recruiterInvitations.map((recruiterInvitation, index) => (
                                <>
                                    <SkillItem
                                        key={recruiterInvitation.id}
                                        candidate={recruiterInvitation}
                                        onDelete={onDelete}
                                        onEdit={onEdit}
                                    />
                                </>
                            ))}
                        </tbody>
                    </table>
            }
            <ul>
                <li>
                    <Badge
                        text={"pending"}
                        color={"blue"}
                    />
                    It's your turn. Invite References to assess you.
                </li>
                <li>
                    <Badge
                        text={"expired"}
                        color={"red"}
                    />
                    You and your references didn't complete the assessment in time.
                </li>
                <li>
                    <Badge
                        text={"complete"}
                        color={"green"}
                    />
                    The assessment was completed by you and your references in time.
                </li>
            </ul>


        </div>
    );
};