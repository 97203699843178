import {create} from 'zustand';

const useCandidateStore = create((set) => ({
    user: null,
    candidateSkills: [],
    workHistory: [],
    references: [],
    assessmentInvitations: [],
    referenceInvitations: [],
    receivedInvitations: [],

    
  setUser: (userData) => set({ user: userData }),
  addCandidateSkills: (newCandidateSkills) => set((state) => ({
    candidateSkills: [
      ...state.candidateSkills,
      ...newCandidateSkills.filter(
        newCandidateSkill => !state.candidateSkills.some(
          candidateSkill => candidateSkill.id === newCandidateSkill.id
        )
      )
    ]
  })),

  addNewCandidateSkill: (newCandidateSkill) => set((state) => ({
    candidateSkills: [...state.candidateSkills, newCandidateSkill]
  })),

  removeCandidateSkill: (deletableCandidateSkill) => set((state) => ({
    candidateSkills: state.candidateSkills.filter(candidateSkill => candidateSkill.id !== deletableCandidateSkill)
  })),



  // Work History
  addWorkHistory: (newWorkHistories) => set((state) => ({
    workHistory: [
      ...state.workHistory,
      ...newWorkHistories.filter(
        newWorkHistories => !state.workHistory.some(
          historyEntry => historyEntry.id === newWorkHistories.id
        )
      )
    ]
  })),

  addNewWorkHistory: (newWorkHistory) => set((state) => ({
    workHistory: [...state.workHistory, newWorkHistory]
  })),

  editWorkHistory: (editedWorkHistory) => set((state) => ({
    workHistory: state.workHistory.map(workHistoryEntry =>
        workHistoryEntry.id === editedWorkHistory.id ? editedWorkHistory : workHistoryEntry
    )
  })),

  removeWorkHistory: (deletableCandidateSkill) => set((state) => ({
    workHistory: state.workHistory.filter(historyEntry => historyEntry.id !== deletableCandidateSkill)
  })),

  //References
  addReferences: (newReferences) => set((state) => ({
    references: [
      ...state.references,
      ...newReferences.filter(
        newReferences => !state.references.some(
          reference => reference.id === newReferences.id
        )
      )
    ]
  })),

  addNewReference: (newReference) => set((state) => ({
    references: [...state.references, newReference]
  })),

  editReference: (editedReference) => set((state) => ({
    references: state.references.map(reference =>
      reference.id === editedReference.id ? editedReference : reference
    )
  })),

  removeReference: (deletableReference) => set((state) => ({
    references: state.references.filter(reference => reference.id !== deletableReference)
  })),

  //Assessment Invitations
  addAssessmentInvitations: (newInvitations) => set((state) => ({
    assessmentInvitations: [
      ...state.assessmentInvitations,
      ...newInvitations.filter(
        newInvitations => !state.assessmentInvitations.some(
          invitation => invitation.id === newInvitations.id
        )
      )
    ]
  })),

  // Sent Invitations
  addSentInvitations: (newSentInvitations) => set((state) => ({
    referenceInvitations: [
      ...state.referenceInvitations,
      ...newSentInvitations.filter(
        newSentInvitations => !state.referenceInvitations.some(
          sentInvitationEntry => sentInvitationEntry.id === newSentInvitations.id
        )
      )
    ]
  })),

  addNewSentInvitation: (newSentInvitation) => set((state) => ({
    referenceInvitations: [...state.referenceInvitations, newSentInvitation]
  })),

  checkReferenceExists: (reference_id, assessment_id, email) => {
    const { references } = useCandidateStore.getState();
    return references.some(
      (ref) => ref.reference_id === reference_id && (ref.assessment_id === assessment_id || ref.email === email)
    );
  },

  
  countCandidateAssessmentAppearances: (candidate_assessment_id) => {
    return useCandidateStore.getState().references.filter(
      ref => ref.candidate_assessment_id === candidate_assessment_id
    ).length;
  },

  findItemById: (id, arrayName) => {
    const state = useCandidateStore.getState();
    return state[arrayName]?.find(item => item.id === id) || null;
  },

  findReferenceByWorkHistoryId: (workHistoryId) => {
    const state = useCandidateStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.references.filter(item => item.work_history_id === workHistoryId);
  },

  findWorkHistoryByReferenceId: (reference_id) => {
    const state = useCandidateStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.references.filter(item => item.id === reference_id);
  },

  findSentInvitationsByAssessmentId: (candidate_assessment_id) => {
    const state = useCandidateStore.getState();
    return state.referenceInvitations.filter(item => item.candidate_assessment_id === candidate_assessment_id);
  },

  // Find Invitable Reference Users by Assessment Id
  // findInvitableReferencesByAssessmentId: (candidate_assessment_id) => {
  //   const state = useCandidateStore.getState();
  //   const invitedReferences = state.referenceInvitations.filter(item => item.candidate_assessment_id === candidate_assessment_id);
  //   console.log(invitedReferences);
  //   return state.references.filter(item => item.id !== invitedReferences.reference_id);
  // },

  findInvitableReferencesByAssessmentId: (candidate_assessment_id) => {
    const state = useCandidateStore.getState();
    const invitedReferences = state.referenceInvitations.filter(
      item => item.candidate_assessment_id === candidate_assessment_id
    );
    // Get a list of invited reference IDs
    const invitedReferenceIds = invitedReferences.map(item => item.reference_id);
  
    return state.references.filter(item => !invitedReferenceIds.includes(item.id));
  },


  countSentInvitationsByAssessmentId: (candidate_assessment_id) => {
    return useCandidateStore.getState().referenceInvitations.filter(
      ref => ref.candidate_assessment_id === candidate_assessment_id
    ).length;
  },

    // Received Invitations
    addReceivedInvitations: (newReceivedInvitations = []) => set((state) => ({
      receivedInvitations: Array.isArray(newReceivedInvitations)
          ? [
              ...state.receivedInvitations,
              ...newReceivedInvitations.filter(
                  (newReceivedInvitation) => !state.receivedInvitations.some(
                      (sentInvitationEntry) => sentInvitationEntry.id === newReceivedInvitation.id
                  )
              )
          ]
          : state.receivedInvitations,
  })),
  
 
    addNewReceivedInvitation: (newReceivedInvitations) => set((state) => ({
      receivedInvitations: [...state.receivedInvitations, newReceivedInvitations]
    })),

    updateReceiveInvitation: (editedInvitation) => set((state) => ({
      receivedInvitations: state.receivedInvitations.map(invitation =>
        invitation.id === editedInvitation.id
          ? { ...invitation, status: editedInvitation.status, updated_at: new Date().toISOString() }
          : invitation
      )
    })),

    clearCandidate: () => set({ user: null, candidateSkills: [], workHistory: [], references: [], assessmentInvitations: [], referenceInvitations: [], receivedInvitations: []})
}));


export default useCandidateStore;
