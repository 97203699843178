import { Fragment } from 'react'
import logo from "../../../assets/brand/brand_logo.png";
import useUserStore from '../../../store/userStore';
//import { logout } from '../../../../services/authService';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from '@headlessui/react'
import {
    HiBars3 as Bars3Icon,
    HiBell as BellIcon,
    HiXMark as XMarkIcon,
    HiDocument as Assessment,
    HiHome as Dashboard,
    HiBriefcase as Jobs,
    HiUserGroup as References,
} from 'react-icons/hi2'
import { NavLink, useNavigate } from 'react-router-dom';
import Link from '../../Link/Link';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const navigation = [
    { name: 'Dashboard', href: '/candidate/dashboard', icon: Dashboard, current: true },
    // { name: 'Invitations', href: '/candidate/invitations', icon: Assessment, current: false },
    // { name: 'References', href: '/candidate/references', icon: References, current: false },
    // { name: 'Profile', href: '/candidate/profile', icon: Jobs, current: false },
]

export default function CandidateNavbar() {
    const navigate = useNavigate();
    const { logout } = useUserStore();
    const handleLogout = () => {
        console.log("logout")

        if (logout()) {  // Call the service which now returns true on logout
            console.log("logout")
            //useStore.clearUser();  // Clear user state
            //useCandidateStore.clearCandidate();
            navigate('/login'); // Redirect to login page
        }
    };

    return (
        <Disclosure as="nav" className="bg-secondary-950 text-secondary-50 shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                        <div className="relative flex h-16 justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-100 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-highlight-500">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </DisclosureButton>
                            </div>
                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex flex-shrink-0 items-center">
                                    <Link to="/">
                                        <img
                                            className="h-8 w-auto"
                                            src={logo}
                                            alt="GoSkilling"
                                        />
                                    </Link>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    {navigation ? navigation.map((entry) => (
                                        <NavLink
                                            to={entry.href}
                                            className={isActive =>
                                                (isActive ? " inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-200 hover:border-gray-300 hover:text-highlight-400" : "inline-flex items-center border-b-2 border-highlight-500 px-1 pt-1 text-sm font-medium text-gray-900")
                                            }
                                        //className={`inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900 ${isActive}`}
                                        >
                                            {entry.name}
                                        </NavLink>

                                    )) : null}
                                </div>
                            </div>
                            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">Open user menu</span>
                                            <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                                                <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            </span>
                                        </MenuButton>
                                    </div>
                                    <Transition
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-secondary-950 text-gray-50 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <MenuItem>
                                                {({ focus }) => (
                                                    <a
                                                        href="#"
                                                        className={classNames(focus ? 'bg-gray-500 text-highlight-500' : '', 'block px-4 py-2 text-sm text-gray-300')}
                                                    >
                                                        Your Profile
                                                    </a>
                                                )}
                                            </MenuItem>
                                            <MenuItem>
                                                {({ focus }) => (
                                                    <button
                                                        type="button"
                                                        onClick={() => handleLogout()}
                                                        className={classNames(focus ? 'bg-gray-500 text-highlight-500' : '', 'block px-4 py-2 text-sm text-gray-300')}
                                                    >
                                                        Log out
                                                    </button>
                                                )}
                                            </MenuItem>
                                        </MenuItems>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <DisclosurePanel className="sm:hidden">
                        <div className="space-y-1 pb-4 pt-2">
                            {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            {navigation ? navigation.map((entry) => (
                                <NavLink
                                    as="a"
                                    to={entry.href}
                                    className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                                >
                                    {entry.name}
                                </NavLink>
                            )) : null}

                        </div>
                    </DisclosurePanel>
                </>
            )}
        </Disclosure>
    )
}
