import SectionHeading from '../../../components/SectionHeader/sectionHeader';
import { H4 } from '../../../components/Typography/Typography';
import { createJobAssessment, deleteJobAssessment, updateJob } from '../../../services/recruiterService';
import { useForm } from 'react-hook-form';
import useGlobalStore from '../../../store/globalStore';
import useRecruiterStore from '../../../store/recruiterStore';
import Button from '../../../components/Buttons/Buttons';
import Beautifier from '../../../../legacy/components/common/beautifier';
import { Select } from '../../../components/Form/Form';


const QuestionItem = ({ question, onDelete }) => {
    return (
        <tr key={question.id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {question.question}
            </td>
        </tr>
    );
};

const QuestionList = ({ questions }) => {
    return (
        <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Questiion
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {questions.map((question, skillIdx) => (
                        <QuestionItem key={question.id} question={question} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const SkillItem = ({ skill, onDelete }) => {
    return (
        <tr key={skill.id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {skill.name}
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
                    <span>
                        Min: {skill.min_score}, Max: {skill.max_score}
                    </span>
                    <span>{skill.assessment_name} - {skill.assessment_description}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {skill.min_score}
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {skill.max_score}
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {skill.assessment_name}
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {skill.assessment_description}
            </td>
        </tr>
    );
};

const SkillList = ({ skills }) => {
    return (
        <div className="-mx-4 mt-10 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Skill
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Min Score
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Max Score
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Assessment
                        </th>
                        <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                            Description
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {skills.map((skill, skillIdx) => (
                        <SkillItem key={skill.id} skill={skill} />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const AssessmentInformation = ({ job }) => {
    const {
        setNotificationWithTimeout, setAppNotificationWithTimeout
    } = useGlobalStore(state => state);
    const {
        assessments,
        findItemById,
        findAssessmentSkillsByAssessmentId,
        countAssessmentQuestions,
        countAssessmentRequirements,
        findAssessmentById,
        addAssessmentIdToJob,
        assessmentRequirements,
        assessmentQuestions,
        editJob,
        findAssessmentRequirementsByAssessmentId,
        findAssessmentQuestionsByAssessmentId
    } = useRecruiterStore(state => state);
    const assessmentSkills = findAssessmentSkillsByAssessmentId(job.assessment_id);

    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
    });

    const onAssignAssessment = async (data) => {
        try {
            console.log(data);
            console.log(job)
            job.assessment_id = data.assessment
            console.log(job)
            const assessmentData = findAssessmentById(data.assessment);
            const result = await updateJob(job);
            if(result.success) {
                console.log(assessmentData);
                job.assessment_name = assessmentData[0].name;
                job.assessment_description = assessmentData[0].description;
                job.assessment_id = assessmentData[0].id;
                job.job_assessment_id = result.data.id;
                console.log(job);
                editJob(job);
                setAppNotificationWithTimeout("Assessment assigned", 'success', "Assessment assigned", 3000);                
            } else {
                setAppNotificationWithTimeout("Error assigning assessment", 'error', "Error assigning assessment", 3000);
            }
        } catch (error) {
            setAppNotificationWithTimeout("Error assigning assessment", 'error', "Error assigning assessment", 3000);
        }

        //setNotificationWithTimeout('Assessment assigned to job.','success',3000);

    }

    const unLinkAssessment = async (data) => {
        const originalJob = job;
        const newJob = job;
        try {
            newJob.assessment_name = null;
            newJob.assessment_description = null;
            newJob.assessment_id = null;
            console.log(data);
            console.log("unlink")
            const result = await updateJob(newJob);
            if(result.success) {
                editJob(newJob);
                setAppNotificationWithTimeout("Assessment uassigned", 'success', "Assessment uassigned", 3000);
            } else {
                setAppNotificationWithTimeout("Error unassigning assessment", 'error', "Error unassigning assessment", 3000);
                editJob(originalJob);
            }
        } catch (error) {
            setAppNotificationWithTimeout("Error unassigning assessment", 'error', "Error unassigning assessment", 3000);
        }
    }

    const assessmentRequiredSkills = findAssessmentRequirementsByAssessmentId(job.assessment_id);
    const assessmentQuestionList = findAssessmentQuestionsByAssessmentId(job.assessment_id);

    return <div>
        {
            job.assessment_id && job.assessment_id !== null ?
                <div className='my-4'>
                    <SectionHeading title={job.assessment_name} description={job.assessment_description} />
                    <SkillList skills={assessmentRequiredSkills} />
                    <QuestionList questions={assessmentQuestionList} />
                    <Button className="w-full" onClick={() => unLinkAssessment(job.assessment_id)} disabled={false} type='primary'>Unlink assessment</Button>
                </div>
                : <>
                    <H4>Select Assessment</H4>

                    <form onSubmit={handleSubmit(onAssignAssessment)} className="mt-5 sm:flex sm:items-center">
                        <div className="w-full sm:max-w-lg sm:mr-2">
                            <select
                                id="assessment"
                                {...register('assessment', { required: 'Assessment is required' })}
                                className="block w-full px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-lg"
                            >
                                <option value="">Select an assessment</option>
                                {assessments.map((assessment) => {
                                    const questionCount = countAssessmentQuestions(assessment.id);
                                    const requirementCount = countAssessmentRequirements(assessment.id);
                                    if (requirementCount > 0 && questionCount > 0) {
                                        return <option key={assessment.id} value={assessment.id}>{assessment.name}</option>
                                    } else {
                                        return <p>{questionCount} {requirementCount}</p>
                                    }
                                })}
                            </select>

                        </div>
                        <Button
                            type="submit"
                            variant="primary"
                            size="small"
                            disabled={!isValid}
                            className="inline-flex justify-center"
                        >
                            Assign Assessment
                        </Button>
                        {errors.assessment && <p className="mt-2 text-sm text-red-600">{errors.assessment.message}</p>}
                    </form>
                </>
        }

    </div>
}

export default AssessmentInformation;