import {CardWithFooter} from "../../../components/Cards/CardWithFooter";
import colorSheet from "../../../assets/images/colorsheet.png";
import { H4 } from "../../../components/Typography/Typography";

function Colors() {
    return <div>
        <a href="https://coolors.co/ffffff-131313-0067c8-dea700-13b03d">New Colors</a>
        <H4>Bright Mode</H4>
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {brightColors.map((color) => (
                <li key={color.source} className="relative">
                    <CardWithFooter
                        contentColor={color.color}
                        footerContent={color.title}
                        contentData={""}

                    />
                </li>
            ))}
        </ul>
        <H4>Dark Mode</H4>
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
            {darkColors.map((color) => (
                <li key={color.source} className="relative">
                    <CardWithFooter
                        contentColor={color.color}
                        footerContent={color.title}
                        contentData={""}

                    />
                </li>
            ))}
        </ul>
        <img src={colorSheet} alt="" />
    </div>
}

const brightColors = [
    {
        title: 'Prim 1',
        color:
            'bg-primary1-50',
        hex: '#408ff1'
    },
    {
        title: 'Prim 2',
        color:
            'bg-primary2-950',
        hex: '#6b7280'
    },
    {
        title: 'Accent 0',
        color:
            'bg-accent0-500',
        hex: '#eab308'
    },
    {
        title: 'Accent 1',
        color:
            'bg-accent1-500',
        hex: '#10b981'
    },
    {
        title: 'Accent 2',
        color:
            'bg-accent2-500',
        hex: '#10b981'
    },
]

const darkColors = [
    {
        title: 'Primary 1',
        color:
            'bg-primary1-950',
        hex: '#408ff1'
    },
    {
        title: 'Primary 2',
        color:
            'bg-primary2-50',
        hex: '#6b7280'
    },
    {
        title: 'Accent 0',
        color:
            'bg-accent0-400',
        hex: '#eab308'
    },
    {
        title: 'Accent 1',
        color:
            'bg-accent1-400',
        hex: '#10b981'
    },
    {
        title: 'Accent 2',
        color:
            'bg-accent2-400',
        hex: '#10b981'
    },
]

export default Colors;