import {
  HiTrash as TrashIcon,
} from 'react-icons/hi2';
import Link from '../../../components/Link/Link';
import useRecruiterStore from '../../../store/recruiterStore';
import Button from '../../../components/Buttons/Buttons';
import Badge from '../../../components/Badge/Badge';
import Beautifier from '../../../../legacy/components/common/beautifier';

const CandidateItem = ({ candidate, onDelete, onEdit, job }) => {
  const expiration_date = new Date(candidate.expiration_date).toLocaleString('en-US', {
    dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
    timeStyle: 'short' // Adjust the time style as needed (e.g., 'full', 'medium', 'long')
  });

  return (
    <tr key={candidate.candidate_id}>

      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {candidate.first_name} {candidate.last_name}
        <div className="mt-1 flex flex-col text-gray-500">
          <span>{candidate.email}</span>
        </div>
        <div className="mt-1 flex flex-col text-gray-500 sm:hidden">
          <span>  
           {
            candidate.status !== 'complete' && candidate.status !== 'concluded'
              ? `Status: ${expiration_date}`
              : <Link to={`/recruiter/results/${candidate.job_id}/${candidate.assessment_id}`}>Results</Link>
          }  </span>
          <span>Status: {candidate.status}</span>
        </div>
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
        <Badge 
          text={candidate.status==="pending" ? "pending" : candidate.status==="expired" ? "expired" : "complete"}
          color={candidate.status==="pending" ? "blue" : candidate.status==="expired" ? "red" : "green"}
        />
      </td>
      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
       
        {
          candidate.status !== 'complete' && candidate.status !== 'concluded'
            ? expiration_date
            : <Link to={`/recruiter/results/${candidate.id}/${candidate.assessment_id}`}>Results</Link>
        }
<Link to={`/recruiter/results/${candidate.id}/${candidate.assessment_id}`}>Results</Link>
      </td>
    </tr>
  );
};

const CandidateList = ({ candidates, onDelete, onEdit, jobItem }) => {
  const {
    canDeleteJob,
  } = useRecruiterStore(state => state);
  const jobEmpty = canDeleteJob(jobItem.id);
  return (
    <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>

            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              Status
            </th>
            <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
              {
                jobItem.status !== 'complete' || jobItem.status !== 'concluded'
                  ? "Expiration Date"
                  : "Results"
              }
            </th>
          </tr>
        </thead>
        <tbody className='divider divide-y divide-x'>

          {candidates.length > 0 ? candidates.map((candidate, index) => (
            candidate.job_id === jobItem.id ?
              <>
                <CandidateItem
                  key={candidate.id}
                  candidate={candidate}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  job={jobItem}
                />

              </>
              : null
          )) : (
            <tr>
              <td colSpan="5" className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                No candidates yet
                <Button
                  variant="primary"
                  size="small"
                  //onClick={() => setEntriesPage(entriesPage + 5)}
                  className="block mr-2 w-full"

                >
                  Activate to add candidates
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CandidateList;