import { useParams } from "react-router-dom";
import TwoColumnContainer from "../../../components/Containers/TwoColumn";
import LeftContent from "./1-2-LC";
import RightContent from "./1-2-RC";
import useExperimentStore from "../../../store/experimentStore";


export default function AssessmentDashboard() {
    const {
        findAssessmentById, sentInvitations, userReferences, addNewSentInvitation
    } = useExperimentStore(state => state);
    let { id } = useParams();
    const data = findAssessmentById(id);
    return <div>
        <TwoColumnContainer
            leftContent={<LeftContent data={data} />}
            rightContent={<RightContent data={data} />}
        />
    </div>
}