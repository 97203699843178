import { H1, H2, H3, H4, H5, H6, P, Small } from "../../../components/Typography/Typography";
import './../../../assets/styles/app.css';
function TextSystem() {
    return <div>
        <ul>
            <li><H1 className={"text-accent1-500"}>H1: Small and big headlines are sharing the same importance.</H1></li>
            <li><H2>H2: Small and big headlines are sharing the same importance.</H2></li>
            <li><H3>H3: Small and big headlines are sharing the same importance.</H3></li>
            <li><H4>H4: Small and big headlines are sharing the same importance.</H4></li>
            <li><H5>H5: Small and big headlines are sharing the same importance.</H5></li>
            <li><H6>H6: Small and big headlines are sharing the same importance.</H6></li>
            <li><P>P: Small and big headlines are sharing the same importance.</P></li>
            <li><Small>Small: Small and big headlines are sharing the same importance.</Small></li>
        </ul>
        <P>For data and specific data, we use shade of green.</P>
        <H3 className={"text-accent2-500"}>54%</H3>
    </div>
}

export default TextSystem;