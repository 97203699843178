import Button from "../../../components/Buttons/Buttons";
import { Input } from "../../../components/Form/Form";

const CandidateCreator = ({ register, handleSubmit, errors, isValid, onSubmit, editingCandidate, onCancel }) => {

  return <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 bg-white p-8 rounded-lg shadow-md mt-8">
    <Input
      label="Email"
      id="email"
      register={register}
      required={true}
      errors={errors}
      type="email"
    />
    <Input
      label="First Name"
      id="first_name"
      register={register}
      required={true}
      errors={errors}
      type="text"
    />
    <Input
      label="Last Name"
      id="last_name"
      register={register}
      required={true}
      errors={errors}
      type="text"
    />

    {/* <Input
      label="Job Assessment ID"
      id="job_assessment_id"
      register={register}
      required={true}
      errors={errors}
      type="text"
    /> */}

    {/* <div>
      <label htmlFor="job_assessment_id" className="block text-sm font-medium text-gray-700">Job Assessment ID</label>
      <div className="mt-1">
        <input
          type="text"
          id="job_assessment_id"
          {...register('job_assessment_id', { required: 'Job Assessment Id is required' })}
          disabled={editingCandidate}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {errors.job_assessment_id && <p className="mt-2 text-sm text-red-600">{errors.job_assessment_id.message}</p>}
      </div>
    </div> */}
    <div className="flex justify-end space-x-4">
      <Button
        type="button"
        variant="secondary"
        onClick={onCancel}
        className="inline-flex justify-center"
      >
        Cancel
      </Button>

      <Button
        type="submit"
        variant="primary"
        disabled={!isValid}
        className="w-full inline-flex justify-center "
      >
        {editingCandidate ? 'Update Candidate' : 'Create Candidate'}
      </Button>
    </div>
  </form>
}


export default CandidateCreator;