// src/components/Typography.js

import React from 'react';
import PropTypes from 'prop-types';
import classNames from '../../utils/ClassNames';

// Base Typography Component
const Typography = ({ as: Component, className, children, ...props }) => {
  return (
    <Component className={classNames(className)} {...props}>
      {children}
    </Component>
  );
};

Typography.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Typography.defaultProps = {
  as: 'p',
  className: '',
};

// Headings
export const H1 = ({ className, children, ...props }) => (
  <Typography
    as="h1"
    className={classNames('text-4xl font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

export const H2 = ({ className, children, ...props }) => (
  <Typography
    as="h2"
    className={classNames('text-3xl font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

export const H3 = ({ className, children, ...props }) => (
  <Typography
    as="h3"
    className={classNames('text-2xl font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

export const H4 = ({ className, children, ...props }) => (
  <Typography
    as="h4"
    className={classNames('text-xl font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

export const H5 = ({ className, children, ...props }) => (
  <Typography
    as="h5"
    className={classNames('text-lg font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

export const H6 = ({ className, children, ...props }) => (
  <Typography
    as="h6"
    className={classNames('text-base font-bold text-gray-900', className)}
    {...props}
  >
    {children}
  </Typography>
);

// Paragraph
export const P = ({ className, children, ...props }) => (
  <Typography
    as="p"
    className={classNames('text-base my-2 text-gray-700', className)}
    {...props}
  >
    {children}
  </Typography>
);

// Small Text
export const Small = ({ className, children, ...props }) => (
  <Typography
    as="small"
    className={classNames('text-sm text-gray-500', className)}
    {...props}
  >
    {children}
  </Typography>
);

export default Typography;
