import React, { useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
    HiMapPin as LocationIcon,
    HiDocument as AssessmentIcon,
    HiBuildingOffice as OfficeIcon
} from 'react-icons/hi2';
import {
    createCandidate, deleteJob, updateJob,
    updateJobStatus
} from '../../../services/recruiterService';
import { useForm } from 'react-hook-form';


import Tabs from "../../../components/Tabs/Tabs";
import CandidateCreator from "./CandidateEditor";
import CandidateList from "./CandidateList";
import JobEditor from "./JobEditor";
import AssessmentInformation from "./AssessmentInfo";

import { H4, P } from "../../../components/Typography/Typography";
import ProgressBar from "../../../components/ProgressBar/progressBar";
import useGlobalStore from "../../../store/globalStore";
import useRecruiterStore from "../../../store/recruiterStore";
import Heading from "../../../components/Heading/heading";
import Button from "../../../components/Buttons/Buttons";
import VerticalProgressBar from "../../../components/ProgressBar/horizontalProgressBar";
import JobViewDocumentation from "./JobViewDocumentation";
import { addDays, getDatePlusXDays } from "../../../utils/dateUtils";
import Beautifier from "../../../../legacy/components/common/beautifier";


export default function JobPage() {
    let { id } = useParams();
    const [currentTab, setCurrentTab] = useState('Candidates');
    const [editingCandidate, setEditingCandidate] = useState(null);
    const [isEditingJob, setIsEditingJob] = useState(false);
    const [isCreatingCandidate, setIsCreatingCandidate] = useState(false);
    const {
        jobCandidates, addNewJobCandidate, editJobCandidate, editJob, removeJob, canDeleteJob,
        findItemById
    } = useRecruiterStore(state => state);
    const navigate = useNavigate();
    const {
        setAppNotificationWithTimeout
    } = useGlobalStore(state => state);
    const { register, handleSubmit, setValue, reset, formState: { errors, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: editingCandidate
    });

    const jobItem = findItemById(id, 'jobs');

    const onAddCandidate = async (data) => {
        try {
            data.job_id = jobItem.id;
            // Number of days that we want to add to the current date
            const days = 7;
            // Function call to add days
            const expiration_date = getDatePlusXDays(days)

            data.expiration_date = expiration_date;
            const response = await createCandidate(data);
            response.data.first_name = data.first_name;
            response.data.last_name = data.last_name;
            response.data.email = data.email;
            addNewJobCandidate(response.data);
            if(!response.success && !response.error) {
                setAppNotificationWithTimeout("Candidate not added", 'success', "Candidate not added", 3000);
            }
            setAppNotificationWithTimeout("Candidate successfully added", 'success', "Candidate successfully added", 3000);
            setIsCreatingCandidate(null);
            setEditingCandidate(null);
            reset();
        } catch (error) {
            console.error("Error adding candidate:", error);
            setAppNotificationWithTimeout("Error adding candidate", 'error', "Error adding candidate", 3000);
        }
    };

    const onEditCandidate = async (data) => {
        try {
            data.id = editingCandidate.id;
            // const result = await editJobCandidate(data);
            // if (!result.success) {
            //     console.error("Error updating candidate:", result.error);
            // } else {
            //     editJobCandidate({ ...editingCandidate, ...data });
            //     setEditingCandidate(null);
            //     reset();
            //     setAppNotificationWithTimeout("Candidate successfully updated", 'success', "Candidate successfully updated", 3000);
            // }
        } catch (error) {
            console.error("Error editing candidate:", error);
            setAppNotificationWithTimeout("Error updating candidate", 'error', "Error updating candidate", 3000);
        }
    };

    const onActivateJob = async (data) => {
        console.log(data);
        try {
            const result = await updateJobStatus({ "status": "open", "id": data.id });
            if (result.success) {
                data.status = "open";
                editJob(data);
                setAppNotificationWithTimeout("Job activated", 'success', "Job activated", 3000);
            } else {
                setAppNotificationWithTimeout("Error updating job status", 'error', "Error updating job status", 3000);
            }
        } catch (error) {
            console.error("Error activating job:", error);
            setAppNotificationWithTimeout("Error activating job", 'error', "Error activating job status", 3000);
        }

    }

    const onDeleteCandidate = async (candidateId) => {
        console.log(candidateId);
        // try {
        //     const result = await deleteCandidate(candidateId);
        //     if (result.error) {
        //         console.error("Error deleting candidate:", result.error);
        //     } else {
        //         removeJobCandidate(candidateId);
        //     }
        // } catch (error) {
        //     console.error("Error deleting candidate:", error);
        // }
    };

    const processCreateCandidate = () => {
        setEditingCandidate({ 'job_assessment_id': jobItem.job_assessment_id });
        setValue('job_assessment_id', jobItem.job_assessment_id, { shouldValidate: true });
        setIsCreatingCandidate(true);
        setEditingCandidate(null);
        reset();
    };

    const cancelCreatingCandidate = () => {
        setIsCreatingCandidate(null);
        setEditingCandidate(null);
        reset(); // Reset the form fields
    };

    const handleEditJob = async (data) => {
        try {
            const updatedJob = await updateJob(data);
            //const updatedJob = { id: "x" };
            if (updatedJob.error) {
                console.error("Error updating job:", updatedJob.error);
                // You might want to show an error message to the user here
            } else {
                jobItem.name = data.name;
                jobItem.location = data.location;
                jobItem.company_name = data.company_name;
                editJob(jobItem);
                setIsEditingJob(false);
                // You might want to show a success message to the user here
            }
        } catch (error) {
            console.error("Error updating job:", error);
            // You might want to show an error message to the user here
        }
    };

    const onDeleteJob = async (id) => {
        console.log(id);
        const result = await deleteJob({ id: jobItem.id });
        console.log(result)
        if (!result.success) {
            setAppNotificationWithTimeout("Error deleting job", 'error', "Error deleting job", 3000);
        } else {
            removeJob(jobItem.id);
            setAppNotificationWithTimeout("Job successfully deleted", 'success', "Job successfully deleted", 3000);
            navigate('/recruiter/jobs');
        }
    }

    const onCloseJob = async () => {
        console.log(id);
    }

    if (!jobItem) {
        return "loading"
    }

    const tabs = [
        { name: 'Candidates', href: '#', current: currentTab === 'Candidates' },
        { name: 'Job Profile', href: '#', current: currentTab === 'Job Profile' },
        { name: 'Assessment', href: '#', current: currentTab === 'Assessment' },
        // { name: 'Settings', href: '#', current: currentTab === 'Settings' },
        // { name: 'Details', href: '#', current: currentTab === 'Details' },
    ];

    const breadcrumbs = [
        { to: "/recruiter/dashboard", text: "Dashboard" },
        { to: "/recruiter/jobs", text: "Jobs" },
        { to: `/recruiter/jobs/${jobItem.id}`, text: jobItem.name, current: true },
    ];

    const details = [
        { label: "Company", icon: <OfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />, content: jobItem.company_name },
        { label: "Location", icon: <LocationIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />, content: jobItem.location },
        { label: "Assessment", icon: jobItem.assessment_id ? <AssessmentIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" /> : null, content: <Link to={`/recruiter/assessments/${jobItem.assessment_id}`}>{jobItem.assessment_name}</Link> },

    ];

    const actions = [

        { text: "Close", onClick: () => onCloseJob(), primary: false, disabled: canDeleteJob(jobItem.id) },
    ];


    return <div>
        <div className="px-4 sm:px-6 lg:px-8 card">
            <Heading
                title={jobItem.name}
                breadcrumbs={breadcrumbs}
                details={details}
                actions={actions}
                backLink={{ to: "/recruiter/assessments", text: "Back" }}
                status={jobItem.status}
            />
            <Tabs tabs={tabs} onTabChange={setCurrentTab} />
            {/* <pre>
                {JSON.stringify(jobItem, null, 2)}
            </pre> */}
            {/* <pre>
                {JSON.stringify(jobCandidates, null, 2)}
            </pre> */}

            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    {currentTab === 'Candidates' &&
                        <div>
                            {
                                jobItem.assessment_id && jobItem.status === "open"
                                    ? <>
                                        <div className="mt-4 mb-4 flex justify-end">
                                            <Button
                                                type="button"
                                                onClick={processCreateCandidate}
                                                size="small"
                                                className="inline-flex items-center"
                                            >
                                                Add New Candidate
                                            </Button>
                                        </div>

                                        <CandidateList
                                            candidates={jobCandidates}
                                            onDelete={onDeleteCandidate}
                                            //onEdit={startEditingCandidate}
                                            jobItem={jobItem}
                                        />
                                        {(isCreatingCandidate || editingCandidate) && (
                                            <CandidateCreator
                                                register={register}
                                                handleSubmit={handleSubmit}
                                                errors={errors}
                                                isValid={isValid}
                                                onSubmit={editingCandidate ? onEditCandidate : onAddCandidate}
                                                editingCandidate={editingCandidate}
                                                onCancel={cancelCreatingCandidate}
                                                jobAssessmentId={jobItem.id}
                                            />
                                        )}
                                    </>
                                    : jobItem.assessment_id && jobItem.status === "draft"
                                        ? <div className="text-center py-20 min-h-40">
                                            <h3 className="mt-2 text-sm font-semibold text-gray-900">Job in draft state</h3>
                                            <p className="mt-1 text-sm text-gray-500">Get started by opening the job and add candidates.</p>
                                            <P className={"text-sm text-gay-500 underline"}>This process is irrevocable.</P>
                                            <div className="mt-6">
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    onClick={() => onActivateJob(jobItem)}
                                                    className="w-full "
                                                    disabled={!(jobItem.assessment_id && jobItem.status === "draft")}
                                                >
                                                    Open Job
                                                </Button>
                                            </div>
                                        </div>
                                        : <div className="text-center py-20 min-h-40">
                                            <h3 className="mt-2 text-sm font-semibold text-gray-900">No Assessment Test Selected</h3>
                                            <p className="mt-1 text-sm text-gray-500">To activate the job, you must first set an assessment.</p>
                                        </div>
                            }

                        </div>
                    }
                    {currentTab === 'Job Profile' &&
                        <div>
                            <JobEditor
                                job={jobItem}
                                onSubmit={handleEditJob}
                                onCancel={() => setIsEditingJob(false)}
                            />

                            <div className="mt-8">
                                <H4>Delete Assessment</H4>
                                <div className="my-4 mx-auto">
                                    <ProgressBar steps={
                                        [
                                            {
                                                name: 'No unfinished candidates',
                                                description: 'No candidates with status pending or in progress.',
                                                href: '#',
                                                status: !canDeleteJob(jobItem.id) ? 'upcoming' : 'complete'
                                            },
                                            {
                                                name: 'No linked Assessment',
                                                description: 'You have unlinked any assessment from this job.',
                                                href: '#',
                                                status: jobItem.assessment_id ? 'upcoming' : 'complete',
                                            }
                                        ]

                                    } />
                                </div>

                                <div>
                                    <Button
                                        variant="caution"
                                        type="button"
                                        onClick={() => onDeleteJob(jobItem)}
                                        className="w-full "
                                        disabled={!(canDeleteJob(jobItem.id) && !jobItem.assessment_id)}
                                    >
                                        Delete Job
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }

                    {currentTab === 'Assessment' &&
                        <div>
                            <AssessmentInformation job={jobItem} />
                        </div>
                    }
{/*                     {currentTab === 'Details' &&
                        <div>
                            <ol>
                                <li>Candidates
                                    <ol>- Add / Create Candidate ✅</ol>
                                    <ol>- Show Candidates ✅</ol>
                                </li>
                                <li>Assessment
                                    <ol>- Show Details ✅</ol>
                                    <ol>- Show Skill Requirements ✅</ol>
                                    <ol>- Unlink assessment ✅</ol>
                                    <li>- if no assessment set
                                        <ol>- Show available assessments (with requirements) ✅</ol>
                                        <ol>- Set assessment (and save) ✅</ol>
                                    </li>
                                </li>
                                <li>Edit Job
                                    <ol>- Edit Job ✅ (✅ Fix data after submission)</ol>
                                    <ol>- Delete Job (if possible) ✅</ol>
                                    <ol>- Remove Skill ✅</ol>
                                </li>
                            </ol>
                            <JobViewDocumentation />

                        </div>
                    } */}

                </div>
            </div>
        </div>
    </div>
}