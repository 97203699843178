import { formatDate } from "../../../legacy/utils/dateFormat";

const Input = ({ label, id, register, required, errors, type = 'text', min, max }) => {
    const getValidationRules = () => {
      const rules = {};
      if (required) rules.required = `${label} is required`;
      if (type === 'number') {
        if (min !== undefined) rules.min = { value: min, message: `Minimum value is ${min}` };
        if (max !== undefined) rules.max = { value: max, message: `Maximum value is ${max}` };
      }
      return rules;
    };
  
    return (
      <div>
        <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
        <div className="mt-2">
          <input
            type={type}
            id={id}
            {...register(id, getValidationRules())}
            className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
            min={type === 'number' ? min : undefined}
            max={type === 'number' ? max : undefined}
          />
          {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
        </div>
      </div>
    );
  };
  
  const Select = ({ label, id, register, required, errors, options }) => (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <select
          id={id}
          {...register(id, { required: required && `${label} is required` })}
          className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
      </div>
    </div>
  );

  const SelectCV = ({ label, id, register, required, errors, options }) => (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <select
          id={id}
          {...register(id, { required: required && `${label} is required` })}
          className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.company_name} - {option.position} | Started {formatDate(option.start_date)}
            </option>
          ))}
        </select>
        {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
      </div>
    </div>
  );

  const SelectJobCandidate = ({ label, id, register, required, errors, options }) => (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <select
          id={id}
          {...register(id, { required: required && `${label} is required` })}
          className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}> {/* value is option.id */}
              {option.job_name} - {option.job_company_name} | {option.assessment_name} {/* Displayed text */}
            </option>
          ))}
        </select>
        {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
      </div>
    </div>
  );
  

  const SelectReference = ({ label, id, register, required, errors, options }) => (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <select
          id={id}
          {...register(id, { required: required && `${label} is required` })}
          className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        >
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.reference_first_name} {option.reference_last_name}
            </option>
          ))}
        </select>
        {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
      </div>
    </div>
  );
  
  const Checkbox = ({ label, id, register, required, errors }) => (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id={id}
          type="checkbox"
          {...register(id, { required: required && `${label} is required` })}
          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {label}
        </label>
      </div>
      {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
    </div>
  );
  
  const Textbox = ({ label, id, register, required, errors }) => (
    <div>
      <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        <textarea
          type={"text"}
          id={id}
          {...register(id, { required: required && `${label} is required` })}
          className="block w-full rounded-md border-0 py-1.5 px-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-600 sm:text-sm sm:leading-6"
        />
        {errors[id] && <p className="mt-2 text-sm text-red-600">{errors[id].message}</p>}
      </div>
    </div>
  );
  

  export {Input, Checkbox, Select, SelectCV, SelectJobCandidate, SelectReference, Textbox};