import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/userStore';
import { checkAndValidateToken } from '../services/authService';

export const PublicRoute = ({ children, allowedRoles }) => {
    const navigate = useNavigate();
    const user = useUserStore(state => state.user);

    useEffect(() => {
        if (user) {
            if (user.role === 'candidate') {
                navigate('/candidate/dashboard');
            } else if (user.role === 'recruiter') {
                navigate('/recruiter/dashboard');
            } else if (user.role === 'admin') {
                navigate('/admin/dashboard');
            } else {
                navigate('/login'); // default route if role is not specified
            }
        }
    }, [user, navigate]);

    return children;
};
