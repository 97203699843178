import axios from 'axios';
//'http://localhost:3000/v3/api'
//`https://lionfish-app-ayg5y.ondigitalocean.app/v3/api`
const BASE_URL = process.env.REACT_APP_API_URL || `https://lionfish-app-ayg5y.ondigitalocean.app/v3/api`;
//const BASE_URL = process.env.REACT_APP_API_URL || `http://localhost:3000/v3/api`;
const apiCall = async (method, endpoint, data = null, customHeaders = {}) => {
  const token = localStorage.getItem('authToken');  // Retrieve the token from local storage
  // Initialize headers object conditionally based on whether data is provided
  const headers = {
    ...customHeaders,
    'Authorization': token ? `Bearer ${token}` : undefined  // Conditionally add the Authorization header
  };

  // Only set content type and assign data if there is data to send
  const config = {
    method,
    url: `${BASE_URL}${endpoint}`,
    headers
  };

  if (data) {
    config.data = JSON.stringify(data);
    headers['Content-Type'] = 'application/json';  // Set content type header for requests with body
  }

  try {
    const response = await axios(config);
    // console.log(response.data);
    if (response.data.success) {
      // console.log(response);
      // console.log(response.data);
      return response.data;
    } else {
      // console.log(response);
      return response;
    }
  } catch (error) {
    console.error('API call failed:', error);

    // Return a consistent error response
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred',
      status: error.response?.status || 500
    };
  }
};

export default apiCall;
