import { useState } from "react";
import Tabs from "../../../components/Tabs/Tabs";
import { useParams } from "react-router-dom";
import useExperimentStore from "../../../store/experimentStore";
import Invitations from "./1-2-RC-Invitations";
import Details from "./1-2-RC-Details";

export default function RightContent() {
    const [currentTab, setCurrentTab] = useState("Reference Invitations");
    const {
        findAssessmentById, sentInvitations, userReferences, addNewSentInvitation
    } = useExperimentStore(state => state);
    let { id } = useParams();

    const tabs = [
        { name: 'Reference Invitations', href: '#', current: currentTab === 'Reference Invitations' },
        { name: 'Details', href: '#', current: currentTab === 'Details' },
        { name: 'Data', href: '#', current: currentTab === 'Data' },
    ];

    const selectableReferences = userReferences.filter(userReference => {
        // Check if the userReference.id is not present in any of the sentInvitations
        return !sentInvitations.some(sentInvitation => sentInvitation.reference_id === userReference.id && sentInvitation.job_candidate_id === id);
    });

    const invitedReferences = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === id; // Compare with the extracted work_history_id
    });

    return <div>
        <Tabs
            tabs={tabs}
            onTabChange={setCurrentTab}
        />
        {
            currentTab === 'Reference Invitations'
            && <Invitations id={id}/>
        }

        {
            currentTab === 'Details'
            && <Details/>
        }

        {
            currentTab === 'Data'
            && <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Invited References ({invitedReferences.length}/3 invited)</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">Invite between 3 and 5 references to peer-assess you.</p>
            </div>
        }

    </div>
}