import { useForm } from "react-hook-form";
import { Input, Select, SelectCV, SelectJobCandidate } from "../../../components/Form/Form";
import useExperimentStore from "../../../store/experimentStore";
import Button from "../../../components/Buttons/Buttons";
import { createReference } from "../../../services/candidateService";
import useGlobalStore from "../../../store/globalStore";
import { useParams } from "react-router-dom";


export default function WorkReferenceEditor({ editingCandidate, editingJobAssessment, changeEditorStatus, workHistoryEntry, jobCandidateId }) {
    const {
        workHistory, recruiterInvitations, checkReferenceExists, addNewReference, addNewSentInvitation, findJobById, findAssessmentById
    } = useExperimentStore(state => state);
    const { setAppNotificationWithTimeout, setNotification, setNotificationStatus, notification } = useGlobalStore((state) => state);
    const { register: register10, handleSubmit: handleSubmit10, setValue: setValue10, reset: reset10, formState: { errors: errors10, isValid: isValid10 } } = useForm({
        mode: 'onChange', // Enables validation on change
        //defaultValues: editingCandidate
    });
const {id} = useParams();
    const onAddReference = async (data) => {

        const notAddable = checkReferenceExists(data);
        const job = findJobById(id);
        data.job_candidate_id = id;
        data.work_history_id = workHistoryEntry;
        console.log(`The reference can ${notAddable ? 'not' : ''} be added.`);
        console.log(data)
        console.log(id);
        console.log(workHistoryEntry)
        if (!notAddable) {
            const response = await createReference(data);
            console.log(response);
            if (!response.success && !response.error) {
                setAppNotificationWithTimeout('Adding reference failed.', 'warning', 'You have already invited the user.', 3000); // 3 seconds
            } else if (response.success) {
                setAppNotificationWithTimeout('Reference added.', 'success', 'Reference added successfully', 3000); // 3 seconds
                addNewReference(response.data.userReference);
                const referenceInvitation = response.data.referenceInvitation;
                referenceInvitation.job_name = job.job_name;
                referenceInvitation.assessment_name = job.assessment_name;
                referenceInvitation.expiration_date = job.expiration_date;
                referenceInvitation.reference_first_name = data.first_name;
                referenceInvitation.reference_last_name = data.last_name;
                referenceInvitation.reference_email = data.email;
                addNewSentInvitation(referenceInvitation);
                changeEditorStatus(false);
                reset10();
            } else {
                setAppNotificationWithTimeout('An error occurred.', 'error', 'An error occurred.', 3000); // 3 seconds
            }
        } else {
            setAppNotificationWithTimeout('Can not add reference. Is already a reference.', 'info', 'Can not add reference. Is already a reference.', 3000); // 3 seconds
        }
    };

    const onEditorClose = async (data) => {
        changeEditorStatus(false);
        //console.log(data)
        // const result = await createReference(data);
        // if (result.error) {
        //     //setNotificationText("Error deleting work history.");
        // } else {
        //     addNewReference(data);
        //     //setNotificationText("Work History deleted.");
        // }
    };

    return <form onSubmit={handleSubmit10(onAddReference)} className="px-2 py-4">
        <Input
            label="First Name"
            id="first_name"
            type="text"
            required={true}
            register={register10}
            errors={errors10}
        />
        <Input
            label="Last Name"
            id="last_name"
            type="text"
            required={true}
            register={register10}
            errors={errors10}
        />
        <Input
            label="Email"
            id="email"
            type="email"
            required={true}
            register={register10}
            errors={errors10}
        />
        {/* <SelectCV
            label="CV Entry"
            id="work_history_id"
            register={register10}
            required={true}
            errors={errors10}
            options={workHistory}
        />
        <SelectJobCandidate
            label="Pending Assessments"
            id="job_candidate_id"
            register={register10}
            required={true}
            errors={errors10}
            options={recruiterInvitations}
        /> */}
        <Select
            label="Relationship"
            id="reference_role"
            register={register10}
            required={true}
            errors={errors10}
            options={[
                { "id": "subordinate", "name": "Subordinate" },
                { "id": "peer", "name": "Peer/Co-Worker" },
                { "id": "supervisor", "name": "Manager/Supervisor" },
            ]}
        />


        <div className="mt-4 sm:flex sm:justify-end">
            <Button
                variant="primary"
                size="medium"
                type="submit"
                disabled={!isValid10}
                className="mr-2"
            >
                Create and Invite
            </Button>
            <Button
                variant="secondary"
                size="medium"
                type="button"
                onClick={() => onEditorClose(false)}
                className=""
            >
                Return
            </Button>
        </div>
    </form>
}

